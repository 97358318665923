import { ReactComponent as Add } from "../../../../assets/icons/addBlueSmall.svg";

function AddUsersDescription() {
  return (
    <>
      <h1 className="text-nimbus font-bold">Agregar destinatarios</h1>
      <p className="text-nimbusLight font-bold">
        Utilice los filtros para buscar el o los usuarios que desee añadir a la
        lista de difusión como destinatarios, y de clic en{" "}
        <Add className="inline" /> para agregarlos.
      </p>
    </>
  );
}

export default AddUsersDescription;
