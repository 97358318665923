import { useNavigate } from "react-router-dom";
import EditarIcon from "../../../../../assets/icons/Edit.svg";
import VisualizarIcon from "../../../../../assets/icons/viewIcon.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLists } from "../../../../../reducers/avisoData/avisoDataSelector";
import {
  addDestinatarios,
  deleteDestinatarios,
} from "../../../../../reducers/avisoData/avisoDataAction";

const AddListasDifusionRow = ({ lista_difusion }) => {
  const [seleccionado, setSeleccionado] = useState();
  const { _id, nombre, destinatarios } = lista_difusion;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const destinatariosStore = useSelector(selectLists);

  const handleAddDelete = (seleccionado, id) => {
    if (!seleccionado) {
      let destinatarios = lista_difusion.destinatarios.map((user) => {
        const estructuraKey = user.estructura.toLowerCase();
        return {
          listaId: id,
          usuario: {
            _id: user.usuario._id,
          },
          estructura: user.estructura,
          [estructuraKey]: {
            _id: user[estructuraKey]._id,
          },
        };
      });
      destinatarios = destinatarios.concat(destinatariosStore);
      dispatch(addDestinatarios(destinatarios));
    } else dispatch(deleteDestinatarios(destinatariosStore, id));
    setSeleccionado(!seleccionado);
  };

  useEffect(() => {
    setSeleccionado(
      destinatariosStore.some((lista) => (lista.listaId === _id ? true : false))
    );
  }, [destinatariosStore]);

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-md border-r-0 text-center font-sans w-16">
        <div className="rounded-md flex flex-row items-center justify-center gap-2">
          <input
            type="checkbox"
            name={`selectList`}
            id={`selectList`}
            className="rounded-md border-2 border-gray-300 my-0 cursor-pointer"
            checked={seleccionado}
            onChange={() => handleAddDelete(seleccionado, _id)}
          />
        </div>
      </td>
      <td className="border-y-2 font-sans text-left">{nombre}</td>
      <td className="border-y-2 font-sans text-center">
        {destinatarios?.length}
      </td>
      <td className="border-2 rounded-r-md border-l-0 text-center font-sans flex justify-around min-h-[48px]">
        <button
          title="Editar lista de difusión"
          className="w-5"
          onClick={() =>
            navigate(`/herramientas/listas-difusion/${_id}/editar`)
          }
        >
          <img src={EditarIcon} className="w-5 h-5" />
        </button>
        <button
          title="Ver lista de difusión"
          className="w-5"
          onClick={() => navigate(`/herramientas/listas-difusion/${_id}/ver`)}
        >
          <img src={VisualizarIcon} className="w-5 h-5" />
        </button>
      </td>
    </tr>
  );
};

export default AddListasDifusionRow;
