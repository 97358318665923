import { useContext, useEffect, useState } from "react";
import AvisoForm from "./AvisoForm";
import SubHeaderAvisosForm from "./SubHeaderAvisosForm";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../services/user";
import {
  GestorAvisosContextProvider,
  useGestorAvisosDetalles,
} from "./GestorAvisosDetallesContext";

const GestorAvisosDetalles = ({ editar, ver }) => {
  return (
    <GestorAvisosContextProvider>
      <GestorAvisosDetallesComponents editar={editar} ver={ver} />
    </GestorAvisosContextProvider>
  );
};

const GestorAvisosDetallesComponents = ({ editar, ver }) => {
  const params = useParams();
  const { activeRole } = useContext(UserContext);

  const [loading, setLoading] = useState(false); // TODO cambiar a true
  const [isEnableSubmitButton, setIsEnableSubmitButton] = useState(false);

  const { setVer, setEditar } = useGestorAvisosDetalles();

  const getAvisoById = async () => {
    try {
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editar || ver) {
      getAvisoById();
      setVer(ver);
      setEditar(editar);
      setIsEnableSubmitButton(true);
    }
  }, [activeRole, params]);

  return (
    <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
      <SubHeaderAvisosForm />
      <AvisoForm />
    </div>
  );
};

export default GestorAvisosDetalles;
