import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../fragments/ConfirmationModal";
import { UserContext } from "../../services/user";
import { MESSAGES } from "../../utils/messagesUtils";
import folder from "../../../assets/img/folder.svg";
import ModalAgregarACarpeta from "./fragments/ModalAgregarACarpeta";
import IconSelect from '../../../assets/icons/tresPuntosVertical.svg'
import GeneralDropdown from "../../fragments/Dropdowns/GeneralDropdown";

const ReactivoCard = ({ uma, formatearFecha, clonarUma, modalbook, handleDelete, navigateToConstructorLayout, reload, seleccionadosParaMover, handleCheckContenido, examen = false }) => {
  let navigate = useNavigate();
  const { user, activeRole } = useContext(UserContext);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [copyConfirmation, setCopyConfirmation] = useState(false);
  const [agregarACarpeta, setAgregarACarpeta] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);

  const CardOptions = () =>{
    return(
      <div className="bg-white p-2 rounded-md border border-[#dcdcdc] flex custom-dropdown-container z-10">
      {reload && <button onClick={() => setAgregarACarpeta(true)} title="Agregar a carpeta" className="dropdown-item">
          <img className={'fondo-azul-folder w-6 mb-1'} src={folder} alt="" />
          <div className="ml-1 text-sm">Añadir a carpeta</div>
          </button>}
          {activeRole !== 'PROFESOR' && 
          <div className="dropdown-item" onClick={() => setCopyConfirmation(true)} title="Duplicar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-nimbus col-span-1 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
            <div className="ml-1 text-sm">Duplicar</div>
          </div>}
          {activeRole === "AUTOR_ESCOLAR" && uma.origen === "EDITORIAL" && uma.creador.id !== user._id ? (
            <button title="Eliminar">
              <span
                title="Eliminar"
                onClick={() => setDeleteConfirmation(true)}
                className="h-7 w-full cursor-pointer dropdown-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-w-[24px] w-6 h-6 text-nimbus col-span-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <div className="ml-1 text-sm">Eliminar</div>
              </span>
            </button>
          ) : null}
          {activeRole === "AUTOR_EDITORIAL" && uma.creador.id === user._id ? (
            <>
              <Link to={`/constructor/UmaConfiguration/${uma._id}`} className="dropdown-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-nimbus col-span-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
                <div className="ml-1 text-sm">Editar campos</div>
              </Link>
              <span
                onClick={() => setDeleteConfirmation(true)}
                className="w-full py-1 cursor-pointer mr-4 dropdown-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-w-[24px] w-6 h-6 text-nimbus col-span-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <div className="ml-1 text-sm">Eliminar</div>
              </span>
            </>
          ) : null}
          {activeRole !== "AUTOR_EDITORIAL" && (
            <>
              {activeRole === "AUTOR_ESCOLAR" && uma.origen === "EDITORIAL" ? null : (
                <>
                  <Link to={`/constructor/UmaConfiguration/${uma._id}`} state={{esExamen: examen}} className="dropdown-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-nimbus col-span-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                    <div className="ml-1 text-sm">Editar campos</div>
                  </Link>
                  <span
                    onClick={() => setDeleteConfirmation(true)}
                    className="rounded-full w-7 h-7 py-1 cursor-pointer mr-4 dropdown-item"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="min-w-[24px] w-6 h-6 text-nimbus col-span-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    <div className="ml-1 text-sm">Eliminar</div>
                  </span>
                </>
              )}
            </>
          )}
      </div>
    )
  }

  const toggleDropdown = () => setDropdownActive(!dropdownActive)

  return (
    <div key={uma._id} className="w-80 mb-4">
      <div
        className={`${
          uma.origen === "EDITORIAL" && user._id !== uma.creador._id && activeRole === "AUTOR_ESCOLAR"
            ? "border-b-4 border-b-[#AF0101]"
            : "border"
        } h-40 rounded-lg p-2 relative pl-5 border border-[#ededed]`}
      >
        <div
          className="py-2 cursor-pointer"
          onClick={() => navigateToConstructorLayout(uma)}
        >
          <div className="col-span-2">
            <p className="text-nimbus font-bold truncate">{uma.titulo}</p>
            <p className="truncate">{uma.descripcion}</p>
          </div>
        </div>
        <div className="absolute col-span-1 top-2 right-2">
          {!examen ? <input checked={seleccionadosParaMover.includes(uma._id)} type="checkbox" name="checkUserAdd" id={uma._id} onChange={handleCheckContenido} className='h-5 w-5 text-[#1A3D82] border-[#1A3D82] rounded-md justify-end'/>: ''}
        </div>
        <div className={`absolute w-2 h-full left-0 top-0 rounded-l-lg  ${uma.umaTipo === 'Examen' ? 'bg-gradient-to-b from-[#cb3f24] to-[#ffc616]' : 'bg-gradient-to-b from-[#AF0101] to-[#FA9EF1]'}`}></div>
        <div className="relative grid grid-cols-11 gap-1 items-end mt-4">
          <div className='col-span-9'>
            <p className="text-xs">{formatearFecha(uma.fechaCreacion)}</p>
            <p>{uma.creador ? uma.creador.nombres : user.nombres}</p>
          </div>
          {/* EN ESPERA PARA INTEGRAR CARPETAS DE EXAMEN */}
            <GeneralDropdown 
              open={dropdownActive}
              children={<CardOptions/>}
              toggleDropdown={toggleDropdown}
              dropdownStyles={'absolute bottom-0 right-[150px]'}
            />
           <svg
            onClick={() => modalbook(uma._id)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7 mt-5 text-nimbus col-span-1 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div className="w-6 h-6 relative">
            <img
              src={IconSelect}
              className="cursor-pointer absolute top-0 left-0 right-0 bottom-0"
              onClick={()=>setDropdownActive(!dropdownActive)}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={deleteConfirmation}
        setIsOpen={setDeleteConfirmation}
        handleAction={() => handleDelete(uma._id, "Evaluación")}
        action="Eliminar"
        messages={activeRole === "PROFESOR" ? MESSAGES.MESSAGE_ELIMINAR_EXAMEN : MESSAGES.MESSAGE_ELIMINAR_EVALUACION}
      />
      <ConfirmationModal
        isOpen={copyConfirmation}
        setIsOpen={setCopyConfirmation}
        handleAction={() => clonarUma(uma, "Evaluación")}
        action="Duplicar"
        messages={activeRole === 'PROFESOR' ? MESSAGES.MESSAGE_COPIAR_EXAMEN : uma.origen === "EDITORIAL" && activeRole === "AUTOR_ESCOLAR" ? MESSAGES.MESSAGE_COPIAR_EVALUACION_PRIMIGENIO : MESSAGES.MESSAGE_COPIAR_EVALUACION}
      />
      <ModalAgregarACarpeta
      idUma={uma._id}
      isOpen={agregarACarpeta}
      setIsOpen={setAgregarACarpeta}
      reload={reload}
      />
    </div>
  );
};

export default ReactivoCard;
