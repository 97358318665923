import * as axios from "axios"
import { es } from "date-fns/locale"
import { _delete, _get, _post, _put } from "./http.js";

const config = () =>{
    const token = localStorage.getItem("token") || window.sessionStorage.getItem('token')
    return {headers:{Authorization: `Bearer ${token}`}}
}

export const logIn = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/iniciar-sesion`,body).then(response=>{
        return response
    }).catch(err=>{
        console.log(err.response.data)
        return {error:err.response.data}
    })
}

export const solicitarPassword = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/solicitarRestablecimientoContrasenia`,body).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const restablecerPassword = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/restablecimientoContrasenia`,body).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const cambiarContrasenia = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/cambiarContrasenia`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const cambiarContraseniaAlumno = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/cambiarContraseniaAlumno`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const cambiarContraseniaPersonalPlantel = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/cambiarContraseniaPersonalPlantel`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const putUsuario = async (usuarioId,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/${usuarioId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const putAlumnoCM = async (correo, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/actualizaCargaMasiva/${correo}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const putTutorCM = async (correo, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/actualizaTutorCargaMasiva/${correo}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const deleteUsuario = async (usuarioId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/usuarios/${usuarioId}`,config()).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const changeRolUser = async (usuarioId, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/cambiarRol/${usuarioId}`,body, config()).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const updateCorp = async (escuelaId, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/cambiarPlantel/${escuelaId}`,body, config()).then(response=>{
        return response
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getUserInfo = async ()=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/info`,null,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const verifyEmail = async (body, allInfo = false)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/obtenerPorCorreoElectronico`,body,config()).then(response=>{
        if(allInfo) return response.data;
        return {correoElectronico:response.data.correoElectronico,id:response.data.id, nombres: response.data.nombres, primerApellido: response.data.primerApellido, roles: response.data.roles}
    }).catch(err=>{
        return err
    })
}

export const getUsuarioDetail = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/usuarios/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const putRecuperarDesactivarUsuario = async (id, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/recuperarDesactivarUsuario/${id}`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const eliminarUsuario = async (id, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/eliminarPermanente/${id}`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const buscarEnPlanteles = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/usuarios/buscarEnPlanteles/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const desactivarUsuario = async (id)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/usuarios/desactivarUsuario/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const eliminarDePlantel = async (id, campusId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/usuarios/campus/${campusId}/eliminarDelPlantel/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getCarpetas = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerPorUsuarioCompletas/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getCarpetasPrimarias = async (id, rolActivo, estructuraId, obtenerUMASEliminadas = true)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerCarpetasPrimarias/${id}/${rolActivo}/${estructuraId}/${obtenerUMASEliminadas}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getCarpetasSecundarias = async (id, rolActivo, estructuraId, obtenerUMASEliminadas)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerCarpetasSecundarias/${id}/${rolActivo}/${estructuraId}/${obtenerUMASEliminadas}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getUmasUsuario = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerUmasUsuario/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getCarpeta = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getDetalleCarpetaPrimaria = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerInfoCarpetaPrimaria/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getDetalleCarpetaPrimariaLimitada = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerInfoCarpetaPrimariaLimitada/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getDetalleCarpetaSecundaria = async (id)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carpetas/obtenerInfoCarpetaSecundaria/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const postCarpeta = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/carpetas`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const putCarpeta = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/carpetas/${id}`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const deleteCarpeta = async (id)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/carpetas/${id}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const putUMACarpeta = async (idCarpeta, body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/carpetas/putUMACarpeta/${idCarpeta}`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const putContenidosCarpeta = async (idCarpeta, body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/carpetas/putContenidosCarpeta/${idCarpeta}`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const removeUMACarpeta = async (body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/carpetas/removeUMACarpeta/`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const removeContenidosCarpetaMasiva = async (body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/carpetas/removeContenidosCarpeta/`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const getUMA = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getDetalleUMA = async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getAreaConocimiento = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/etiquetas/porDiscriminador/AREA_CONOCIMIENTO`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getTemas = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/etiquetas/porDiscriminador/TEMA`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const crearTag = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/etiquetas`,body,config()).then((response)=>{
        return {data:response.error? response.error : response.data}
    }).catch((err)=>{
        return err
    })
}

export const getUsuarios = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/usuarios`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getNivelesAcademico = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/nivel-academico`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}
export const getNivelAcademico = async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/nivel-academico/${id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getBloques = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/bloques`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getUMADetail = async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postUMA = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const editUMA = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${id}`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const editCamposOpcionalesUMA = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/actualizarOpcionales/${id}`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const actualizarAutorUma = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/actualizarAutor/${id}`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        return err
    })
}

export const deleteUMA = async (id)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/umas/${id}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const updateUMATemp = async (id, body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/desactivaReactivaLeccion/${id}`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getUmasConstructor = async (id, activeRole, esEliminado)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/paraConstructor/${activeRole}/${esEliminado}/${id}`, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getUmasLimitadasConstructor = async (id, activeRole, esEliminado)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/paraConstructorLimitado/${activeRole}/${esEliminado}/${id}`, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getUmasPorArrayId = async (body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/obtenerPorArrayId`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })

}
    
export const obtenerUmasConstructorBusqueda = async (id, activeRole, esEliminado, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/paraConstructorBuscador/${activeRole}/${esEliminado}/${id}`, body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const duplicarUma = async (uma, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${uma}`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getActividadPregunta = async (umaId, bloqueId, actId, pregId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/preguntas`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}


/*  */

/* export const getActividadChecklist = async (umaId, bloqueId, actId, ) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/checklist`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postChecklistItem = async (umaId, bloqueId, actId, body ) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/checklist`,body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteChecklistItem = async (umaId, bloqueId, actId, itemId ) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/checklist/${itemId}`, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
} */

export const getActividad = async (umaId, bloqueId, actId,actName ) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/${actName}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

/*  */

export const obtenerRespuestas = async (umaId, bloqueId, actName, preguntaId ) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actName}/preguntas/${preguntaId}/respuestas`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postRespuestas = async (umaId, bloqueId, actName, preguntaId, body ) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actName}/preguntas/${preguntaId}/respuestas`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const updateRespuestas = async (umaId, bloqueId, actName, preguntaId, respuestaId, body  ) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actName}/preguntas/${preguntaId}/respuestas/${respuestaId}`,body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteRespuestas = async (umaId, bloqueId, actName, preguntaId, respuestaId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actName}/preguntas/${preguntaId}/respuestas/${respuestaId}`, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
} 

export const getFilas = async (umaId, bloqueId, actId, columnaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/columnas/${columnaId}/filas`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postFilas = async (umaId, bloqueId, actName, columnaId, body ) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actName}/columnas/${columnaId}/filas`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const updateFilas = async (umaId, bloqueId, actId, itemId,filaId, body ) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/columnas/${itemId}/filas/${filaId}`,body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

/* Actividades */

export const postActividadItem = async (umaId, bloqueId, actId, actName, body ) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/${actName}`,body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const updateActividadItem = async (umaId, bloqueId, actId, actName,itemId, body ) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/${actName}/${itemId}`,body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteActividadItem = async (umaId, bloqueId, actId,actName,  itemId ) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actId}/${actName}/${itemId}`, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
} 

//Para bloque checklists

export const postUserCheck = async (idEscuela,idCampus,idGrupo,idMateria,idUsuario, body ) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}/materia/${idMateria}/usuario/${idUsuario}/checklist`,body, config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getUsersChecks = async (idEscuela,idCampus,idGrupo,idMateria,idUsuario) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}/materia/${idMateria}/usuario/${idUsuario}/checklist`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

//Actividad Evidencias 

export const getEvidenciaDetalle = async (umaId, bloqueId, actividadId, evidenciaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actividadId}/evidencias/${evidenciaId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const updateEvidencia = async (umaId, bloqueId, actividadId, evidenciaId, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actividadId}/evidencias/${evidenciaId}`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postEvidencia = async (umaId, bloqueId, actividadId, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actividadId}/evidencias`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const updateEvidenciaCompleta = async (umaId, bloqueId, actividadId, evidenciaId, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}/actividad/${actividadId}/evidencias/${evidenciaId}/actualizarTodo`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getSeguimientoEvidencia = async (evidenciaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${evidenciaId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const darAltaEvidencia = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getListadoEvidencias = async (escuela, campus, grupo, materia) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuela}/campus/${campus}/grupos/${grupo}/materia/${materia}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getEvidenciasAlumno = async (escuela, campus, grupo, materia, alumno) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuela}/campus/${campus}/grupos/${grupo}/materia/${materia}/alumno/${alumno}/seguimientoEvidencias`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postArchivoEvidencia = async (calificacion, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${calificacion}/cargaEvidencias`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteArchivoEvidencia = async (calificacion, evidencia)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${calificacion}/cargaEvidencias/${evidencia}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postComentarioEvidencia = async (calificacion, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${calificacion}/comentariosAlumno`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const enviarTareaEvidencia = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/enviarTarea`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

//para calificar evidencias

export const getListadoPorEvidencia = async (grupo, materia, libro, uma, evidencia) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${grupo}/materia/${materia}/libroContenido/${libro}/umaBloque/${uma}/evidencia/${evidencia}/calificacionEvidencias`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getListadoAlumnos = async (escuela, campus, grupo, materia) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuela}/campus/${campus}/grupos/${grupo}/materia/${materia}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getEvidenciaAlumno = async (escuela, campus, grupo, materia, alumno) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuela}/campus/${campus}/grupos/${grupo}/materia/${materia}/alumno/${alumno}/seguimientoEvidencias`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const calificarRubrica = async (evidencia, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${evidencia}/calificacionRubrica`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postComentarioProfesor = async (evidencia, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${evidencia}/comentariosProfesor`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getDetalleEvidencia = async (materia, libro, agrupador, uma, evidencia, alumno) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/materia/${materia}/libroContenido/${libro}/agrupadorUma/${agrupador}/umaBloque/${uma}/evidencia/${evidencia}/alumno/${alumno}/calificacionEvidencias`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const rechazarTarea = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/rechazarTarea`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}
export const calificarTarea = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/enviarCalificacion`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const bloquearTareaAlumno = async (evidencia, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${evidencia}/bloquear`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const desbloquearTareaAlumno = async (evidencia, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/${evidencia}/desbloquear`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const bloquearTareaGeneral = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/bloquearParaTodos`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const desbloquearTareaGeneral = async (body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/calificacionEvidencias/desbloquearParaTodos`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getDetalleTareaBloqueada = async (grupo, materia, libro, uma, evidencia) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${grupo}/materia/${materia}/libroContenido/${libro}/umaBloque/${uma}/evidencia/${evidencia}/evidenciaBloqueadaGeneral`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

/* fin actividades */

export const postBloque = async (umaId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getBloque = async (umaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getBloqueDetalle = async (umaId, bloqueId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/umas/${umaId}/umaBloques/${bloqueId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const deleteUmaBloque = async (idUma,id)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/umas/${idUma}/umaBloques/${id}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const editBloques = async (idUma,body,id) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/umas/${idUma}/umaBloques/${id}`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postArchivos = async (data) =>{
    let token = localStorage.getItem('token') || window.sessionStorage.getItem('token');
    return await axios.post(`${process.env.REACT_APP_API_URL}/archivos/`,data,{headers:{'Content-Type':'multipart/form-data',Authorization:'Bearer '+token}}).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteRubricaItem= async (evidenciaId, rubricaId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/evidencias/${evidenciaId}/rubricas/${rubricaId}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const addRubricaItem= async (evidenciaId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/evidencias/${evidenciaId}/rubricas`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

//Grupos

export const getAgrupadores = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/agrupadores`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postAgrupadores = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/agrupadores`,body,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteAgrupadores = async (id)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/agrupadores/${id}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getDetalleAgrupador= async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/agrupadores/${id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const editAgrupador = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/agrupadores/${id}`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const deleteAgrupador = async (id)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/agrupadores/${id}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

//Libros

export const getLibros = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getLibrosGrupoAsignado = async (body) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/obtenerLibrosGrupoAsignado`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postLibros = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/libros/`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteLibros = async (id, body)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/libros/${id}`,{...config(), data:body}).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getLibrosFavoritos = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/favoritos`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postLibrosFavoritos = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/libros/favoritos/`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteLibrosFavoritos = async (id)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/libros/favoritos/${id}`,config()).then((response)=>{
        return {response}
    }).catch((err)=>{
        console.log(err)
    })
}

export const getDetalleLibro= async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/${id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getDetalleLibroContenido= async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/obtenerContenidos/${id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getDetalleLibroArmado= async (id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/${id}/armado`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}
export const editLibro = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/libros/${id}`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const editPropietearioLibro = async (id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/libros/actualizarPropietario/${id}`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getDetalleNiveles= async (id,idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/${id}/niveles/${idLibro}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postNivelLibro = async (idLibro,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/libros/${idLibro}/niveles`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const putNivelLibro = async (idLibro,idNivel,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/libros/${idLibro}/niveles/${idNivel}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getLibroArmado = async (idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/${idLibro}/armado`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postContenido = async (idLibro,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/libros/${idLibro}/contenidos`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const putContenido = async (idLibro,idContenido,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/libros/${idLibro}/contenidos/${idContenido}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const deleteContenido = async (idLibro,idContenido) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/libros/${idLibro}/contenidos/${idContenido}`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getActividadesResueltas = async (idEscuela,idCampus,idGrupo,idAlumno,idMateria) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}/alumno/${idAlumno}/calificaciones/materia/${idMateria}/actividadesResueltas`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getActividadesResueltasLibro = async (idGrupo,idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${idGrupo}/calificaciones/libro/${idLibro}/actividadesResueltasLibro`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getUmasLibro = async (idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/umas/${idLibro}`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getActividadesResueltasAlumno = async (idAlumno, idGrupo, idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${idGrupo}/calificaciones/libro/${idLibro}/actividadesResueltasAlumno/${idAlumno}`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getActividadesResueltasGrupo = async (idGrupo, idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${idGrupo}/calificaciones/libro/${idLibro}/actividadesResueltasGrupoCompleto`,config()).then(response=>{
        return {data:response.data[0]}
    }).catch((err)=>{
        return err
    })
}

export const getEvaluacionesResueltasLibro = async (idGrupo,idLibro) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${idGrupo}/materia/${idLibro}`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getEvaluacionesResueltasAlumno = async (idAlumno, idGrupo, idLibro, idUma) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/grupo/${idGrupo}/alumno/${idAlumno}/materia/${idLibro}/evaluacion/${idUma}`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getInsigniasPorLibro = async (idEscuela,idCampus,idGrupo,idAlumno,idMateria)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}/alumno/${idAlumno}/calificaciones/materia/${idMateria}/insigniasMateria`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getInsigniasPorMateria = async (idMateria, idAlumno)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/calificaciones/alumno/${idAlumno}/materia/${idMateria}/insigniasMateria`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postCalificacion = async (idEscuela,idCampus,idGrupo,idAlumno,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}/alumno/${idAlumno}/calificaciones`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const postEvaluacion = async (idEscuela,idCampus,idGrupo,idAlumno,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${idEscuela}/campus/${idCampus}/grupos/${idGrupo}/alumno/${idAlumno}/evaluaciones`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })

}
export const liberarlibro = async (idLibro,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/libros/liberar/${idLibro}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const librosLiberados = async ()=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/libros/liberados`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}


// Endpoints Escolar

export const getEscuela = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/editorial-distribucion`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getEscuelaDetail = async (escuelaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/editorial-distribucion`,config()).then(response=>{
        return {data:response.data}
    }).catch((error)=>{
        return error
    })
}

export const getEscuelaPlantelesLicencia = async (escuelaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/plantelesLicencias`,config()).then(response=>{
        return {data:response.data}
    }).catch((error)=>{
        return error
    })
}

export const getEscuelaPaquetes = async (escuelaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/editorial-distribucion/porPaquetes`,config()).then(response=>{
        return {data:response.data}
    }).catch((error)=>{
        return error
    })
}

export const getEscuelaDetailOptimizado = async (escuelaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/editorial-distribucion/otraVersion`,config()).then(response=>{
        return {data:response.data}
    }).catch((error)=>{
        return error
    })
}


export const getDirectoresCorporativos = async (escuelaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/editorial-distribucion/directores`,config()).then(response=>{
        return {data:response.data}
    }).catch((error)=>{
        return error
    })
}

export const getLibrosCampusCorporativo = async (corporativoId, campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${corporativoId}/libros/${campusId}`,config()).then(response=>{
        return {data:response.data}
    }).catch((error)=>{
        return error
    })
}

export const postEscuela = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas`,body,config()).then(response=>{
        return response
    }).catch((error)=>{
        return error
    })
}

export const putEscuela = async (escuelaId, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}`,body,config()).then(response=>{
        return response
    }).catch((error)=>{
        return error
    })
}

export const deleteEscuela = async (escuelaId, body) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}`,{...config(), data:body}).then(response=>{
        if(response.error){
            throw response.error;
        }
        return {data:response.data}
    }).catch((error)=>{
        console.log(error)
        return error
    })
}

export const getCampus = async (escuelaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getCampusDetail = async (escuelaId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getUsuariosPlantel = async (escuelaId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/obtenerUsuarios`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getCampusDetailParaGrupo = async (escuelaId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/obtenerParaEditar/${campusId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getGruposPlantel = async (escuelaId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/obtenerSoloGrupos`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getLibrosProfesor = async (escuelaId,campusId,profesorId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/profesores/usuario/${profesorId}/obtenerLibros`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getGruposProfesor = async (escuelaId,campusId,profesorId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/profesores/usuario/${profesorId}/obtenerGruposPorId`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const deletePlantel = async (escuelaId,campusId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}`,config()).then((response)=>{
        return response
    }).catch((err)=>{
        return err
    })
}

export const getPaises = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/paises`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getInteractivos = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/interactivos`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getPaisDetalle = async (paisId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/paises/${paisId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getEstados = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/estados`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getEstadoDetalle = async (estadoId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/estados/${estadoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postCampus = async (escuelaId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const editCampus = async (escuelaId,campusId,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getNivelesCampus = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getNivelesConGrados = async (escuelaId, campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/obtenerNivelesConGrados/${campusId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getNivelesConGradosMaM = async (escuelaId, campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/obtenerNivelesConGradosMaM/${campusId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postNivelCampus = async (escuelaId,campusId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const editNivelCampus = async (escuelaId,campusId,nivelId,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/${nivelId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteNivelCampus = async (escuelaId,campusId,nivelId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/${nivelId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getGradosCampus = async (escuelaId,campusId,nivelId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/${nivelId}/grados-academicos`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {err:err.response.data}
    })
}

export const postGradoCampus = async (escuelaId,campusId,nivelId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/${nivelId}/grados-academicos`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const editGradoCampus = async (escuelaId,campusId,nivelId,gradoId,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/${nivelId}/grados-academicos/${gradoId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteGradoCampus = async (escuelaId,campusId,nivelId,gradoId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/niveles-academicos/${nivelId}/grados-academicos/${gradoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getCalendario = async (escuelaId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/calendario`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postCalendario = async (escuelaId,campusId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/calendario`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const editCalendario = async (escuelaId,campusId,calendarioId,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/calendario/${calendarioId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteCalendario = async (escuelaId,campusId,calendarioId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/calendario/${calendarioId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getProfesores = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/profesores`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getGruposCampus = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getGruposCampusFiltrados = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/filtrados`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postGrupo = async (escuelaId,campusId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const putGrupo = async (escuelaId,campusId,grupoId,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteGrupo = async (escuelaId,campusId,grupoId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}`,config()).then(response=>{
        return response
    }).catch(err=>{
        return err
    })
}

export const deleteContenidoGrupo = async (escuelaId,campusId,grupoId, boolEliminar)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/eliminaContenidoGrupo/${boolEliminar}`,config()).then(response=>{
        return response
    }).catch(err=>{
        return err
    })
}

export const getAlumnosGrupo = async (escuelaId,campusId,grupoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/alumnos`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postAlumnoGrupo = async (escuelaId,campusId,grupoId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/alumnos`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteAlumnoGrupo = async (escuelaId,campusId,grupoId,alumnoId)=>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/alumnos/${alumnoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteAlumnosGrupo = async (escuelaId, campusId, grupoId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/alumnos/eliminarAlumnosGrupo`,body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const agregarAlumnosGrupo = async (escuelaId, campusId, grupoId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/alumnos/agregarAlumnosGrupo`,body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getMateriasGrupo = async (escuelaId,campusId,grupoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/materias/obtenerMaterias`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}
export const getDetalleMateriaGrupo = async (escuelaId,campusId,grupoId,materiaId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/materias/${materiaId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}
export const getEvaluacion = async (escuelaId,campusId,grupoId,materiaId,libroContenidoId,alumnoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/materia/${materiaId}/libroContenido/${libroContenidoId}/alumno/${alumnoId}/evaluacion`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postMateriaGrupo = async (escuelaId,campusId,grupoId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/materias`,body,config()).then(response=>{
        return response.data
    }).catch(err=>{
        return err
    })
}

export const deleteMateriaGrupo = async (escuelaId,campusId,grupoId,materiaId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/materias/${materiaId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteMateriasDeGrupo = async (escuelaId, campusId, grupoId, body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/materias/eliminarMateriasGrupo`, body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAsignaturasGrupo = async (escuelaId,campusId,grupoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/asignaturas`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAsignatura = async (asignaturaId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/asignaturas/${asignaturaId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postAsignaturaGrupo = async (escuelaId,campusId,grupoId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/asignaturas`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const putAsignaturaGrupo = async (escuelaId, campusId, grupoId,asignaturaId, body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/asignaturas/${asignaturaId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const deleteAsignaturaGrupo = async (escuelaId,campusId,grupoId,asignaturaId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/asignaturas/${asignaturaId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const eliminarAsignaturasProfesoresGrupo = async (escuelaId,campusId,grupoId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/eliminarAsignaturaProfesor`,body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const eliminarAsignaturas = async (escuelaId,campusId,grupoId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}/asignaturas/eliminarAsignaturas`,body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getGrupo = async (escuelaId,campusId,grupoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/grupos/${grupoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}


export const getAlumnos = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAlumnosOptimizado = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/menorInfo`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const obtenerPlantelesAlumnoYEliminar = async (escuelaId, campusId, alumnoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/${alumnoId}/obtenerPlantelesYEliminar`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const obtenerPlantelesAlumnos = async (escuelaId, campusId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/obtenerPlantelesTodosAlumnos`, body , config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const promoverAlumnosMasiva = async (escuelaId, campusId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/promoverAlumnos`, body , config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const promoverAlumnosPlantel = async (escuelaId, campusId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/promoverTodosAlumnos`, body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAlumnosInactivos = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/papelera/alumno`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getTutoresInactivos = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores/papelera/tutor`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const obtenerPlantelesTutores = async (escuelaId,campusId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores/obtenerPlantelesTodosTutores`, body , config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const obtenerPlantelesTutorYEliminar = async (escuelaId,campusId, tutorId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores/${tutorId}/obtenerPlantelesYEliminar`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const obtenerPlantelesUsuarios = async (body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/obtenerPlantelesTodosUsuarios`, body , config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const obtenerPlantelesUsuarioYEliminar = async (usuarioId, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/${usuarioId}/obtenerPlantelesYEliminar`, body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAlumnoDetalle = async (escuelaId,campusId,alumnoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/${alumnoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAlumnoInfo = async (escuelaId,campusId,alumnoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/${alumnoId}/info`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postAlumno = async (escuelaId,campusId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos`,body,config()).then(response=>{
        return {_id:response.data._id}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const putAlumno = async (escuelaId,campusId,alumnoId,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/${alumnoId}`,body,config()).then(response=>{
        return {_id:response.data._id}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const deleteAlumno = async (escuelaId,campusId,alumnoId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/alumnos/${alumnoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getTutorDetalle = async (escuelaId,campusId,tutorId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores/${tutorId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getFamiliaTutorId = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/familias/tutores/user`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getTutores = async (escuelaId,campusId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postTutor = async (escuelaId,campusId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const deleteTutor = async (escuelaId, campusId, tutorId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/tutores/${tutorId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getUsuarioRegistrado = async (escuelaId, campusId, padre, body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/${!padre? 'profesores' : 'tutores'}/buscarUsuario`,body, config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const postFamilia = async (escuelaId,campusId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/familias`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const putFamilia = async (escuelaId,campusId,familiaId,body)=>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/familias/${familiaId}`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getFamiliaAlumno = async (escuelaId,campusId,alumnoId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/familias/alumno/${alumnoId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getFamiliaTutor = async (escuelaId,campusId,tutorId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/familias/tutor/${tutorId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getFamiliaDetalle = async (escuelaId,campusId,familiaId)=>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/campus/${campusId}/familias/${familiaId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postUsuarioCampus = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return err
    })
}

export const getFacturasUsuario = async (usuarioId) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/market-place/facturacion/usuario/${usuarioId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getMisCompras = async (usuarioId) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/market-place/compras/obtenerPorUsuario/${usuarioId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postFacturacion = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/market-place/facturacion`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return err
    })
}

export const getInfoFacturacionUsuario = async (usuarioId) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/market-place/datosFacturacion/usuario/${usuarioId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postInfoFacturacionUsuario = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/market-place/datosFacturacion`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return err
    })
}

export const putInfoFacturacionUsuario = async (body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/market-place/datosFacturacion`,body,config()).then(response=>{
        return response
    }).catch(err=>{
        return err
    })
}
export const deleteInfoFacturacionUsuario = async (usuarioId) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/market-place/datosFacturacion/${usuarioId}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

// Endpoints Marketplace
//-----------------------------------------------------Cursos-------------------------------------------------------------
export const postCourse = async (cursosId,campusId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/campus/${campusId}/cursos`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const postCourseCategory = async (cursosId,body)=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/cursosCategorias`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getCursos = async (cursosId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/campus/${campusId}/cursos`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}

export const getAllCursos = async (cursosId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/cursosCategorias`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}

export const getSpecificCategoria = async (escuelaId,categoriaId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${escuelaId}/cursosCategorias/${categoriaId}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}

export const getSpecificCourse = async (cursosId,campusId,id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/campus/${campusId}/cursos/${id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}
export const deleteCourse = async (cursosId,campusId,Id) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/campus/${campusId}/cursos/${Id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}
export const getSpecicCategories = async (cursosId,Id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${cursosId}/cursosCategorias/${Id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}
//-----------------------------------------------------Academias-----------------------------------------------------------
export const getAcademias = async (academiasId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/rutaAprendizaje`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}

export const getSpecificAcademic= async (academiasId,campusId,Id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/rutaAprendizaje/${Id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}


export const postAcademias = async (academiasId,campusId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/rutaAprendizaje`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const putAcademias = async (academiasId,campusId,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/rutaAprendizaje/${id}`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}


export const deleteAcademic = async (academiasId,campusId,Id) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/rutaAprendizaje/${Id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const CreateAcademicCategories = async (IdAcademic,idCampus,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${IdAcademic}/campus/${idCampus}/rutaAprendizajeCategorias`,body,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getAllAcademias = async (academiasId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/rutaAprendizajeCategorias`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}

//------------------------------------------Items-----------------------------------------------------------
export const getItems = async (academiasId,campusId) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/items`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}

export const getSpecificItem= async (academiasId,campusId,Id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/items/${Id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}


export const postItem = async (academiasId,campusId,body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/items`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const putItem = async (academiasId,campusId,id,body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/items/${id}`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}


export const deleteItem = async (academiasId,campusId,Id) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/escuelas/${academiasId}/campus/${campusId}/items/${Id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}



//----------------------------------------Maestros-------------------------------------------------------------
export const getAllTeachers = async (profesorEscuela,profesorCampus) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/${profesorEscuela}/campus/${profesorCampus}/profesores`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}
export const getSpecicTeachers = async (Id) =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/escuelas/628d7d3a2cb39c000ba8b8f5/campus/628d7f022cb39c000ba8b933/profesores/${Id}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
    
}
//-------------------------------------ENDPOINTS de metodo de pagos--------------------------------------------------
export const postPasarela = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/market-place/secret`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}
export const postCheckout = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/market-place/checkout`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}
//-------------------------------------ENDPOINTS Carro de compras///--------------------------------------------------
export const getShopCar = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/carritoCompras`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const postShopCar = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/carritoCompras`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const putShopItem = async (body) =>{
    return await axios.put(`${process.env.REACT_APP_API_URL}/carritoCompras/cambiarProducto`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

export const deleteShopItem = async (user,item) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/carritoCompras/user/${user}/item/${item}`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}

//-------------------------------------------Comprados----------------------------------------------------------------

export const getProductosAlumnos = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/productosComprados/misProductos`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}


//-------------------------------------------Favoritos----------------------------------------------------------------
export const createfavorite = async (body) =>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/market-place/favoritos`,body,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}
export const getAllFavorites = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/market-place/favoritos`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log("paso aslgo",err)
    })
}
export const getAllProducts = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/market-place/compras`,config()).then((response)=>{
        return {data:response.data}
    }).catch((err)=>{
        console.log(err)
    })
}
export const deleteFavorite = async (Id) =>{
    return await axios.delete(`${process.env.REACT_APP_API_URL}/market-place/favoritos/${Id}`,config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return err
    })
}

export const getInfo=async()=>{
    return await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/info`,{},config()).then(response=>{
        return {data:response.data}
    }).catch(err=>{
        return {error:err.response.data}
    })
}

export const getTodosChecklist = async () =>{
    return await axios.get(`${process.env.REACT_APP_API_URL}/checklist/obtenerTodosChecklist`,config()).then(response=>{
        return {data:response.data}
    }).catch((err)=>{
        return err
    })
}

export const getLicencia = (rutaId) => _get(`/licencias/${rutaId}`);
export const getLicencias = () => _get('/licencias');
export const getLicenciasEditorial = (editorialId) => _get(`/licencias/porEditorial/${editorialId}`);
export const postLicencia = (body) => _post(`/licencias`,body);
export const putLicencia = (licenciaId, body) => _put(`/licencias/${licenciaId}`, body);
export const changeStatusLicencia = (licenciaId, body) => _put(`/licencias/actualizarStatus/${licenciaId}`, body);
export const deleteLicencia = (licenciaId) => _delete(`/licencias/${licenciaId}`);

export const changeStatusPlantel = (plantelId, body) => _put(`/plantel/actualizarStatus/${plantelId}`, body);
export const changeStatusCorporativo = (corporativoId, body) => _put(`/corporativos/actualizarStatus/${corporativoId}`, body);
export const getPlantelesConLicencia = (licenciaId, licenciaReemplazo) => _get(`/licencias/obtenerPorLicencia/${licenciaId}/${licenciaReemplazo}`);
export const getLicenciasCorporativoEditorial = (corporativoId) => _get(`/licencias/obtenerPorCorporativo/${corporativoId}`);

export const getMateria = (materiaId) => _get(`/etiquetas/${materiaId}`);
export const changeStatusMateria = (materiaId, body) => _put(`/etiquetas/actualizarStatus/${materiaId}`, body);
export const putMateria = (materiaId, body) => _put(`/etiquetas/${materiaId}`, body);
export const deleteMateria = (materiaId) => _delete(`/etiquetas/${materiaId}`);
export const getMateriasEditorial = (editorialId) => _get(`/etiquetas/porEditorial/${editorialId}`);
export const getMateriasEditorialActivo = (editorialId) => _get(`/etiquetas/porEditorialActivo/${editorialId}`);
export const getMateriasPlantel = (plantelId) => _get(`/etiquetas/porPlantel/${plantelId}`);
export const getMateriasEditorialPlantel = (plantelId) => _get(`/etiquetas/porEditorialPlantel/${plantelId}`);
export const getMateriasCategoria = (categoriaId) => _get(`/etiquetas/porCategoria/${categoriaId}`);
export const updateMateriasCategoria = (categoriaId, body) => _put(`/etiquetas/updtMateriasCategoria/${categoriaId}`, body);

export const postCategoria = (body) => _post(`/categoriaMaterias`,body);
export const getCategoria = (categoriaId) => _get(`/categoriaMaterias/${categoriaId}`);
export const changeStatusCategoria = (categoriaId, body) => _put(`/categoriaMaterias/actualizarStatus/${categoriaId}`, body);
export const putCategoria = (categoriaId, body) => _put(`/categoriaMaterias/${categoriaId}`, body);
export const deleteCategoria = (categoriaId) => _delete(`/categoriaMaterias/${categoriaId}`);
export const getCategoriasPlantel = (plantelId) => _get(`/categoriaMaterias/porPlantel/${plantelId}`);
export const getCategoriasEditorial = (editorialId) => _get(`/categoriaMaterias/porEditorial/${editorialId}`);
export const getCategoriasEditorialActivo = (editorialId) => _get(`/categoriaMaterias/porEditorialActivo/${editorialId}`);
export const getCategoriasEditorialPlantel = (plantelId) => _get(`/categoriaMaterias/porEditorialPlantel/${plantelId}`);

export const getUmasPorMaterias = (body) => _post('/umas/obtenerPorMaterias', body);