import { useNavigate } from "react-router-dom";
import AddButton from "../../../fragments/Buttons/AddButton";
import BreadCrumb from "../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../fragments/SubHeader";

const SubHeaderListasDifusion = () => {
  const navigate = useNavigate();

  return (
    <div className="flex align-middle justify-between items-center">
      <SubHeader styles="!bg-backgroundColor">
        <BreadCrumbContainer>
          <BreadCrumb
            route={"Herramientas /"}
            onClick={() => navigate("/recursos/herramientas")}
          />
          <BreadCrumb route={"Listas de difusión"} active />
        </BreadCrumbContainer>
      </SubHeader>
      <div className="py-5 px-10">
        <AddButton
          text="Agregar lista de difusión"
          onClick={() =>
            navigate("/herramientas/listas-difusion/agregarListaDifusion")
          }
        />
      </div>
    </div>
  );
};

export default SubHeaderListasDifusion;
