import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TablePagination from "../../../../fragments/TablePagination";
import { MESSAGES } from "../../../../utils/messagesUtils";
import { getListasDifusionPropias } from "../../../../services/Api/ListasDifusion";
import { toast } from "react-toastify";
import alertConfiguration from "../../../../utils/alertsUtils";
import { usePagination } from "../../../../hooks/usePagination";
import AddButton from "../../../../fragments/Buttons/AddButton";
import NoDataMessage from "../../../../fragments/NoDataMessage";
import AddListasDifusionTable from "./AddListasDifusionTable";
import ScheduleForm from "./ScheduleForm";
import { useSelector } from "react-redux";
import {
  selectLists,
  selectSendDate,
} from "../../../../../reducers/avisoData/avisoDataSelector";
import SubmitButton from "../../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../../fragments/Buttons/CancelButton";

function AddListasModal({
  activeRole,
  userId,
  onCloseModal,
  saveNotification,
}) {
  const [loadingLists, setLoadingLists] = useState(true);
  const [listas, setListas] = useState([]);
  const navigate = useNavigate();
  const destinatarios = useSelector(selectLists);
  const { defaultOption, date, time } = useSelector(selectSendDate);
  const disabledButton =
    destinatarios.length <= 0 ||
    (defaultOption === "Later" &&
      (date == null || date === "" || time == null || time === ""));

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    listas,
    10
  );

  const getListasDifusion = async () => {
    try {
      setLoadingLists(true);
      if (activeRole !== "") {
        let { registros } = await getListasDifusionPropias(userId, currentPage);
        registros.sort((listaDifusionA, listaDifusionB) =>
          listaDifusionB.fechaCreacion.localeCompare(
            listaDifusionA.fechaCreacion
          )
        );
        setListas(registros);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoadingLists(false);
    }
  };

  useEffect(() => {
    if (activeRole !== "") {
      getListasDifusion();
    }
  }, [activeRole]);

  return (
    <div className="flex flex-col text-center gap-y-4 max-w-[70dvw] max-h-[70dvh]">
      <h1 className="text-nimbusDark font-semibold text-2xl mb-3">
        Configurar envío del aviso
      </h1>
      <div
        className={`${
          listas?.length !== 0 && "justify-between"
        } relative flex-1 flex flex-col px-4 sm:px-8 gap-y-5`}
      >
        <div className="font-semibold text-nimbusLight">
          <p>
            A continuación, debe configurar el envío del aviso definiendo el o
            los destinatarios mediante la selección de una o más listas de
            difusión, y seleccionando la fecha de envío
          </p>
        </div>
        <p className="font-semibold lg:text-left text-base text-black">
          Enviar a:
        </p>
        <div className="flex flex-col sm:flex-row gap-5 justify-between">
          <div className="flex flex-wrap items-center w-64">
            <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
              {listas.length}
            </p>
            <p className="text-black">Listas de difusión</p>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 w-full lg:w-fit p-3 justify-end">
            <AddButton
              text="Agregar lista de difusión"
              onClick={() =>
                navigate("/herramientas/listas-difusion/agregarListaDifusion")
              }
            />
          </div>
        </div>
        <AddListasDifusionTable
          listas={currentData()}
          loadingLists={loadingLists}
        />
        <div>
          {listas?.length === 0 ? (
            <NoDataMessage message={MESSAGES.MESSAGES_LISTAS_DIFUSION_TABLE} />
          ) : (
            <TablePagination
              next={next}
              prev={prev}
              jump={jump}
              data={currentData()}
              currentPage={currentPage}
              maxPage={maxPage}
            />
          )}
          <ScheduleForm />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-4 py-4">
        <div>
          <CancelButton
            text="Cancelar"
            bgColor="bg-divider"
            styles="!w-full md:!min-w-[15rem]"
            onClick={onCloseModal}
          />
        </div>
        <div>
          <SubmitButton
            text="Enviar"
            styles="!w-full md:!min-w-[15rem] min-h-[40px]"
            disabled={disabledButton}
            onClick={saveNotification}
          />
        </div>
      </div>
    </div>
  );
}

export default AddListasModal;
