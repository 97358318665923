import { useAddUsers } from "./AddUsersContext";
import { useEffect, useState } from "react";

const AddUsersSelectItems = ({ currentUsers }) => {
  const { selectAllUsers, destinatarios } = useAddUsers();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const selectedUsers = currentUsers.filter((user) => user.esSeleccionado);

  useEffect(() => {
    const allSelected =
      currentUsers.length > 0 &&
      currentUsers.every((user) => user.esSeleccionado);
    setIsSelectedAll(allSelected);
  }, [currentUsers]);

  const handleChecked = () => {
    setIsSelectedAll((state) => !state);
    selectAllUsers(!isSelectedAll, currentUsers);
  };

  return (
    <div className="relative rounded-md border-[1px] border-gray-300 flex gap-3 items-center justify-between px-4 py-6 md:py-4 xl:py-2 flex-0">
      <div className="rounded-md flex flex-row items-center gap-2">
        <input
          type="checkbox"
          name={`selectAllCheckbox`}
          id={`selectAllCheckbox`}
          className="rounded-md border-2 border-gray-300 my-0 cursor-pointer disabled:bg-opacity-90 disabled:cursor-not-allowed"
          checked={isSelectedAll}
          onChange={handleChecked}
          disabled={destinatarios?.length === 0}
        />
        <label>
          <p className="text-sm text-left font-semibold">
            Seleccionar todos los destinatarios que se muestran en pantalla
          </p>
        </label>
      </div>

      <div className="flex items-center gap-1 justify-end mr-4">
        <p className="text-xs font-semibold">{selectedUsers?.length}</p>
        <p className="text-xs">registros seleccionados</p>
      </div>
    </div>
  );
};

export default AddUsersSelectItems;
