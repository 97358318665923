import Modal from "react-modal";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../services/user";
import { getCarpetas, putContenidosCarpeta, putUMACarpeta, removeContenidosCarpetaMasiva, removeUMACarpeta } from "../../../services/api";
import folder from "../../../../assets/img/folder.svg";
import IconAdd from "../../../../assets/icons/addblue.svg";
import ActiveIcon from '../../../../assets/icons/activeIcon.svg'
import VectorRight from '../../../../assets/icons/line.svg'
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { getCarpetasLibros, putLibroCarpeta, removeLibroCarpeta } from "../../../services/Api/Constructor/Libros";

const ModalAgregarACarpetaMasiva = ({contenidos, idLibro, isOpen, setIsOpen, reload, isLibro = false }) => {
    const { user } = useContext(UserContext);
    const [dataCarpetas, setDataCarpetas] = useState([]);
    const [idFolderSelected, setFolderId] = useState('')
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "25rem",
      maxHeight: "36rem"
    },
    overlay:{
      zIndex: 25
    }
  };

  const handleConfirmation = async () => {
    let obj = isLibro 
      ? {'idLibro': idLibro, 'user': user._id}
      : {'contenidos': contenidos, 'user': user._id}
    if(idFolderSelected !== 'raiz'){
      if(isLibro){
        await putLibroCarpeta(idFolderSelected, obj).then(response => {
          if(response?.response?.error?.status == 500){
            toast.error(`${response.response.error.message}`, alertConfiguration)
          }else{             
            setIsOpen(false);
            toast.success('Se movió el contenido seleccionado a la carpeta', alertConfiguration)
            reload();
          }
        }).catch(error=>{
          console.log(error);
        })
        return;
      }
      await putContenidosCarpeta(idFolderSelected, obj).then(response => {
        if(response?.response?.error?.status == 500){
          toast.error(`${response.response.error.message}`, alertConfiguration)
        }else{
          setIsOpen(false);
          toast.success('Se movió el contenido seleccionado a la carpeta', alertConfiguration)
          reload();
        }
      }).catch(error=>{
          console.log(error)
      })
    }else{
      if(isLibro){
        await removeLibroCarpeta(obj).then(response => {
          if(response?.error?.status == 500){
            toast.error(`${response.error.message}`, alertConfiguration)
          }else{
            setIsOpen(false);
            if(response.status){
              toast.success(response.mensaje, alertConfiguration)
            }else{
              toast.error(response.mensaje, alertConfiguration)
            }
            reload();
          }
        }).catch(error=>{
            console.log(error)
        })
        return;
      }else{
        await removeContenidosCarpetaMasiva(obj).then(response => {
          if(response?.response?.error?.status == 500){
            toast.error(`${response.response.error.message}`, alertConfiguration)
          }else{
            setIsOpen(false);
            if(response.response.data.status){
              toast.success(response.response.data.mensaje, alertConfiguration)
            }else{
              toast.error(response.response.data.mensaje, alertConfiguration)
            }
            reload();
          }
        }).catch(error=>{
            console.log(error)
        })
      }
    }
  };

  const setFolder = (id) => {
    setFolderId((idFolderSelected === id)? '' : id)
  };

  const setFolderRaiz = () => {
    //Se agrega función para uma de carpetas propias
    setFolderId((idFolderSelected === 'raiz')? '' : 'raiz')
  };

  useEffect(async () => {
    if(isOpen){
        setFolder('')
      if(isLibro){
        await getCarpetasLibros(user._id).then(response=>{
          let carpetasOrdenadas = [];
          response.forEach((element) => {
            carpetasOrdenadas.push(element);
            if (element.carpetas.length > 0) {
              element.carpetas.forEach((carpeta) => {
                carpetasOrdenadas.push(carpeta);
              });
            }
          });
          setDataCarpetas(carpetasOrdenadas);
        });
        return;
      }
      await getCarpetas(user._id).then(response=>{
        let carpetasOrdenadas = [];
        response.data.forEach((element) => {
          carpetasOrdenadas.push(element);
          if (element.carpetas.length > 0) {
            element.carpetas.forEach((carpeta) => {
              carpetasOrdenadas.push(carpeta);
            });
          }
        });
        setDataCarpetas(carpetasOrdenadas);
      });
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} style={modalStyle}>
      <div className="grid justify-items-center gap-y-6 p-4">
        <div className="flex flex-col space-y-2 items-center gap-y-1">
          <h1 className="text-nimbusDark font-bold text-lg">Mover a carpeta</h1>
          <span className="text-center">{dataCarpetas.length > 0 ? 'Selecciona una carpeta a donde quieras mover los contenidos seleccionados' : ''}</span>
        </div>
                        <div className="overflow-x overflow-y-scroll gap-y-4" style={{maxHeight: '20rem'}}>
                        <table className="w-full  border-separate font-sans" style={{ tableLayout: 'fixed', width: '100%' }}>
                                            <tbody>
                                            <tr className={'bg-white'} key='raiz'>
                                            <td className="border-2 rounded-l-md border-r-0 text-center w-8">/</td>
                                                <td className="font-sans border-y-2 truncate hover:text-ellipsis w-45">
                                                    Listado de contenidos principal
                                                </td>
                                                <td className="border-y-2 rounded-r-md border-r-2 border-l-0 w-8">
                                                    <button className=" w-8 h-8 p-2 rounded" onClick={() => setFolderRaiz()}>
                                                        <img className="w-10" src={idFolderSelected === 'raiz'? ActiveIcon : IconAdd} alt="" />
                                                    </button></td>
                                            </tr><br/>
                                            {dataCarpetas.map((e)=>(
                                                (e.nivel==0)? <tr className={'bg-white'} key={e._id}>
                                                <td className="border-2 rounded-l-md border-r-0 text-center w-8">
                                                <img className={`${e.color}-folder w-6`} src={folder} alt="" /></td>
                                                <td className="font-sans border-y-2 truncate hover:text-ellipsis w-45">
                                                    {e.nombre}
                                                </td>
                                                <td className="border-y-2 rounded-r-md border-r-2 border-l-0 w-8">
                                                    <button className=" w-8 h-8 p-2 rounded" onClick={() => setFolder(e._id)}>
                                                        <img className="w-10" src={idFolderSelected === e._id? ActiveIcon : IconAdd} alt="" />
                                                    </button></td>
                                                </tr> 
                                                :
                                                <tr className={'bg-white'} key={e._id}>
                                                <td className="w-8">
                                                <img className={'w-7 ml-1 gray-svg'} src={VectorRight} alt="" /></td>
                                                <td className="flex h-10 border-2 rounded-l-md border-r-0 font-sans text-center truncate hover:text-ellipsis w-45">
                                                <img className={`${e.color}-folder w-6 mr-3`} src={folder} alt="" /> <p className="flex mt-1.5">{e.nombre}</p>
                                                </td>
                                                <td className="border-y-2 rounded-r-md border-r-2 border-l-0">
                                                    <button className=" w-8 h-8 p-2 rounded" onClick={() => setFolder(e._id)}>
                                                        <img className="w-10" src={idFolderSelected === e._id? ActiveIcon : IconAdd} alt="" />
                                                    </button></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table></div>
        <div className="flex flex-nowrap gap-x-3 gap-y-1">
          <button className="bg-gray-200 font-semibold text-nimbusDark p-2 w-28 rounded" onClick={() => setIsOpen(false)}>
            Cancelar
          </button>
          <button className={`bg-nimbusDark font-semibold text-white p-2 w-28 rounded ${idFolderSelected !== ''? '' : 'opacity-25'}`} disabled={idFolderSelected !== ''? false : true} onClick={handleConfirmation}>
            Mover
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAgregarACarpetaMasiva;
