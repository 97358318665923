import React, { useState, useContext, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ActividadContext } from "./ActividadesUtils/BlockWrapper";
import LoadingButton from "./ActividadesUtils/LoadingButton";
import Lightbox from "./ActividadesUtils/Lightbox/Lightbox";
import LightboxWrapper from "./ActividadesUtils/Lightbox/LightboxWrapper";
import ImagenValidada from "./ActividadesUtils/RecursosValidados/ImagenValidada";
import { shuffle } from "./ActividadesUtils/ActividadesUtils";

function PreguntasAbiertas({
  preguntas,
  instrucciones,
  tipoOrden = "",
}) {
  const [inputList, setInputList] = useState([]);
  const [lightboxValue, setLightboxValue] = useState("");
  const activityContext = useContext(ActividadContext);

  const handleInput = (answer, inputId) => {
    const newInputList = inputList.map((el) => {
      if (el._id === inputId) return { ...el, answer };
      else return el;
    });
    setInputList(newInputList);
  };

  const calificar = () => {
    const dataToSave = inputList
      .filter((el) => el && el.answer)
      .map((el) => ({ answer: el.answer ? el.answer : "", _id: el._id }));
    activityContext.postCalificacionActividad(100, JSON.stringify(dataToSave));
  };

  const reordenarPreguntas = () => {
    const listadoPreguntas = [...preguntas];
    if (tipoOrden === "AZAR") {
      return shuffle(listadoPreguntas);
    } else if (tipoOrden === "ORDENAR") {
      return listadoPreguntas.sort((a, b) => (a.orden > b.orden ? 1 : -1));
    } else {
      return listadoPreguntas;
    }
  };

  useEffect(() => {
    const userResponses = activityContext?.userData;
    if (userResponses && userResponses !== "") {
      const parsedSelected = JSON.parse(userResponses);
      const answeredList = preguntas.map((el) => {
        const savedAns = parsedSelected.find((ans) => ans._id === el._id);
        if (savedAns && savedAns.answer)
          return { ...el, answer: savedAns.answer };
        else return el;
      });
      setInputList(reordenarPreguntas(answeredList));
    } else {
      setInputList(reordenarPreguntas(preguntas));
    }
  }, []);

  const isLoading = activityContext?.loading;

  return (
    <>
      <Lightbox
        lightboxValue={lightboxValue}
        closeLightbox={() => setLightboxValue("")}
      />
      <div
        style={{
          boxShadow: `rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px`,
        }}
        className="my-10 mx-auto p-4 md:px-10 py-14 bg-white  rounded-2xl border border-[#ededed] w-full max-w-activity"
      >
        <div className="mx-auto">
          {instrucciones && (
            <div className="text-nimbus mb-10 text-left w-full mx-auto font-bold text-lg">
              {instrucciones}
            </div>
          )}
          {inputList.map((el, index) => (
            <>
              {el && el.texto && (
                <div className=" items-center mb-8 justify-between">
                  <div className="flex flex-col sm:flex-row items-center mb-10 flex-1">
                    {el.imagen && (
                      <>
                        <LightboxWrapper
                          handleClick={() => setLightboxValue(el.imagen)}
                        >
                          <ImagenValidada
                            src={el.imagen}
                            className="w-full md:w-48 aspect-[16/10] rounded-[10px] bg-cover mb-3 sm:mb-0"
                          />
                        </LightboxWrapper>
                        <div className="mr-8"></div>
                      </>
                    )}
                    <div className="text-nimbus md:mr-4 flex-1 w-full md:w-auto mt-4 md:mt-0">
                      <div className="md:min-h-[50px]">
                        <span className="inline-block font-bold mr-1 text-lg">
                          {index + 1}.
                        </span>
                        {el.texto}
                      </div>
                      <TextareaAutosize
                        className="flex-1 border-lightBlue border-dashed border-2 border-t-0 border-l-0 border-r-0 bg-[#f7fcff] rounded-md w-full resize-none min-h-[3rem]"
                        onChange={(e) => handleInput(e.target.value, el._id)}
                        value={el.answer}
                        autoCorrect={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <LoadingButton onClick={calificar} isLoading={isLoading} />
      </div>
    </>
  );
}

export default PreguntasAbiertas;
