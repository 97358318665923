import React, {useRef, useState, useEffect} from 'react'
import Crossword from "@jaredreisinger/react-crossword";
import {useContext} from 'react'
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { Trans } from 'react-i18next';

function CrucigramaBody({crucigrama, evaluable, crucigramaLength, umaBloque, guardarCalificacion}) {
  const [encontrados, setEncontrados] = useState([])
  const [iniciado, setIniciado] = useState(false)
  const ref = useRef();
  const activityContext = useContext(ActividadContext)

  useEffect(() => {
    if(evaluable){
      ref.current.reset();
    }else{
      ref.current.fillAllAnswers();
    }
  }, [evaluable]);

  const calificar = () =>{
    const calificacion = encontrados.length / crucigramaLength * 100
    if(iniciado){
      activityContext.postCalificacionActividad(calificacion)
      if(umaBloque)guardarCalificacion(umaBloque, encontrados.length, '')
    }else{
      activityContext.postCalificacionActividad(null)
    }
  }

  const handleCorrect = (a, b , c, d) =>{
    const encontradaId = `${a}${b}`
    if(!encontrados.includes(encontradaId)){
      setEncontrados([...encontrados,encontradaId])
    }
  }

  const theme = {
    gridBackground: "transparent",
    cellBorder:'#18bafbcc',
    numberColor	:'#627d92',
    focusBackground:'#c4edff',
    highlightBackground:'#e2f6ff',
    cellBackground:'#fff',
    textColor:'#627d92',
  };

  return (
    <>
        <Crossword 
            data={crucigrama}
            onCorrect={(a, b) => handleCorrect(a, b)}
            onCellChange={()=>setIniciado(true)}
            ref={ref}
            theme={theme}
        />
        {evaluable ? 
        <button 
          className="className='mt-4 md:mt-[4rem] border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100 cursor-pointer w-fit'"
          onClick={()=>calificar()}
        >
          <Trans>{iniciado ? 'Calificar' : 'Cerrar'}</Trans>
        </button>:null
        }
    </>
  )
}

export default CrucigramaBody