import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faRectangleList } from '@fortawesome/free-solid-svg-icons'
import {useState, useEffect } from "react";
import {useContext} from 'react'
import {ActividadContext } from "../ActividadesUtils/ActWrapper";
import useGrabar from '../ActividadesUtils/useGrabar';
import {toCleanText } from '../ActividadesUtils/ActividadesUtils';
import Microphone from './Microphone';
import { Trans } from 'react-i18next';
import ImagenValidada from '../ActividadesUtils/RecursosValidados/ImagenValidada';

export default function HerramientaHabla({lang, inputList, umaBloque, guardarCalificacion, instrucciones}) {
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [puntuacion, setPuntuacion] = useState(0)
  const [iniciado, setIniciado] = useState(false)
  const [errores, setErrores] = useState(0)
  const activityContext = useContext(ActividadContext)
  const {completeTranscript, isListening, transcript, stopHandle, handleReset, resetTranscript, handleListing} = useGrabar({lang})

  const verify = () =>{
    stopHandle()
    setTimeout(()=>{
      const currentWord = inputList[currentWordIndex].texto
      if (toCleanText(completeTranscript) === toCleanText(currentWord)) {
        resetTranscript();
        setPuntuacion(puntuacion + 1)
        setCurrentWordIndex(currentWordIndex + 1)
        setErrores(0)
      } else {
        setErrores(errores + 1)
        handleReset()
        if(errores === 2){
          setErrores(0)
          setCurrentWordIndex(currentWordIndex + 1)
        }
      }
    },1000)
  }

  useEffect(()=>{
    setTimeout(()=>{
      if(inputList.length === currentWordIndex){
        calificar()
      }
      handleReset()
    },300)
  },[currentWordIndex])

  useEffect(()=>{
    stopHandle()
    handleReset()
  },[])
  
  const calificar = () =>{
    const calificacion = puntuacion / inputList.length * 100
    if(iniciado){
      activityContext.postCalificacionActividad(calificacion)
      if(umaBloque)guardarCalificacion(umaBloque, puntuacion, '')
    }else{
      activityContext.postCalificacionActividad(null)
    }
  }

  const handleResumeRecord = () =>{
    handleListing()
    if(!iniciado)setIniciado(true)
  }

  const tryAgain = () =>{
    stopHandle()
    resetTranscript()
  }

  return (
    <>
      {instrucciones && <div className='act-instructions'>{instrucciones}</div>}
      <div className="max-w-[36rem] min-w-[340px] w-full md:w-[1280px] mx-auto pt-10">
      {inputList.map((el,i)=>(
          <div key={el._id} className={`w-full mx-auto text-xl text-[#627d92] custom-transition ${i === currentWordIndex ? 'opacity-100' : 'opacity-0 h-0 w-0'}`}>
          <div className="text-center text-3xl text-[#627d92] mb-6">
            <div className='mr-2 inline-block'>
              <Trans i18nKey='wordLabel'></Trans>
            </div>
            <span className='text-[#18bafb] font-bold'>{el.texto}</span>
          </div>
          <div className="flex justify-between mb-2 text-sm">
            <div className="flex items-center">
              <FontAwesomeIcon className="text-sm mr-2 text-[#627d92]" icon={faRectangleList}></FontAwesomeIcon>
              <div className='inline-block'>{i + 1} <Trans i18nKey='slideOf'></Trans> {inputList.length}</div>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon className="text-sm mr-2 text-[#627d92]" icon={faCircleCheck}></FontAwesomeIcon>
              <div className="inline-block">{puntuacion} <Trans i18nKey='correctWordLabel'></Trans></div>
            </div>
          </div>
          {el.imagen ? <ImagenValidada className="w-full border mx-auto rounded-lg shadow-lg mb-4 " src={el.imagen}/> : null}
          </div>
        ))}
      <Microphone isListening={isListening} lang={lang} transcript={transcript} stopHandle={verify} handleListing={handleResumeRecord} automatic tryAgain={tryAgain}/>
      <div className={`text-red-500 text-center text-sm opacity-80 custom-transition ${errores && !transcript ? 'opacity-100' : 'opacity-0'}`}>
        {lang === 'es' ? `Palabra u oración incorrecta, te quedan ${3 - errores} intento(s)` : `Incorrect word/sentence you have ${3 - errores} more attempt(s)`}
      </div>
      </div>
    </>
  );
}