import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCarpetasSecundarias,
  getDetalleCarpetaPrimaria,
  getDetalleCarpetaPrimariaLimitada,
  updateUMATemp,
} from "../../services/api";
import IconAdd from "../../../assets/icons/addblue.svg";
import IconDelete from "../../../assets/icons/delete.svg";
import IconEdit from "../../../assets/icons/Edit.svg";
import { reload } from "../../../actions/arreglo.action";
import ModalBook from "./fragments/ModalBook";
import ModalCarpeta from "./fragments/ModalCarpeta";
import { deleteCarpeta } from "../../services/api";
import { useContext } from "react";
import { UserContext } from "../../services/user";
import { useCallback } from "react";
import { dateOptions } from "../../utils/dateFunctions";
import { toast } from "react-toastify";
import alertConfiguration from "../../utils/alertsUtils";
import IconVectorLeft from "../../../assets/icons/vectorLeft.svg";
import LoadingScreen from "../../fragments/LoadingScreen";
import ModalAgregarACarpetaMasiva from "./fragments/ModalAgregarACarpetaMasiva";
import TransferirPropiedad from "./TransferirPropiedad";
import TooltipButton from "../../fragments/Buttons/TooltipButton";
import ExamenFilter from "../../../../src/components/Herramientas/examenes/ExamenFilter";
import PrevBreadCrumb from "../../utils/breadcrumbs/PrevBreadCrumb";
import CurrentBreadcrumb from "../../utils/breadcrumbs/CurrentBreadcrumb";
import ReactivoCard from "./ReactivoCard";

import React from "react";
import CarpetaCard from "../../Herramientas/examenes/Carpetas/CarpetaCard";
import {
  deleteTarea,
  getCarpetasSecunadariasTareas,
  obtenerInfoCarpetaTareas,
} from "../../services/Api/Tareas";
import TareaCard from "../../Herramientas/examenes/Tareas/TareaCard";
import { errorToast, successToast } from "./ActividadesUtils/FormularioUtils";
import TareasFilter from "../../Herramientas/examenes/Tareas/TareasFilter";
import ShowCount from "../../utils/ShowCount";

function CarpetasProfesorScreen({ contentType }) {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataF, setDataF] = useState([]);
  const [modalBook, setModalBook] = useState(false);
  const [idBook, setIdBook] = useState("");
  const [loading, setLoading] = useState(true);
  const { user, activeRole } = useContext(UserContext);
  const [contadorCarpetas, setContadorCarpetas] = useState(0);
  const [dataCarpetas, setDataCarpetas] = useState([]);
  const [modalCarpeta, setModalCarpeta] = useState(false);
  const [edit, setEdit] = useState(false);
  const [idUpdateCarpeta, setIdUpdateCarpeta] = useState();
  const [seleccionarTodosContenidos, setSeleccionarTodosContenidos] =
    useState(false);
  const [seleccionadosParaMover, setSeleccionadosParaMover] = useState([]);
  const [agregarACarpeta, setAgregarACarpeta] = useState(false);
  const [dataCarpetaPrimaria, setDataCarpetaPrimaria] = useState([]);

  let params = useParams();
  let carpetaId = params.carpetaId;
  localStorage.setItem("carpetaId", carpetaId);
  const { state } = useLocation();

  const icons = {
    add: IconAdd,
    delete: IconDelete,
    edit: IconEdit,
  };

  //Obtener umas por carpeta
  const getData = useCallback(async () => {
    if (contentType === "TAREA") {
      setSeleccionarTodosContenidos(false);
      setSeleccionadosParaMover([]);
      setLoading(true);
      await obtenerInfoCarpetaTareas(carpetaId)
        .then((res) => {
          setDataCarpetaPrimaria(res);
          let tareas = res.tareas;
          let tareasSort = tareas.sort((a, b) => {
            var textA = a.nombre;
            var textB = b.nombre;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          setData(tareasSort);
          setDataF(tareasSort);
          setContadorCarpetas(res.carpetas.length);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getDetalleCarpetaPrimaria(carpetaId).then((res) => {
        setDataCarpetaPrimaria(res.data);
        let umas = res.data.umas;
        umas = umas.reduce((acc, current) => {
          let esDuplicado = data.find((item) => item._id === current._id);
          if (!esDuplicado) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        let umasSort = umas.sort((a, b) => {
          var textA = a.titulo;
          var textB = b.titulo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        setData(umasSort);
        setDataF(umasSort);
        setContadorCarpetas(res.data.carpetas.length);
      });
      setLoading(false);
    }
  }, [carpetaId, reload]);

  const formatearFecha = (fecha) => {
    const fechaNueva = new Date(fecha);
    return fechaNueva.toLocaleDateString("es-ES", dateOptions);
  };

  const handleDelete = (id, type) => {
    setLoading(true);
    let obj = { esEliminado: true };
    let del = updateUMATemp(id, obj);
    del
      .then((res) => {
        getData();
        toast.success("Examen eliminado con éxito!", alertConfiguration);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const clonarUma = (umaModel, type) => {
    //espera
  };

  const modalbook = (id) => {
    setIdBook(id);
    setModalBook(true);
  };

  const navigateToConstructorLayout = (uma) => {
    navigate(`/constructor/ConstructorLayout/${uma._id}`);
  };

  function renderTareas() {
    return dataF.map(
      (el) =>
        !el.esEliminado && (
          <TareaCard
            tareaId={el._id}
            nombre={el.nombre}
            fechaCreacion={formatearFecha(el?.fechaCreacion)}
            handleClick={() => navigate(`/creadorTareas/${el._id}`)}
            handleDelete={() => handleDeleteTarea(el._id)}
            reload={reloadCarpetas}
          />
        )
    );
  }

  function renderUMAS(tipo) {
    if (tipo === "Examen") {
      return dataF.map(
        (uma) =>
          !uma.esEliminado &&
          uma.umaTipo === tipo && (
            <ReactivoCard
              key={uma._id}
              uma={uma}
              formatearFecha={formatearFecha}
              clonarUma={clonarUma}
              onInfo={(msg) => toast.info(msg, alertConfiguration)}
              modalbook={modalbook}
              handleDelete={handleDelete}
              navigateToConstructorLayout={navigateToConstructorLayout}
              reload={reloadCarpetas}
              handleCheckContenido={handleCheckContenido}
              seleccionadosParaMover={seleccionadosParaMover}
            />
          )
      );
    }
  }

  const handleDeleteTarea = (tareaId) => {
    setLoading(true);
    deleteTarea(tareaId)
      .then(() => {
        setLoading(false);
        successToast("Se ha eliminado la tarea con éxito");
        getData();
      })
      .catch(() => {
        setLoading(false);
        errorToast(
          "Hubo un problema al eliminar esta tarea. Inténtalo de nuevo en unos momentos."
        );
      });
  };

  function renderCarpetas() {
    return dataCarpetas.map((carpeta) => {
      return (
        <CarpetaCard
          key={carpeta._id}
          carpeta={carpeta}
          handleDelete={handleDeleteCarpetas}
          handleEdit={handleEditCarpetas}
          isTarea={contentType === "TAREA"}
        />
      );
    });
  }

  const handleDeleteCarpetas = async (id) => {
    await deleteCarpeta(id)
      .then(() => {
        toast.success("Carpeta eliminada correctamente", alertConfiguration);
        updateCarpetas();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reloadCarpetas = () => {
    getData();
    updateCarpetas();
  };

  const openModalCarpeta = () => {
    setEdit(false);
    setModalCarpeta(true);
  };

  const closeModalCarpeta = () => {
    setModalCarpeta(false);
  };

  const handleEditCarpetas = (_id) => {
    setEdit(true);
    setIdUpdateCarpeta(_id);
    setModalCarpeta(true);
  };

  const updateCarpetas = async () => {
    if (contentType === "TAREA") {
      try {
        if (user._id && activeRole) {
          if (activeRole === "PROFESOR") {
            await getCarpetasSecunadariasTareas(carpetaId).then((res) => {
              setDataCarpetas(res);
            });
          }
        }
      } catch (error) {
        toast.error(
          "Ocurrió un error obteniendo las carpetas.",
          alertConfiguration
        );
      }
    } else {
      try {
        if (user._id && activeRole) {
          if (activeRole === "PROFESOR") {
            await getCarpetasSecundarias(
              carpetaId,
              activeRole,
              user._id,
              false
            ).then((res) => {
              setDataCarpetas(res.data);
            });
          }
        }
      } catch (error) {
        toast.error(
          "Ocurrió un error obteniendo las carpetas.",
          alertConfiguration
        );
      }
    }
  };

  const handleCheckContenido = (event) => {
    const { id, checked } = event.target;
    setSeleccionadosParaMover([...seleccionadosParaMover, id]);
    if (!checked) {
      setSeleccionadosParaMover(
        seleccionadosParaMover.filter((item) => item !== id)
      );
    }
  };

  useEffect(() => {
    getData();
    reloadCarpetas();
  }, [getData]);

  useEffect(() => {
    if (!seleccionarTodosContenidos) {
      setSeleccionadosParaMover([]);
    } else {
      const arrParaMover = [];
      dataF.forEach((uma) => {
        arrParaMover.push(uma._id);
      });
      setSeleccionadosParaMover(arrParaMover);
    }
  }, [seleccionarTodosContenidos]);

  useEffect(async () => {
    updateCarpetas();
  }, [user, activeRole]);

  return (
    <>
      <div>
        <div className="z-10 w-full">
          <div className="flex items-center p-8 !pb-4">
            <PrevBreadCrumb
              title="Herramientas"
              route={"/recursos/herramientas"}
            />
            <PrevBreadCrumb
              title={`Creador de ${
                contentType === "TAREA" ? "tareas" : "exámenes"
              }`}
              route={contentType === "TAREA" ? "/tareasHome" : "/examenes"}
            />
            <CurrentBreadcrumb title={state.nombreCarpeta} />
            <div className="flex-1"></div>
            {contentType === "TAREA" ? (
              <TareasFilter
                data={data}
                setFilteredData={setDataF}
                key={carpetaId}
              />
            ) : (
              <ExamenFilter
                data={data}
                setFilteredData={setDataF}
                key={carpetaId}
              />
            )}
          </div>
        </div>
        <div className={`flex pb-4`}>
          <div className="mt-1">
            <div
              className="flex items-center cursor-pointer px-6 py-4"
              onClick={() => navigate(-1)}
            >
              <img className="px-2 mt-0.5 w-6" src={IconVectorLeft} alt="" />
              <p className="font-bold text-lg text-[#1A3D82]">Atrás</p>
            </div>
          </div>
        </div>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            {dataCarpetaPrimaria.nivel === 0 && (
              <>
                <div className="px-8 pt-3 flex items-center">
                  <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
                    {contadorCarpetas}
                  </p>
                  <p className="text-[#697D99]">
                    {contadorCarpetas === 1 ? "Carpeta" : "Carpetas"}
                  </p>
                </div>
                <div className="p-6 flex flex-wrap gap-3">
                  <div className="w-60">
                    <button onClick={openModalCarpeta}>
                      <div className="flex flex-col items-center w-60 h-32 border pt-8 border-[#1A3D82] border-dashed font-bold text-[#1A3D82] rounded-md">
                        <img className="w-10" src={icons.add} alt="" />
                        <p className="px-2">Crear carpeta</p>
                      </div>
                    </button>
                  </div>
                  {renderCarpetas()}
                </div>
              </>
            )}

            <div className="mx-6 pt-3 flex items-center left-0 bg-white z-10">
              <ShowCount count={dataF.filter((el) => !el.esEliminado).length} />
              {contentType === "EXAMEN" && (
                <div className="flex mt-3 mb-3 text-center flex-1">
                  <input
                    type="checkbox"
                    name="seleccionarTodosContenidos"
                    className="h-5 w-5 text-[#1A3D82] border-[#1A3D82] rounded-md mr-1 ml-6 mt-0.5"
                    checked={seleccionarTodosContenidos}
                    onChange={() =>
                      setSeleccionarTodosContenidos(!seleccionarTodosContenidos)
                    }
                  />{" "}
                  <label
                    htmlFor="seleccionarTodosContenidos"
                    className=" font-thin text-s"
                  >
                    Seleccionar todos
                  </label>
                </div>
              )}
              {seleccionadosParaMover.length > 0 ? (
                <TooltipButton
                  message={"Mover contenidos"}
                  disabled={!seleccionadosParaMover.length > 0}
                  handleClick={() => setAgregarACarpeta(true)}
                  styles={"w-auto h-10 ml-6"}
                  iconStyles="azul-folder"
                />
              ) : (
                ""
              )}
              {seleccionadosParaMover.length > 0 &&
                user.correoElectronico === "rcr@caze.com" && (
                  <TransferirPropiedad
                    seleccionados={seleccionadosParaMover}
                    reload={reloadCarpetas}
                  />
                )}
            </div>
            <div className="p-6 flex flex-wrap gap-3">
              <div className="w-80">
                <Link
                  to={
                    contentType === "TAREA"
                      ? "/creadorTareas"
                      : "/constructor/UmaConfiguration"
                  }
                >
                  <div className="flex flex-col items-center  h-40 border pt-12 border-[#1A3D82] border-dashed font-bold text-[#1A3D82] rounded-md">
                    <img className="w-10" src={icons.add} alt="" />
                    <p className="px-2">
                      Crear{" "}
                      {contentType === "TAREA" ? "nueva tarea" : "nuevo examen"}{" "}
                    </p>
                  </div>
                </Link>
              </div>
              {contentType === "EXAMEN" && renderUMAS("Examen")}
              {contentType === "TAREA" && renderTareas()}
            </div>
            {modalBook ? (
              <ModalBook setModalBook={setModalBook} id={idBook} />
            ) : (
              ""
            )}
            <ModalCarpeta
              edit={edit}
              idCarpeta={idUpdateCarpeta}
              nivelCarpeta={1}
              isOpen={modalCarpeta}
              closeModal={closeModalCarpeta}
              update={updateCarpetas}
              idCarpetaPadre={carpetaId}
              isTarea={contentType === "TAREA"}
            />
            <ModalAgregarACarpetaMasiva
              contenidos={seleccionadosParaMover}
              isOpen={agregarACarpeta}
              setIsOpen={setAgregarACarpeta}
              reload={reloadCarpetas}
            />
          </>
        )}
      </div>
    </>
  );
}

export default CarpetasProfesorScreen;
