import { ReactComponent as Add } from "../../../../assets/icons/add.svg";
import { useEffect, useState } from "react";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import AddUsers from "../add-users/AddUsers";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import NimbusModal from "../../../fragments/NimbusModal";

const FILTRO_LISTA_INITIAL_VALUES = {
  busqueda: "",
};

const styles = {
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

const SearchFilter = () => {
  const { usersAdded, setUsersAddedFiltered } = useListaDifusionDetalles();
  const [filtro, setFiltro] = useState(FILTRO_LISTA_INITIAL_VALUES);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { ver } = useListaDifusionDetalles();

  const filterDataBySearch = (usersAdded, search) => {
    if (search === "") return usersAdded;

    const newUsersAddedFiltered = usersAdded.filter(
      (user) =>
        user?.usuario?.nombres?.toLowerCase().includes(search) ||
        user?.usuario?.primerApellido?.toLowerCase().includes(search) ||
        user?.usuario?.segundoApellido?.toLowerCase().includes(search) ||
        user?.usuario?.correoElectronico?.toLowerCase().includes(search)
    );
    return newUsersAddedFiltered;
  };

  useEffect(() => {
    let newFilterdUsers = usersAdded;
    newFilterdUsers = filterDataBySearch(
      newFilterdUsers,
      filtro.busqueda.toLowerCase()
    );
    setUsersAddedFiltered(newFilterdUsers);
  }, [filtro, usersAdded]);

  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-end gap-2 w-full">
        <div className="w-full md:w-fit">
          <InputBuscar
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
          />
        </div>

        {!ver && (
          <div className="w-full md:w-fit">
            <button
              type="button"
              className={`w-full bg-nimbusDark text-white py-2 p-4 rounded-lg flex items-center justify-center`}
              onClick={() => setIsOpenModal(true)}
              style={{ textWrap: "nowrap" }}
            >
              <Add className="w-5 h-5 mr-2" />
              Agregar destinatarios
            </button>
          </div>
        )}
      </div>

      <NimbusModal
        isOpen={isOpenModal}
        styles={styles}
      >
        <AddUsers onCloseModal={() => setIsOpenModal(false)} />
      </NimbusModal>
    </>
  );
};

export default SearchFilter;
