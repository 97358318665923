import { useEffect } from "react"
import { useState } from "react"

export default function useValidateForm(info) {

    const [values,setValues] = useState(info)

    const [isEnableSubmitButton, setIsEnableSubmitButton] = useState(false)

    const [errors,setErrors] = useState({})

    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
    const handleRoleChange =(event,inputType) =>{
        let roles = event.map(rol=>{
            return rol.value
        })
        setValues({...values,roles:roles})
        setIsEnableSubmitButton(true)
        // validate(event,inputType)
    }

    const handleChange = (event,inputType) =>{
        event.persist()
        
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;


        validate(name,value,inputType)

        switch(type){
            case 'password':
            case 'email':
            case 'text':
            case 'date':
            case 'number':
            case 'tel':
                if(name === "cantidadArticulo"){
                    if(value === "" || /^\d+$/.test(value))
                    setValues({
                        ...values,
                        [name]:value ===  "" | value === "0" ? value : parseInt(value)
                    })
                }else{
                    setValues({
                        ...values,
                        [name]:value
                    })
                }
                break;
            case 'file':
                setValues({
                    ...values,
                    [name]:event.target.files[0]
                })
                break;
            case 'radio':
                if(name === "esPremium"){
                    setValues({
                        ...values,
                        [name]:value === "true"
                    })
                }else{
                    setValues({
                        ...values,
                        [name]:value
                    })
                }
                break; 
            case 'select-one':
                if([name] === 'id_distribucion'){
                    setValues({...values,[name]:value
                    })
                }
                else if([name] === 'casaEditorial'){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "nivelAcademico"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "gradosAcademicos"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "gradoAcademico"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "monedaNacional"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "monedaDeIntercambio"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "categoria"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "configuracionProducto"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "estatus"){
                    setValues({...values,[name]:value === "true"
                    })
                }
                else if(name === "tipoProducto"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "notificar"){
                    setValues({...values,[name]:value === "true"
                    })
                }
                else if(name === "esDestacado"){
                    setValues({...values,[name]:value === "true"
                    })
                }
                else if(name === "masInformacion"){
                    setValues({...values,[name]:value === "true"
                    })
                }
                else if(name === "impuestos"){
                    setValues({...values,[name]:value === "true"
                    })
                }
                else if(name === "porcentajeImpuestos"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "compra"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "usoFactura"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "regimenFiscal"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "claveArticulo"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "claveUnidad"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "nombrePais"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "nombreMoneda"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "abreviacionMoneda"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "simboloMoneda"){
                    setValues({...values,[name]:value
                    })
                }
                else if(name === "valorMoneda"){
                    setValues({...values,[name]:value.toLocaleString()
                    })
                }
                else{
                    setValues({
                        ...values,
                        [name]:{_id:value}
                    })
                }

                break;
            default:
                break;
        }

        setIsEnableSubmitButton(true)
        
    }

    const validate = (name,value,inputType) =>{
        switch(inputType){
            case 'nombreCompleto':
                if(value.length<=2){
                    addError(name,'El nombre debe tener por lo menos 3 caracteres.')
                }else if(value.length>25){
                    addError(name,'El nombre debe tener menos de 26 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras y espacios.')
                }else if (/^\s*$/.test(value)) {
                    addError(name,'El nombre tiene únicamente espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'nombre':
            case 'name':
                if(value.length<=2){
                    addError(name,'El nombre debe tener por lo menos 3 caracteres.')
                }else if(value.length>=75){
                    addError(name,'El nombre debe tener menos de 75 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras y espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'lastname1':
                if(value.length<=2){
                    addError(name,'El primer apellido debe tener por lo menos 3 caracteres.')
                }else if(value.length>=75){
                    addError(name,'El primer apellido debe tener menos de 75 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value)){
                    addError(name,'El primer apellido sólo puede tener letras y espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'lastname2':
                if(value.length<=2 && value !== ''){
                    addError(name,'El segundo apellido debe tener por lo menos 3 caracteres.')
                }else if(value.length>=75 && value !== ''){
                    addError(name,'El segundo apellido debe tener menos de 75 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value) && value !== ''){
                    addError(name,'El segundo apellido sólo puede tener letras y espacios.')
                }else{
                    removeError(name)
                }
                break;

            case 'address':
                if(value.length<=2){
                    addError(name,'La dirección debe tener por lo menos 3 caracteres.')
                }else if(value.length>=180){
                    addError(name,'La dirección debe tener menos de 180 caracteres.')
                }else{
                    removeError(name)
                }
                break;
            case 'rfc':
                if(value.includes(" ")){
                    addError(name,'El RFC no debe contener espacios.')
                }
                else if(value.length<12){
                    addError(name,'El RFC debe tener por lo menos 12 caracteres.')
                }
                else if(value.length>13){
                    addError(name,'El RFC debe tener menos de 13 caracteres.')
                }
                else{
                    removeError(name)
                }
                break;
            case 'cp':
                if(value.length!==5){
                    addError(name,'El código postal debe tener 5 caracteres.')
                }else if(!/^\d+$/.test(value)){
                    addError(name,'El código postal sólo puede tener números.')
                }else{
                    removeError(name)
                }
                break;
            case 'correoNotificacion':
            case 'email':
                if(!EMAIL_REGEX.test(value)){
                    addError(name,'El correo es inválido.')
                }else{
                    removeError(name)
                }
                break;
            case 'telefono':
            case 'phone':
                if(!/^[\d\(\)\-\+]+$/.test(value) && value !== ''){
                    addError(name,'El teléfono contiene caracteres que no son permitidos.')
                }else if(value.length>30){
                    addError(name,'El teléfono no puede contener más de 30 carácteres')
                }else{
                    removeError(name)
                }
                break;
            case 'password':
                if(value.includes(" ")){
                    addError(name,'La contraseña no debe contener espacios.')
                }
                else if(value.length < 6){
                    addError(name,'La contraseña debe tener al menos 6 caracteres.')
                }
                else if(value.length > 14){
                    addError(name,'La contraseña es mayor a 14 caracteres.')
                }
                else{
                    removeError(name)
                }
                break;
            case 'razon':
                if(value.length<5){
                    addError(name,'La razón social debe tener por lo menos 5 caracteres.')
                }else if(value.length>=75){
                    addError(name,'La razón social debe tener menos de 75 caracteres.')
                }else{
                    removeError(name)
                }
                break;
            case 'nivelAcademico':
                if(value.length === 0){
                    addError(name, 'Selecciona un nivel educativo')
                }else{
                    removeError(name)
                }
                values.gradoAcademico = ""
                break;
            case 'nivelEducativo':
                if(value.length === 0){
                    addError(name, 'Selecciona un nivel educativo')
                }else{
                    removeError(name)
                }
                values.gradoAcademico = ""
                break;
            case 'gradosAcademicos':
                if(value.length === 0){
                    addError(name, 'Selecciona un grado académico')
                }else{
                    removeError(name)
                }
                break;
            case 'gradoAcademico':
                if(value.length === 0){
                    addError(name, 'Selecciona un grado académico')
                }else{
                    removeError(name)
                }
                break;
            case "descuento":
            case "porcentajeDeGanancia":
                if(!/^\d+$/.test(value)){
                    addError(name, 'Ingrese un valor entero.')
                }else if(parseInt(value) < 0 || parseInt(value) > 100){
                    addError(name, "Ingrese un valor entre 0 y 100.")
                }else{
                    removeError(name)
                }
                break;
            case 'comision':
                if(!/^\d+$/.test(value)){
                    addError(name, 'Ingrese un valor entero.')
                }else if(parseInt(value) < 10 || parseInt(value) > 100 || value[0] === "0"){
                    addError(name, "Ingrese un valor entre 10 y 100.")
                }else{
                    removeError(name)
                }
                break;
            case 'factorDeConversion':
                let number = parseFloat(value)
                if(!/^[-+]?[0-9]*\.?[0-9]+$/.test(value)){
                    addError(name, 'Ingrese un valor númerico.')
                }else if(number  < 0.01 || number > 100000.00){
                    addError(name, "Ingrese un valor entre 0.01 y 100,000.00.")
                }else if(!/^\d+(\.\d{1,2})?$/.test(value)){
                    addError(name, "El valor sólo puede tener un máximo de dos decimales.")
                } else{
                    removeError(name)
                }
                break;
            case "notificar":
                if(value === "false"){
                    removeError("correoNotificacion");
                }
                break
            case "categoria":
                if(value === "otro"){
                    values.tipoProducto = ""
                    removeError("nombreCategoria");
                }else{
                    values.nombreCategoria = ""
                }
                break
            case 'precioAsignacion':
                const precioAsignacion = parseFloat(value)
                if((precioAsignacion  < 0 || precioAsignacion > 100000000.00)){
                    addError(name, "Ingrese un valor entre 0.00 y 100,000,000.00.")
                }else{
                    removeError(name)
                }
                break;
            case 'precioVenta':
                const precioVenta = parseFloat(value)
                if((precioVenta  < 0 || precioVenta > 1000000000.00)){
                    addError(name, "Ingrese un valor entre 0.00 y 1,000,000,000.00.")
                }else{
                    removeError(name)
                }
                break;
            case 'nombreCarpeta': 
                if(value.startsWith(" ") || value.endsWith(" ")){
                    addError(name,'El nombre no puede tener espacios al inicio o al final.')
                }
                else if(value.length < 2){
                    addError(name,'El nombre debe tener por lo menos 2 caracteres.')
                }else if(value.length > 50){
                    addError(name,'La nombre debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, espacios y números.')
                }
                else if(value.trim() === ""){
                    addError(name,'El nombre únicamente contiene espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'nombreArchivo': 
                if(value.startsWith(" ") || value.endsWith(" ")){
                    addError(name,'El nombre no puede tener espacios al inicio o al final.')
                }
                else if(value.length < 2){
                    addError(name,'El nombre debe tener por lo menos 2 caracteres.')
                }else if(value.length > 50){
                    addError(name,'El nombre debe tener como máximo 50 caracteres.')
                }else if(value.trim() === ""){
                    addError(name,'El nombre únicamente contiene espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'nombreProducto': 
                if(value.length < 3){
                    addError(name,'El nombre del producto debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'La nombre del producto debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, espacios y números.')
                }else{
                    removeError(name)
                }
                break;
            case 'nombrePaquete': 
                if(value.length < 3){
                    addError(name,'El nombre del paquete debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'La nombre del paquete debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, espacios y números.')
                }else{
                    removeError(name)
                }
                break;
            case 'nombreCategoria': 
                if(value.length < 3){
                    addError(name,'El nombre de la categoría debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'La nombre de la categoría debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, espacios y números.')
                }else{
                    removeError(name)
                }
                break;
            case 'marca': 
                if(value.length < 2){
                    addError(name,'El nombre de la marca debe tener por lo menos 2 caracteres.')
                }else if(value.length > 50){
                    addError(name,'La nombre de la marca debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, espacios y números.')
                }else{
                    removeError(name)
                }
                break;
            case 'nombreDireccion': 
                if(value.length < 3){
                    addError(name,'El nombre de la dirección debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'La nombre de la dirección debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, espacios y números.')
                }else{
                    removeError(name)
                }
                break;
            case 'estado': 
                if(value.length < 3){
                    addError(name,'El nombre del estado debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'El nombre del estado debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z\sÁÉÍÓÚáéíóúÜü]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras y espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'municipio': 
                if(value.length < 3){
                    addError(name,'El nombre del municipio debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'El nombre del municipio debe como máximo 50 caracteres.')
                }else if(!/^[A-Za-z\sÁÉÍÓÚáéíóúÜü]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras y espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'colonia': 
                if(value.length < 3){
                    addError(name,'El nombre de la colonia debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'El nombre de la colonia debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜü]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, números y espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'calle': 
                if(value.length < 3){
                    addError(name,'El nombre de la calle debe tener por lo menos 3 caracteres.')
                }else if(value.length > 50){
                    addError(name,'El nombre de la calle debe tener como máximo 50 caracteres.')
                }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜü]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras, números y espacios.')
                }else{
                    removeError(name)
                }
                break;
            case 'referencias':
                if(value !== ""){
                    if(value.length > 50){
                        addError(name,'El nombre de la calle debe tener como máximo 50 caracteres.')
                    }else if(!/^[A-Za-z0-9\sÁÉÍÓÚáéíóúÜüñÑ]+$/.test(value)){
                        addError(name,'El nombre sólo puede tener letras, números y espacios.')
                    }else{
                        removeError(name)
                    }
                }else{
                    removeError(name)
                } 
                break;
            case "descripcion":
            case "caracteristicas":
                if(value === ""){
                    addError(name, 'Este campo es obligatorio.')
                }else{
                    removeError(name)
                }
                break
            case 'numeroExterior':
                if(!/^\d+$/.test(value)){
                    addError(name, 'El número exterior sólo puede tener números.')
                }else{
                    removeError(name)
                }
                break;
            case "cantidadArticulo":
                let cantidad = parseInt(value)
                if(value === "" || cantidad <= 0){
                    addError(name, "Puede comprar desde 1 u.")
                }else if(cantidad > 1000){
                    addError(name, "Supera las unidades disponibles")
                }else{
                    removeError(name)
                }
                break;
            case "porcentajeImpuestos":
                let impuesto = parseFloat(value)
                if(value === ''){
                    addError(name, "Ingrese un valor")
                }
                else if(!/^\d*\.?\d+$/.test(value)){
                    addError(name, "Ingrese un valor númerico o decimal.")
                }
                else if(impuesto < 0 || impuesto > 100){
                    addError(name, "Ingrese un valor entre 0 y 100.")
                }else{
                    removeError(name)
                }
                break;
            case "dirFiscalPais":
                if(value === ""){
                    addError(name, "Este campo es obligatorio.")
                }else{
                    removeError(name)
                }
                break;
            case "nombreCompletoFactura":
                if(value.length<=2){
                    addError(name,'El nombre debe tener por lo menos 3 caracteres.')
                }else if(value.length>60){
                    addError(name,'El nombre debe tener menos de 60 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value)){
                    addError(name,'El nombre sólo puede tener letras y espacios.')
                }else if (/^\s*$/.test(value)) {
                    addError(name,'El nombre tiene únicamente espacios.')
                }else{
                    removeError(name)
                }
                break;
            case "claveArticulo":
            case "claveUnidad":
                if(value.length !== 10){
                    addError(name, 'La clave debe ser de 10 caracteres')                     
                }
                else if(!/^[a-zA-Z0-9]+$/.test(value)){
                    addError(name, 'La clave sólo puede tener letras y números')
                }
                else{
                    removeError(name)
                }
                break;
            case "nombrePais":
                if(value.length < 3){
                    addError(name, 'El nombre del país debe tener por lo menos 3 caracteres.')
                }else if(value.length > 40){
                    addError(name, 'El nombre del país debe tener máximo 40 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value)){
                    addError(name, 'El nombre sólo puede tener letras y espacios.')
                }else if (/^\s*$/.test(value)) {
                    addError(name, 'El nombre tiene únicamente espacios.')
                }else{
                    removeError(name)
                }
                break;
            case "nombreMoneda":
                if(value.length < 1){
                    addError(name, 'El nombre de la moneda debe tener por lo menos 1 caracter.')
                }else if(value.length > 40){
                    addError(name, 'El nombre de la moneda debe tener máximo 40 caracteres.')
                }else if(!/^[aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ ]+$/.test(value)){
                    addError(name, 'El nombre sólo puede tener letras y espacios.')
                }else if (/^\s*$/.test(value)) {
                    addError(name, 'El nombre tiene únicamente espacios.')
                }else{
                    removeError(name)
                }
                break;
            case "simboloMoneda":
                if(value.length < 1){
                    addError(name, 'El símbolo de la moneda debe tener por lo menos 1 caracter.')
                }else if(value.length > 5){
                    addError(name, 'El símbolo de la moneda debe tener máximo 5 caracteres.')
                }else if(value.includes(" ")){
                    addError(name, 'El símbolo de la moneda no puede contener espacios.')
                }else{
                    removeError(name)
                }            
                break;
            case "abreviacionMoneda":
                if(value.length < 1){
                    addError(name, 'La abreviación de la moneda debe tener por lo menos 1 caracter.')
                }else if(value.length > 10){
                    addError(name, 'La abreviación de la moneda debe tener máximo 10 caracteres.')
                }else if(!/^[AÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ]+$/.test(value)){
                    addError(name, 'La abreviación de la moneda sólo puede tener letras mayúsculas.')
                }else{
                    removeError(name)
                }
                break;
            case "valorMoneda":
                let valorMoneda = parseFloat(value)
                if(value === ''){
                    addError(name, 'Ingrese un valor.')
                }
                else if(!/^\d*\.?\d+$/.test(value)){
                    addError(name, 'Ingrese un valor númerico o decimal.')
                }
                else if(valorMoneda < 0.00001 || valorMoneda > 999999.99){
                    addError(name, "Ingrese un valor entre 0.00001 y 999,999.99.")
                }
                else if(!/^\d+(\.\d{1,5})?$/.test(value)){
                    addError(name, "El valor de la moneda debe tener máximo 5 decimales.")
                } else{
                    removeError(name)
                }
                break;
            case "nombreLista":
                const validRegex = /^[A-ZÁÉÍÓÚÑÜ].*$/.test(value);

                if (!value) {
                    addError(name, 'El nombre de la lista de difusión es requerido.');
                } else if (value?.length >= 50) {
                    addError(name, 'El nombre de la lista no debe tener más de 50 caracteres.');
                } else if (!validRegex) {
                    addError(name, 'El primer caracter debe ser una mayúscula.');
                } else {
                    removeError(name);
                }
                break;
            case "nombreAviso":
                const validRegexAviso = /^[A-ZÁÉÍÓÚÑÜ].*$/.test(value);

                if (!value) {
                    addError(name, 'El nombre del aviso es requerido.');
                } else if (value?.length > 80) {
                    addError(name, 'El nombre del aviso no puede tener más de 80 caracteres.');
                } else if (!validRegexAviso) {
                    addError(name, 'El primer caracter debe ser una mayúscula.');
                } else {
                    removeError(name);
                }
                break;
            default:
                if(value===undefined){
                    addError(name,'Este campo es obligatorio')
                }else{
                    removeError(name)
                }
                break;
        }
    }

    const validateEmpty = async (exclude=[]) =>{
        let newErrors = {}
        for(const value in values){
            switch(typeof values[value]){
                case 'object':
                    if(values[value] instanceof File){
                        
                    }else{
                        if(values[value]['_id'] === ''){
                            newErrors[value] = 'Este campo es obligatorio'
                        }
                    }
                    break;
                case 'string':
                    if(values[value] === ''){
                        newErrors[value] = 'Este campo es obligatorio'
                    }
                    break;
                default:
                    break;
            }
        }
        // console.log('Excluidos',exclude)
        exclude.forEach(element => {
            delete newErrors[element]
        });
        setErrors(newErrors)
        return newErrors
    }

    const validateEmptyAddressFields = async (fields) => {
        for(const value of fields){

            let fieldType = typeof (values[value])
            
            if(fieldType === "object" && !(values[value] instanceof File)){
                if(values[value]['_id'] !== '') return false
            }else{
                if(values[value] !== '') return false
            }
        }
    
        return true
    }

    const areRequiredFieldsFulfilled = (notRequiredFields = []) => {

        let requiredFields = values ? {...values} : {}
        
        notRequiredFields.forEach(field => {
            delete requiredFields[field]
        });

        for(const key of Object.keys(requiredFields)){

            let fieldType = typeof (requiredFields[key])
            
            if(fieldType === "object" && !(requiredFields[key] instanceof File)){
                if(requiredFields[key]['_id'] === '') return false
            }else{
                if(requiredFields[key] === '' || requiredFields[key] === undefined) return false
            }
        }
    
        return true
    }

    const addError = async (name,error) =>{
        setErrors({
            ...errors,
            [name]:error
        })
    }

    const removeError = (name) =>{
        let {[name]:_,...newErrors} = errors;
        setErrors(newErrors)
    }

    return{
        values,
        errors,
        handleChange,
        handleRoleChange,
        validateEmpty,
        setValues,
        addError,
        removeError,
        setErrors,
        validateEmptyAddressFields,
        areRequiredFieldsFulfilled,
        isEnableSubmitButton,
        setIsEnableSubmitButton
    }
}