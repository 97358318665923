import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import UsersAddedRow from "./UsersAddedRow";

const UsersAddedTable = () => {
  const { currentData } = useListaDifusionDetalles();

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-separate">
        <thead>
          <tr className="text-left font-semibold font-sans text-black">
            <th />
            <th className="text-center">Nombre del usuario</th>
            <th className="text-center">Correo electrónico</th>
            <th className="text-center">Estructura</th>
            <th className="text-center">Rol</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {currentData()?.map((user) => (
            <UsersAddedRow key={user?.id} user={user} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersAddedTable;
