import { useEffect, useState } from "react";
import { getCategoriaNotificacionPorId } from "../../../../services/Api/CategoriaNotificaciones";
import { useSelector } from "react-redux";
import { selectAvisoData } from "../../../../../reducers/avisoData/avisoDataSelector";
import { ICONOS_CATEGORIAS } from "../../../../utils/categoriaNotificacionesUtils";
import { ReactComponent as MarkAsRead } from "../../../../../assets/icons/markAsRead.svg";
import { ReactComponent as Close } from "../../../../../assets/icons/closeRounded.svg";
import { CONTENT } from "../../../../utils/gestorAvisosUtils";
import ImagenVisor from "../../../../visor/bloques/Imagen";
import AudioVisor from "../../../../visor/bloques/Audio";
import VideoVisor from "../../../../visor/bloques/Video";
import TextoVisor from "../../../../visor/bloques/Texto";

const Notification = ({ onClosePreview }) => {
  const { categoria, nombre, descripcion, contenido } =
    useSelector(selectAvisoData);
  const [categoryDetails, setCategoryDetails] = useState();

  useEffect(() => {
    if (categoria !== "") getCategoria(categoria);
  }, [categoria]);

  const getCategoria = async () => {
    try {
      const categoryRes = await getCategoriaNotificacionPorId(categoria);
      setCategoryDetails(categoryRes);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col gap-4 w-[88dvw] max-h-[85dvh]">
      <div className="flex w-full bg-nimbusDark text-white items-center p-4 justify-between">
        <div className="text-left font-sans">
          <img
            className={"white-svg mx-auto w-9 h-9"}
            src={ICONOS_CATEGORIAS[categoryDetails?.icono]}
            title={categoryDetails?.nombre}
          />
        </div>
        <h1 className="text-2xl font-semibold text-center">{nombre}</h1>
        <div className="flex gap-4">
          <button className="cursor-pointer">
            <MarkAsRead className="w-8 h-8" />
          </button>
          <button className="cursor-pointer">
            <Close className="white-svg w-8 h-8" />
          </button>
        </div>
      </div>
      <p className="text-center text-nimbusLight font-semibold">
        {descripcion}
      </p>
      <section className="flex flex-col items-center">
        {contenido?.map((content, index) =>
          content.bloque._id === CONTENT.texto ? (
            <div key={index}>
              <TextoVisor texto={content.texto} />
            </div>
          ) : content.bloque._id === CONTENT.audio ? (
            <div key={index} className="min-w-full max-w-[50rem] max-h-[50rem]">
              <AudioVisor audio={content.path} pieDeFoto={""} />
            </div>
          ) : content.bloque._id === CONTENT.video ? (
            <div key={index} className="min-w-full max-w-[50rem] max-h-[50rem]">
              <VideoVisor video={content.path} pieDeFoto={""} />
            </div>
          ) : content.bloque._id === CONTENT.imagen ? (
            <div key={index} className="min-w-full max-w-[50rem] max-h-[50rem]">
              <ImagenVisor imagen={content.path} pieDeFoto={""} />
            </div>
          ) : content.bloque._id === CONTENT.archivo ? (
            <>
              <div className="h-[calc(80vh-3.5rem)] w-[80vw] block md:hidden">
                <p className="mt-4">
                  Haz click{" "}
                  <a
                    href={content.path}
                    target="_blank"
                    className="text-[#00a6f0]"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </a>{" "}
                  para ver el PDF
                </p>
              </div>
              <embed
                src={content.path}
                type="application/pdf"
                className="px-10 h-[calc(80vh-3.5rem)] w-[80dvw] md:h-[calc(90vh-3.5rem)] md:w-[90dvw] hidden md:block"
              />
            </>
          ) : (
            ""
          )
        )}
      </section>
      <div className="flex flex-col lg:flex-row justify-center gap-2 mt-[5rem]">
        <button
          className="flex items-center justify-center bg-gray-200 w-full lg:w-40 h-10 rounded-lg text-nimbusDark font-semibold cursor-pointer"
          onClick={onClosePreview}
        >
          <Close className="mr-2" />
          <span>Cancelar</span>
        </button>
        <button
          className="flex items-center justify-center bg-nimbusDark p-2 rounded-lg text-white font-semibold cursor-pointer"
          onClick={onClosePreview}
        >
          <MarkAsRead className="mr-2" />
          <span>Marcar como leído</span>
        </button>
      </div>
    </div>
  );
};

export default Notification;
