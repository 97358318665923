import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import IconAudio from "../../../../../assets/icons/icons-bloques/audio_default.png";
import audio_up from "../../../../../assets/icons/icons-bloques/audio_up.png";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import editListIcon from "../../../../../assets/icons/editListIcon.svg";
import { useGestorAvisosDetalles } from "../GestorAvisosDetallesContext";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileHandler } from "../../../../../reducers/avisoData/avisoDataAction";
import { selectContent } from "../../../../../reducers/avisoData/avisoDataSelector";

const AudioComponent = ({ pieDeFoto, setCkEditorTable, contenidoId }) => {
  const [audio, setAudio] = useState();
  const [isPlaying, setIsPlaying] = useState();
  const dispatch = useDispatch();
  const contenido = useSelector(selectContent);
  const { editandoTexto, ver } = useGestorAvisosDetalles();
  const fileRef = useRef();

  useEffect(() => {
    setIsPlaying(!isPlaying);
  }, [setIsPlaying]);

  return (
    <div className="hover:border-[#c6c6c6] transition-all rounded-md pl-5 pb-5 pr-5 p-2 mb-4 border">
      <div className="flex justify-end pb-2">
        <div className="border rounded-l-md rounded-b-md bg-nimbus text-white px-2">
          Audio
        </div>
      </div>
      <div className="flex justify-center w-full max-w-[960px] mx-auto flex-col">
        <button
          onClick={() => fileRef.current.click()}
          className={`border border-dashed border-blue-400 relative ${
            editandoTexto || ver ? "cursor-not-allowed" : ""
          } `}
        >
          <input
            type="file"
            ref={fileRef}
            onChange={(e) =>
              dispatch(
                uploadFileHandler(
                  e.target.files[0],
                  setAudio,
                  contenido,
                  contenidoId,
                  "audio"
                )
              )
            }
            hidden
            disabled={editandoTexto || ver}
            accept=".mp3, .mpeg"
          />
          <img
            className="h-full w-full"
            src={audio ? audio_up : IconAudio}
            alt=""
          />
          <div className="absolute transform -translate-x-1/2 left-[51%] bottom-[10%] min-w-[20rem] w-1/4">
            {audio ? (
              <ReactAudioPlayer
                src={audio}
                controls
                className="w-full border border-[#dcdcdc] rounded-full"
                disabled={editandoTexto}
              />
            ) : (
              <button
                className="w-full flex items-center justify-center border rounded-md py-2 bg-nimbus text-white text-center"
                onClick={() => setIsPlaying(!isPlaying)}
                disabled={editandoTexto}
              >
                <FontAwesomeIcon color="#fff" icon={faVolumeHigh} />
                <span className="ml-2">Escuchar audio</span>
              </button>
            )}
          </div>
        </button>
      </div>
      {pieDeFoto === "" || pieDeFoto === undefined ? (
        <div
          className={`text-[#697D99] cursor-pointer flex justify-center items-center ${
            editandoTexto || ver ? "cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!editandoTexto && !ver) setCkEditorTable(false);
          }}
        >
          <img src={editListIcon} alt="" />
          <span>Editar pie</span>
        </div>
      ) : (
        <div
          onClick={() => {
            if (!editandoTexto && !ver) setCkEditorTable(false);
          }}
          className={`text-[#697D99] cursor-pointer items-center mt-2 flex justify-center ${
            editandoTexto || ver ? "cursor-not-allowed" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: pieDeFoto }}
        />
      )}
    </div>
  );
};

export default AudioComponent;
