import { BrowserRouter, Routes, Route, useLocation, Outlet, Navigate, useNavigate } from "react-router-dom"
import { useState, useEffect, lazy, Suspense } from "react";
import { useAuth, ProvideAuth } from './components/services/auth'
import getHeaderTitle from "./components/utils/getHeaderTitle";
import getHeaderIcon from "./components/utils/getHeaderIcon";
import ComprasGenerales from "./components/marketplace/Market/Cuenta/Comprados/ComprasGenerales";
import axios from "axios";
import { UserProvider } from "./components/services/user";
import NotFound from "./components/fragments/NotFound";
import { ToastContainer } from "react-toastify";
import hideSidebar from './components/utils/hideSidebar';
import 'react-toastify/dist/ReactToastify.min.css';
import LoadingScreen from "./components/fragments/LoadingScreen";
/* import EvaluadorEvidencias from "./components/Herramientas/EvaluadorEvidencias";
import EvaluarActividad from "./components/Herramientas/EvaluarActividad"; */
import Modal from 'react-modal'
import ForgotPassword from "./components/login/ForgotPassword";
import ConfirmMailPassword from "./components/login/ConfirmMailPassword";
import ResetPassword from "./components/login/ResetPassword";
import ConfirmResetPassword from "./components/login/ConfirmResetPassword";
import { ResetScroll } from "./components/fragments/ResetScroll";
import HerramientasNotFound from "./components/plataforma/HerramientasNotFound";
import ReportesNotFound from "./components/escolar/ReportesNotFound";
import ConfiguracionMarketplace from "./components/comercial/ConfiguracionMarketplace/ConfiguracionMarketplace";
import ComercialRoutes from "./routes/comercial/ComercialRoutes";
import { useTranslation } from "react-i18next";
import './i18n.js'
import PaisesRoutes from "./routes/gestion/Paises/PaisesRoutes.js";
import FormadoresRoutes from "./routes/herramientas/Formadores/FormadoresRoutes.js";
import ListasDifusionRoutes from "./routes/herramientas/listasDifusion/ListasDifusionRoutes.jsx";
import GestorAvisosRoutes from "./routes/herramientas/gestorAvisos/GestorAvisosRoutes.jsx";
import CategoriasNotificacionesRoutes from "./routes/herramientas/CategoriasNotificaciones/CategoriasNotificacionesRoutes.js";
import CarpetasProfesorScreen from "./components/constructor/umas/CarpetasProfesorScreen";
const ListadoAlumnos = lazy(()=>import("./components/Herramientas/examenes/ListadoAlumnos.js"))
const ListadoAlumnosTareas = lazy(()=>import("./components/Herramientas/examenes/Tareas/ListadoAlumnosTareas"))
const ListadoGrupal = lazy(()=>import("./components/Herramientas/examenes/ListadoGrupal.js"))
const ListadoGrupalTareas = lazy(()=>import("./components/Herramientas/examenes/Tareas/ListadoGrupalTareas"))
const BibliotecaMedios = lazy(()=>import("./components/Herramientas/BibliotecaMedios/BibliotecaMedios"))
const PaquetesForm = lazy(()=>import("./components/visor/Paquetes/PaquetesForm"))
const EstructuraComercialHome = lazy(()=>import('./components/gestion/EstructuraComercialHome'))
const Herramientas = lazy(()=>import("./components/Herramientas/Herramientas"))
const HerramientasExternasOnly= lazy(()=>import("./components/Herramientas/HerramientasExternasOnly"))
const EvaluadorEvidencias = lazy(()=>import("./components/Herramientas/EvaluadorEvidencias"))
const EvaluarActividad = lazy(()=>import("./components/Herramientas/EvaluarActividad"))
const HerramientasHome = lazy(() => import("./components/Herramientas/HerramientasHome"))
const ReviewTests = lazy(() => import("./components/Herramientas/examenes/ReviewTests"))
const GestorTareas = lazy(() => import("./components/Herramientas/examenes/Tareas/GestorTareas.js"))
const HerramientasPaquetes = lazy(() => import("./components/Herramientas/paquetes/HerramientasPaquetes"))
const CrearHerramienta = lazy(() => import("./components/Herramientas/crearHerramientas/CrearHerramienta"))


const Login = lazy(()=>import('./components/login/login'))
const HomeView = lazy(()=>import('./components/home/Home'))
const UmaHome = lazy(()=>import('./components/constructor/umas/UmaHome'))
const CreadorTareas = lazy(()=>import("./components/Herramientas/examenes/Tareas/CreadorTareas.js"))
const UmaConfiguration = lazy(()=>import('./components/constructor/umas/UmaConfiguration'))
const EditUmaConfiguration = lazy(()=>import('./components/constructor/umas/EditUmaConfiguration'))
const ConstructorLayout = lazy(()=>import('./components/constructor/umas/ConstructorLayout'))
const Header = lazy(()=>import('./components/fragments/Header'))
const SideBar = lazy(()=>import('./components/fragments/SideBar'))
const ActividadRelacionar = lazy(()=>import('./components/constructor/umas/ActividadRelacionar'))
const ActividadOrdenar = lazy(()=>import('./components/constructor/umas/ActividadOrdenar'))
const ActividadPreguntas = lazy(()=>import('./components/constructor/umas/ActividadPreguntas'))
const FormularioChecklist = lazy(()=>import('./components/constructor/umas/FormularioChecklist'))
const ActividadCarousel = lazy(()=>import('./components/constructor/umas/ActividadCarousel'))
const ActividadRuleta = lazy(()=>import('./components/constructor/umas/ActividadRuleta'))
const ActividadOrdenarPalabras = lazy(()=>import('./components/constructor/umas/ActividadOrdenarPalabras'))
const ActividadMemorama = lazy(()=>import('./components/constructor/umas/ActividadMemorama'))
const ActividadRompecabezas = lazy(()=>import('./components/constructor/umas/ActividadRompecabezas'))
const ActividadCartas = lazy(()=>import('./components/constructor/umas/ActividadCartas'))
const ActividadArrastrar = lazy(()=>import('./components/constructor/umas/ActividadArrastrar'))
const ActividadEvidencias = lazy(()=>import('./components/constructor/umas/ActividadEvidencias'))
const ActividadEncontrar = lazy(()=>import('./components/constructor/umas/ActividadEncontrar'))
const ActividadSopaDeLetras = lazy(()=>import('./components/constructor/umas/ActividadSopaDeLetras'))
const ActividadHotspot = lazy(()=>import('./components/constructor/umas/ActividadHotspot'))
const ActividadCrucigrama = lazy(()=>import('./components/constructor/umas/ActividadCrucigrama'))
const ActividadDibujar = lazy(()=>import('./components/constructor/umas/ActividadDibujar'))
const ActividadLeeYEscucha = lazy(()=>import('./components/constructor/umas/ActividadLeeYEscucha'))
const ActividadHabla = lazy(()=>import('./components/constructor/umas/ActividadHabla'))
const ActividadEscuchaEscribe = lazy(()=>import('./components/constructor/umas/ActividadEscuchaEscribe'))
const ActividadHTML = lazy(()=>import('./components/constructor/umas/ActividadHTML'))
const ContenedorRecursos = lazy(()=>import('./components/constructor/umas/BloqueContenedorRecursos.js'))
const PapeleraLecciones = lazy(()=>import('./components/constructor/umas/PapeleraUmas'))
const PreguntasAbiertasBlock = lazy(()=>import('./components/constructor/umas/PreguntasAbiertasForm'))
const BloqueAutoevaluacion = lazy(()=>import('./components/constructor/umas/BloqueAutoevaluacion'))
const BloqueTest= lazy(()=>import('./components/constructor/umas/Test/BloqueTest'))
const BloqueTarjetas= lazy(()=>import('./components/constructor/umas/BlockTarjetas.js'))
const BloqueModelos3D= lazy(()=>import('./components/constructor/umas/BlockModelos3D.js'))
const BloqueComparadorImg= lazy(()=>import('./components/constructor/umas/BlockComparadorImagen.js'))
const ConstructorClickAndLearn= lazy(()=>import('./components/constructor/umas/ClickAndLearnConstructor.js'))

const EscuelasHome = lazy(()=>import('./components/escolar/EscuelasHome'))
const EscuelasForm = lazy(()=>import('./components/escolar/Escuelas/EscuelasForm'))
const CampusHome = lazy(()=>import('./components/escolar/CampusHome'))
const NuevoCampus = lazy(()=>import('./components/escolar/Campus/AddCampus'))
const CampusProfile = lazy(()=>import('./components/escolar/Campus/CampusProfile'))
const GruposDashboard = lazy(()=>import('./components/escolar/Grupos/GruposDashboard'))
const GruposConfiguration = lazy(()=>import('./components/escolar/Grupos/GruposConfiguration'))
const GruposView = lazy(()=>import('./components/escolar/Grupos/GruposView'))
const FamiliasHome = lazy(()=>import('./components/escolar/FamiliasHome'))
const AlumnosHome = lazy(()=>import('./components/escolar/AlumnosHome'))
const TutoresHome = lazy(()=>import('./components/escolar/TutoresHome'))
const FamiliaFormulario = lazy(()=>import('./components/escolar/Familias/FamiliaFormulario'))
const FamiliaDetalle = lazy(()=>import('./components/escolar/Familias/FamiliaDetalle'))
const AlumnoFormulario = lazy(()=>import('./components/escolar/Familias/AlumnoIndividualForm'))
const AlumnoView = lazy(()=>import('./components/escolar/Familias/AlumnoView'))
const UsuariosHome = lazy(()=>import('./components/escolar/UsuariosHome'))
const UsuarioForm = lazy(()=>import('./components/escolar/Usuarios/UsuarioForm'))
const AlumnoIndividualForm = lazy(()=>import('./components/escolar/Familias/AlumnoIndividualForm'))
const UsuarioDetalle = lazy(()=>import('./components/escolar/Usuarios/UsuarioDetalle'))
const UsuarioChangePassword = lazy(()=>import('./components/escolar/Usuarios/ChangePassword/UsuarioChangePassword.js'))
const CampusConfiguration = lazy(()=>import('./components/escolar/Campus/CampusConfiguration'))
const CampusInformation = lazy(()=>import('./components/escolar/Campus/CampusInformation'))
const ReportesGrupos = lazy(()=>import('./components/escolar/ReportesGrupos'))
const Reportes = lazy(()=>import('./components/escolar/Reportes'))
const ReportesLibros = lazy(()=>import('./components/escolar/Reportes/ReportesLibros'))
const ReportesActividades = lazy(()=>import('./components/escolar/Reportes/ReportesActividades'))
const ReportesAlumno = lazy(()=>import('./components/escolar/Reportes/ReportesAlumno'))
const ReportesEvaluaciones = lazy(()=>import('./components/escolar/Reportes/ReportesEvaluaciones'))
const ReportesDistribuidor = lazy(()=>import('./components/escolar/ReportesDistribuidor'))
const ReportesLicenciasDistribuidor = lazy(()=>import('./components/escolar/ReportesLicenciasDistribuidor'))
const LogrosInsignias = lazy(()=>import('./components/plataforma/Logros/LogrosInsignias'))
const AlumnoCargaMasiva = lazy(()=>import('./components/escolar/Familias/AlumnoCargaMasiva'))
const UsuarioCargaMasiva = lazy(()=>import('./components/escolar/Usuarios/UsuarioCargaMasiva'))
const TutorCargaMasiva = lazy(()=>import('./components/escolar/Familias/TutorCargaMasiva'))
const PapeleraUsuariosPlantel = lazy(()=>import('./components/escolar/Usuarios/PapeleraUsuariosPlantel'))
const PapeleraAlumnosTutores = lazy(()=>import('./components/escolar/Familias/PapeleraAlumnosTutores'))
const PapeleraUsuariosDistribucion = lazy(()=>import('./components/gestion/Distribuidores/Usuarios/PapeleraUsuariosDistribucion'))
const PapeleraUsuariosEditorial = lazy(()=>import('./components/gestion/Editoriales/Usuarios/PapeleraUsuariosEditorial'))


const FormularioAcademias = lazy(()=>import('./components/marketplace/Pantallas/PatallasFormularios/FormularioAcademias'))
const FormularioCurso = lazy(()=>import('./components/marketplace/Pantallas/PatallasFormularios/FormularioCursos'))
const FormularioItems = lazy(()=>import('./components/marketplace/Gestion/GestionarProductos/Formulario/FItems'))
const PantallaPrincipal = lazy(()=>import('./components/marketplace/Pantallas/PantallasCartas/PantallaPrincipal'))
const Principal = lazy(()=>import('./components/marketplace/Gestion/GestionarProductos/Cartas/Principal/Principal'))
const Temario = lazy(()=>import('./components/marketplace/Gestion/GestionarProductos/Temario/temario'))
const TablaCursos = lazy(()=>import('./components/marketplace/Pantallas/Tablas/TablaCursos'))
const TablaProductos = lazy(()=>import('./components/marketplace/Pantallas/Tablas/TablaAcademias'))
const TablaItems = lazy(()=>import('./components/marketplace/Pantallas/Tablas/TablaItems'))
const FItems = lazy(()=>import('./components/marketplace/Gestion/GestionarProductos/Formulario/FItems'))
const EditarFormularioCursos = lazy(()=>import('./components/marketplace/Pantallas/PantallasEditarFormularios/EditarFormularioCursos'))
const Formulario = lazy(()=>import('./components/marketplace/Gestion/GestionarProductos/Formulario/Formulario'))
const EditarFormularioAcademias = lazy(()=>import('./components/marketplace/Pantallas/PantallasEditarFormularios/EditarFormularioAcademias'))
const GruposHome = lazy(()=>import('./components/constructor/Grupos/GruposHome'))

const HomeViewMarket = lazy(()=>import('./components/marketplace/Market/Home/Views/HomeViewMarket'))
//const TablaItems = lazy(()=>import('./components/marketplace/Market/Cuenta/CuentaDirector/TablaDirector/THCuenta'))
const CoursesViewMarket = lazy(()=>import('./components/marketplace/Market/Home/Views/CoursesViewMarket'))
const AcademicViewMarket = lazy(()=>import('./components/marketplace/Market/Home/Views/AcademicViewMarket'))
const CardsViewMarket = lazy(()=>import('./components/marketplace/Market/Home/Views/CardsViewMarket'))
const DetailsViews = lazy(()=>import('./components/marketplace/Market/Home/Views/DetailsViews'))
const FormDetailSpecific = lazy(()=>import('./components/marketplace/Market/Home/Details/FormDetailSpecific'))
const FDS = lazy(()=>import('./components/marketplace/Market/Home/Details/FDS'))
const ShoppingCar = lazy(()=>import('./components/marketplace/Market/Home/Compras/ShoppingCar'))
const MetodoPago = lazy(()=>import('./components/marketplace/Market/Home/Pagar/MetodoPago'))
const SecuenceConfiguration = lazy(()=>import('./components/constructor/Grupos/SecuenceConfiguration'))
const GruposConsConfiguration = lazy(()=>import('./components/constructor/Grupos/GruposConsConfiguration'))
const Prueba = lazy(()=>import('./components/marketplace/Pantallas/prueba'))
const CardContainer = lazy(()=>import('./components/marketplace/Market/Cuenta/CardAccount/CardContainer'))
const CuentaContainer = lazy(()=>import('./components/marketplace/Market/Cuenta/CuentaDirector/CuentaContainer'))
const Favoritos = lazy(()=>import('./components/marketplace/Market/Cuenta/Favoritos/index'))
const MisCompras = lazy(()=>import('./components/marketplace/Market/Cuenta/Comprados/MisCompras'))
const Comprado = lazy(()=>import('./components/marketplace/Market/Cuenta/Comprados/IntermedioCompra'))
const Historial = lazy(()=>import('./components/marketplace/Market/Cuenta/Historial/Index'))
const LibrosHome = lazy(()=>import('./components/constructor/libros/LibrosHome'))
const PathConfiguration = lazy(()=>import('./components/constructor/libros/PathConfiguration'))
const PathHierarchical = lazy(()=>import('./components/constructor/libros/PathHierarchical'))
const Groupings = lazy(()=>import('./components/visor/Groupings'))
const ExamenesGroupings = lazy(()=>import('./components/visor/ExamenesGroupings.jsx'))
const TareasGroupings = lazy(()=>import('./components/visor/TareasGrouping.jsx'))
const BookInformation = lazy(()=>import('./components/visor/BookInformation'))
const ExamenInformation = lazy(()=>import('./components/visor/Examenes/ExamenInformation'))
const Tarea = lazy(()=>import('./components/Herramientas/examenes/Tareas/Tarea'))
const Book = lazy(()=>import('./components/visor/Book'))
const LogrosAlumno = lazy(()=>import('./components/plataforma/Logros/LogrosAlumno'))
const LogrosProfesor = lazy(()=>import('./components/plataforma/Logros/LogrosProfesor'))
const LogrosTutor = lazy(()=>import('./components/plataforma/Logros/LogrosTutor'))

const MiPerfil = lazy(()=>import('./components/plataforma/MiPerfil'))

const Escaparate = lazy(()=>import('./components/escolar/Escaparate/Escaparate'))

const ChangePassword  = lazy(() => import ("./components/plataforma/Logros/Fragments/ChangePassword/ChangePassword"))
const ErrorPassword  = lazy(() => import ("./components/login/ErrorPassword"))
const TokenExpired  = lazy(() => import ("./components/login/TokenExpired"))

const EditorialesHome = lazy(()=>import('./components/gestion/EditorialesHome'))
const EditorialesForm = lazy(()=>import('./components/gestion/Editoriales/EditorialesForm'))

const DistribuidoresHome = lazy(()=>import('./components/gestion/DistribuidoresHome'))
const DistribuidoresForm = lazy(()=>import('./components/gestion/Distribuidores/DistribuidoresForm'))
const UserDistribuidores = lazy(()=>import('./components/gestion/Distribuidores/Usuarios/UsersDistribuidores'))
const AddDistribuidores = lazy(()=>import('./components/gestion/Distribuidores/Usuarios/UserForm'))

const UserEditoriales = lazy(()=>import('./components/gestion/Editoriales/Usuarios/UserEditorales'))
const AddEditoriales = lazy(()=>import('./components/gestion/Editoriales/Usuarios/UserForm'))
const Recursos = lazy(()=>import('./components/gestion/Distribuidores/Recursos/Recursos'))

const UsersEscuelas = lazy(()=>import('./components/escolar/Escuelas/Usuarios/UserEscuelas'))
const UserFormEscuela = lazy(()=>import('./components/escolar/Escuelas/Usuarios/UserForm'))
const UserCorpPapelera = lazy(()=>import('./components/escolar/Escuelas/Usuarios/PapeleraUsuariosCorporativo'))

const RolesHome = lazy(()=>import('./components/gestion/RolesHome'));
const RolesForm = lazy(()=>import('./components/gestion/Roles/RolesForm'));
const CategoriasHome = lazy(()=>import('./components/gestion/CategoriasHome.js'));
const RutasHome = lazy(()=>import('./components/gestion/RutasHome.js'));
const LibroPreview = lazy(()=>import('./components/constructor/umas/ViewLibro'))
const CarpetaUmas = lazy(()=>import('./components/constructor/umas/CarpetaUmas'))
const ExamenesHome = lazy(()=>import('./components/Herramientas/examenes/ExamenesHome'))
const PapeleraExamenes = lazy(()=>import('./components/Herramientas/examenes/PapeleraExamenes'))
const TareasHome = lazy(()=>import('./components/Herramientas/examenes/Tareas/TareasHome.js'))
const PapeleraTareas = lazy(()=>import("./components/Herramientas/examenes/Tareas/PapeleraTareas.js"))
const ConfiguracionLicencias = lazy(()=>import('./components/Herramientas/licencias/ConfiguracionLicencias'))
const ConfiguracionMaterias = lazy(()=>import('./components/Herramientas/materias/ConfiguracionMaterias'))
const ConfiguracionCategorias = lazy(()=>import('./components/Herramientas/materias/ConfiguracionCategorias'))
const ModificarUmas = lazy(()=>import('./components/Herramientas/ModificadorUmas/ModificarUmas.jsx'))



Modal.setAppElement('#root')
function App() {
  const [sidebar, setSidebar] = useState(true);//state para el sidebar responsive
  const [sidebarlg, setSidebarlg] = useState(false);//state para el sidebar web
  /* const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }; 

  useEffect(()=>{
    changeLanguage('en')
  },[]) */

  return (
    
    <ProvideAuth>
      <ToastContainer/>
      <UserProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingScreen/>}>
        <ResetScroll>
          <Routes>
            {/* Login */}
            <Route exact path='/' element={<Login />} />
            <Route exact path='/change/password' element={<ForgotPassword />} />
            <Route exact path='/verify/email' element={<ConfirmMailPassword />} />
            <Route path='/recover/password/:idUsuario/:codigo' element={<ResetPassword />} />
            <Route exact path='/verify/password' element={<ConfirmResetPassword />} />
            <Route exact path='/permission/denied/password' element={<ErrorPassword />} />
            <Route exact path='/token/expired' element={<TokenExpired />} />
            <Route element={<PrivateRoute />}>
              <Route path='/home' element={<HomeView />} />
              {/* Constructor */}
                {/* UMA/Reactivos */}
              <Route path='/examenes' element={<ExamenesHome />}></Route>
              <Route path='/papeleraExamenes' element={<PapeleraExamenes />}></Route>
              <Route path='/tareasHome' element={<TareasHome />}></Route>
              <Route path='/papeleraTareas' element={<PapeleraTareas />}></Route>
              <Route path='/constructor' element={<UmaHome />}></Route>
              <Route path='/constructor/CarpetaUma/:carpetaId' element={<CarpetaUmas />}></Route>              
              <Route path='/constructor/CarpetaExamen/:carpetaId' element={<CarpetasProfesorScreen contentType="EXAMEN"/>}></Route>              
              <Route path='/constructor/CarpetaTarea/:carpetaId' element={<CarpetasProfesorScreen contentType="TAREA"/>}></Route>              
              <Route path='/LibroPreview/:umaId' element={<LibroPreview evaluable={false} view />}></Route>
              <Route path='/constructor/UmaConfiguration' element={<UmaConfiguration sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/UmaConfiguration/:umaId' element={<EditUmaConfiguration sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />}></Route>
              <Route path='/creadorTareas/:tareaId' element={<CreadorTareas sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />}></Route>
              <Route path='/creadorTareas' element={<CreadorTareas sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />}></Route>
              <Route path='/constructor/ConstructorLayout/:umaId' element={<ConstructorLayout sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Preguntas' element={<ActividadPreguntas sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Relacionar' element={<ActividadRelacionar sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Ordenar' element={<ActividadOrdenar sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Formulario-Checklist' element={<FormularioChecklist sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Carousel' element={<ActividadCarousel sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Ruleta' element={<ActividadRuleta sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Ordenar-Palabras' element={<ActividadOrdenarPalabras sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Memorama' element={<ActividadMemorama sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Rompecabezas' element={<ActividadRompecabezas sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Cartas' element={<ActividadCartas sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Arrastrar' element={<ActividadArrastrar sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Evidencias' element={<ActividadEvidencias sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Encontrar' element={<ActividadEncontrar sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Sopa-de-letras' element={<ActividadSopaDeLetras sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Hotspot' element={<ActividadHotspot sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Crucigrama' element={<ActividadCrucigrama sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Dibujar' element={<ActividadDibujar sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Lee-Escucha' element={<ActividadLeeYEscucha sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Habla' element={<ActividadHabla sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-Escucha-Escribe' element={<ActividadEscuchaEscribe sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Actividad-html' element={<ActividadHTML sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/ContenedorRecursos' element={<ContenedorRecursos sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/PreguntasAbiertas' element={<PreguntasAbiertasBlock sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/BloqueAutoevaluacion' element={<BloqueAutoevaluacion sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/BloqueTest' element={<BloqueTest sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/BloqueTarjetas' element={<BloqueTarjetas sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/Modelos3D' element={<BloqueModelos3D sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/BloqueComparadorImagenes' element={<BloqueComparadorImg sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
              <Route path='/constructor/ConstructorLayout/BloqueClickAndLearn' element={<ConstructorClickAndLearn sidebarlg={sidebarlg} setSidebarlg={setSidebarlg} />} ></Route>
                {/* GRUPOS */}
              <Route path='/constructor/Grupos' element={<GruposHome/>} ></Route>
              <Route path='/constructor/Grupos/GruposConfiguration' element={<GruposConsConfiguration/>} ></Route>
              <Route path='/constructor/Grupos/GruposConfiguration/:agrupadorId' element={<GruposConsConfiguration edit/>}></Route>
              <Route path='/constructor/Grupos/SecuenceConfiguration/:agrupadorId' element={<SecuenceConfiguration/>} ></Route>
              {/* LIBROS */}
              <Route path='/constructor/Libros' element={<LibrosHome/>} ></Route>
              <Route path='/constructor/CarpetaLibro/:carpetaId' element={<LibrosHome />}></Route>
              <Route path='/constructor/Libros/PathConfiguration' element={<PathConfiguration/>} ></Route>
              <Route path='/constructor/Libros/PathConfiguration/:agrupadorId' element={<PathConfiguration edit/>}></Route>
              <Route path='/constructor/Libros/SecuenceConfiguration/:agrupadorId' element={<PathHierarchical/>} ></Route>
              {/* Visor */}
              <Route path='/visor/groupings' element={<Groupings/>} ></Route>
              <Route path='/visor/groupings/examenes' element={<ExamenesGroupings/>} ></Route>
              <Route path='/visor/groupings/tareas' element={<TareasGroupings/>} ></Route>
              <Route path='/visor/groupings' element={<Groupings/>} ></Route>
              <Route path='/visor/BookInformation/:agrupadorId' element={<BookInformation evaluable={false}/>}/>
              <Route path='/visor/Examenes/:examenId/group/:groupId' element={<ExamenInformation evaluable={true}/>}/>
              <Route path='/visor/Tareas/:tareaId/group/:groupId' element={<Tarea evaluable={true}/>}/>
              <Route path="/visor/BookInformation/:agrupadorId/group/:groupId/subject/:subjectId" element={<BookInformation evaluable={true}/>}/>
              
              {/* Escolar */}  
              <Route path='/escolar' element={<EscuelasHome/>}/>
              <Route path="/escolar/new" element={<EscuelasForm/>}/>
              <Route path="/escolar/:idEscuela/users/" element={<UsersEscuelas/>}/>
              <Route path="/escolar/:idEscuela/users/new" element={<UserFormEscuela/>}/>
              <Route path="/escolar/:idEscuela/users/:idUser" element={<UserFormEscuela edit/>}/>
              <Route path="/escolar/:idEscuela/users/:idUser/view" element={<UserFormEscuela view/>}/>
              <Route path="/escuela/:idEscuela/users/" element={<UsersEscuelas/>}/>
              <Route path="/escuela/:idEscuela/users/new" element={<UserFormEscuela/>}/>
              <Route path="/escuela/:idEscuela/users/papelera" element={<UserCorpPapelera/>}/>
              <Route path="/escuela/:idEscuela/users/:idUser" element={<UserFormEscuela edit/>}/>
              <Route path="/escuela/:idEscuela/users/:idUser/view" element={<UserFormEscuela view/>}/>
              <Route path="/escuela/:idEscuela/users/:idUsuario/changePassword" element={<UsuarioChangePassword esDistribuidor esEstructura/>}/>
              <Route path="/escolar/new/:idDistribuidor" element={<EscuelasForm/>}/>
              <Route path="/escolar/:idDistribuidor/:idEscuela" element={<EscuelasForm edit/>}/>
              <Route path="/escolar/:idDistribuidor/:idEscuela/view" element={<EscuelasForm view/>}/>
              <Route path="/escolar/:idEscuela" element={<EscuelasForm edit/>}/>
              <Route path="/escolar/:idEscuela/view" element={<EscuelasForm view/>}/>
              <Route path="/escolar/:idEscuela/campus" element={<CampusHome/>}/>
              <Route path='/escolar/:idEscuela/campus/new' element={<NuevoCampus configuracion={'configuracion'}/>}/>
              <Route path='/escolar/:idEscuela/campus/:idCampus' element={<CampusProfile/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/configuracion" element={<NuevoCampus edit configuracion={'configuracion'}/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/editar" element={<NuevoCampus edit configuracion={'editar'}/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/grupos" element={<GruposDashboard/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/grupos/nuevo" element={<GruposConfiguration/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/grupos/:idGrupo" element={<GruposConfiguration edit/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/grupos/:idGrupo/view" element={<GruposView edit/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias" element={<FamiliasHome/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/alumnos" element={<AlumnosHome/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/alumnos/nuevo" element={<AlumnoIndividualForm/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/tutores" element={<TutoresHome/>}/>
              <Route path='/escolar/:idEscuela/campus/:idCampus/tutores/changePassword/:idUsuario' element={<UsuarioChangePassword esTutor/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias/nuevo" element={<FamiliaFormulario/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias/:idFamilia" element={<FamiliaFormulario edit/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias/:idFamilia/view" element={<FamiliaDetalle/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias/tutor/:idTutor" element={<FamiliaFormulario edit tutor/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias/tutor/:idTutor/view" element={<FamiliaDetalle tutor/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/alumno/:idAlumno" element={<AlumnoIndividualForm edit alumno/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/alumno/:idAlumno/view" element={<AlumnoView/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/usuarios" element={<UsuariosHome/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/usuarios/nuevo" element={<UsuarioForm/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/usuarios/:idUsuario" element={<UsuarioForm edit/>}/>
              <Route path='/escolar/:idEscuela/campus/:idCampus/usuarios/view/:idUsuario' element={<UsuarioDetalle/>}/>
              <Route path='/escolar/:idEscuela/campus/:idCampus/usuarios/changePassword/:idUsuario' element={<UsuarioChangePassword/>}/>
              <Route path="/escuelas/:idEscuela/plantel/:idPlantel/recursos/" element={<Recursos/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/alumnos/carga_masiva" element={<AlumnoCargaMasiva/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/usuarios/carga_masiva" element={<UsuarioCargaMasiva/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/tutores/carga_masiva" element={<TutorCargaMasiva/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/usuarios/papelera" element={<PapeleraUsuariosPlantel/>}/>
              <Route path="/escolar/:idEscuela/campus/:idCampus/familias/papelera" element={<PapeleraAlumnosTutores/>}/>
              <Route path="/distribucion/users/papelera" element={<PapeleraUsuariosDistribucion/>}/>
              <Route path="/distribuciones/:idDistribuidor/users/papelera" element={<PapeleraUsuariosDistribucion/>}/>
              <Route path="/editoriales/:idEditorial/users/papelera" element={<PapeleraUsuariosEditorial/>}/>
              <Route path="/constructor/papelera" element={<PapeleraLecciones/>}/>

              {/* Escaparate */}
              <Route path='/escaparate' element={<Groupings/>}/>
              <Route path='/paquetes/:idInstitucion' element={<PaquetesForm/>}/>
              <Route path='/paquetes/distribuciones/:idDistribucion/paquetes/:idPaquete' element={<PaquetesForm edit/>}/>
              <Route path='/paquetes/distribuciones/:idDistribucion/paquetes/:idPaquete/view' element={<PaquetesForm view/>}/>
              <Route path='/paquetes/corporativo/:idCorporativo/paquetes/:idPaquete' element={<PaquetesForm edit/>}/>
              <Route path='/paquetes/corporativo/:idCorporativo/paquetes/:idPaquete/view' element={<PaquetesForm view/>}/>
              <Route path='/paquetes/plantel/:idPlantel/paquetes/:idPaquete' element={<PaquetesForm edit/>}/>
              <Route path='/paquetes/plantel/:idPlantel/paquetes/:idPaquete/view' element={<PaquetesForm view/>}/>

              {/**Plataforma */}
              <Route path="/plataforma/logros" element={<LogrosProfesor/>}/>
              <Route path='/plataforma/logros/alumno' element={<LogrosAlumno/>}/>
              <Route path='/plataforma/logros/alumno/:idGrupo/:idAlumno' element={<LogrosAlumno profesor/>}/>
              <Route path='/plataforma/logros/change/password/alumno/:idAlumno' element={<ChangePassword/>}/>
              <Route path='/profile' exact element={<MiPerfil/>}/>

              {/* Herramientas */}
              <Route path='/herramientas' element={<Herramientas/>}></Route>
              <Route path='/herramientasExternas' element={<HerramientasExternasOnly />}/>
              <Route path='/herramientas/evidencias' element={<EvaluadorEvidencias />}></Route>
              <Route path='/herramientas/evidencias/evaluarActividad' element={<EvaluarActividad />}></Route>
              <Route path="/herramientas/contrasenias" element={<LogrosProfesor herramientas/>}/>
              <Route path='/recursos/herramientas' element={<HerramientasHome />}/>
              <Route path='/herramientas/gestionExamenes' element={<ReviewTests />}></Route>
              <Route path='/herramientas/gestionTareas' element={<GestorTareas />}></Route>
              <Route path='/herramientas/gestionExamenes/listadoGrupal/:groupId' element={<ListadoGrupal />}></Route>
              <Route path='/herramientas/gestionExamenes/listadoGrupal/:groupId/examen/:examenId' element={<ListadoAlumnos />}></Route>
              <Route path='/herramientas/gestionExamenes/listadoGrupalTareas/:groupId' element={<ListadoGrupalTareas />}></Route>
              <Route path='/herramientas/gestionExamenes/listadoGrupalTareas/:groupId/tarea/:tareaId' element={<ListadoAlumnosTareas />}></Route>
              <Route path='/herramientas/paquetes' element={<HerramientasPaquetes />}/>
              <Route path='/herramientas/bibliotecaMedios' element={<BibliotecaMedios />}/>
              <Route path='/herramientas/crearHerramienta' element={<CrearHerramienta />}/>              
              <Route path='/herramientas/autores/*' element={<FormadoresRoutes />}/>
              <Route path="/herramientas/gestor-avisos/*" element={<GestorAvisosRoutes />} />
              <Route path='/herramientas/listas-difusion/*' element={<ListasDifusionRoutes />} />
              <Route path='/herramientas/categoriasNotificaciones/*' element={<CategoriasNotificacionesRoutes />}/>
              <Route path='/herramientas/ModificarUmas' element={<ModificarUmas />}/>
              <Route path='/herramientas/changePassword' element={<UsuarioChangePassword esAdmin/>}/>

              {/* Editoriales */}

              <Route path="/editoriales" element={<EditorialesHome/>}/>
              <Route path='/editoriales/new' element={<EditorialesForm/>}/>
              <Route path='/editoriales/:idEditorial' element={<EditorialesForm edit/>}/>
              <Route path='/editoriales/:idEditorial/view' element={<EditorialesForm view/>}/>
              <Route path="/editoriales/:idEditorial/users/" element={<UserEditoriales/>}/>
              <Route path="/editoriales/:idEditorial/users/new" element={<AddEditoriales />}/>
              <Route path="/editoriales/:idEditorial/users/:idUser" element={<AddEditoriales edit/>}/>
              <Route path="/editoriales/:idEditorial/users/:idUser/view" element={<AddEditoriales view/>}/>
              <Route path="/editorial/users/" element={<UserEditoriales/>}/>
              <Route path="/editorial/users/new" element={<AddEditoriales/>}/>
              <Route path="/editorial/users/:idUser" element={<AddEditoriales edit/>}/>
              <Route path="/editorial/users/:idUser/view" element={<AddEditoriales view/>}/>

              {/* Distribuidores */}

              <Route path="/distribuciones" element={<DistribuidoresHome/>}/>
              <Route path='/distribuciones/new' element={<DistribuidoresForm/>}/>
              <Route path='/distribuciones/:idEditorial/new' element={<DistribuidoresForm/>}/>
              <Route path='/distribuciones/:idDistribuidor' element={<DistribuidoresForm edit/>}/>
              <Route path='/distribuciones/:idDistribuidor/view' element={<DistribuidoresForm view/>}/>
              <Route path="/distribuciones/:idDistribuidor/users/" element={<UserDistribuidores/>}/>
              <Route path="/distribucion/users/" element={<UserDistribuidores/>}/>
              <Route path="/distribucion/:idDistribuidor/users/new" element={<AddDistribuidores />}/>
              <Route path="/distribucion/:idDistribuidor/users/:idUser" element={<AddDistribuidores edit/>}/>
              <Route path="/distribucion/:idDistribuidor/users/:idUser/view" element={<AddDistribuidores view/>}/>
              <Route path="/distribucion/:idDistribuidor/users/:idUsuario/changePassword" element={<UsuarioChangePassword esDistribuidor/>}/>
              <Route path="/distribuciones/:idDistribuidor/users/new" element={<AddDistribuidores />}/>
              <Route path="/distribuciones/:idDistribuidor/users/:idUser" element={<AddDistribuidores edit/>}/>
              <Route path="/distribuciones/:idDistribuidor/users/:idUser/view" element={<AddDistribuidores view/>}/>
              <Route path="/distribucion/:idDistribuidor/recursos/" element={<Recursos/>}/>
              <Route path="/distribucion/:idDistribuidor/corporativo/:idCorporativo/recursos/" element={<Recursos/>}/>


              {/**MARKET PLACE */}
              <Route path="/Gestion/GestionProductos/Academias/Formulario" exact element={<FormularioAcademias/>}/>
              <Route path="/Gestion/GestionProductos/Cursos/Formulario" exact element={<FormularioCurso />} />
              <Route path="/Gestion/GestionProductos/Items/Formulario" exact element={<FormularioItems/>} />
              

              <Route path="/Gestion/GestionProductos/Cursos/FormularioEditar/:id" exact element={<EditarFormularioCursos />} />
              <Route path="/Gestion/GestionProductos/Academias/Formulario/:Editar/:id" exact element={<EditarFormularioAcademias />} />
              <Route path="/Gestion/GestionProductos/Academias/Formulario/:Duplicar/:id" exact element={<EditarFormularioAcademias />} />
              <Route path="/Gestion/GestionProductos/Academias/Formulario/:Vista/:id" exact element={<EditarFormularioAcademias />} />
              <Route path="/Gestion/GestionProductos/Cursos" exact element={<TablaCursos />} />
              <Route path="/Gestion/GestionProductos/Academias" exact element={<TablaProductos />} />
              <Route path="/Gestion/GestionProductos/Items" exact element={<TablaItems/>} />
              <Route path="/Gestion/GestionProductos/Items/Formulario/:Editar/:id" exact element={<FItems />} />
              <Route path="/Gestion/GestionProductos/Items/Formulario/:Vista/:id" exact element={<FItems />} />

              <Route path="/Gestion/formulario" exact element={<Formulario />} />
              <Route path="/Gestion/HistorialPagos" exact element={<CuentaContainer/>} />
              <Route path="/Gestion/formulario/temario" exact element={<Temario />} />
              <Route path="/Gestion/GestionProductos" exact element={<Principal />} />
              <Route path="/Gestion" exact element={<PantallaPrincipal/>} />
              <Route path="/Gestion/estructuraBasica" exact element={<EstructuraComercialHome />} />
              
              {/* <Route path="/MarketPlace" exact element={<HomeViewMarket/>} /> */}
              {/* <Route path="/MarketPlace/Course" exact element={<CoursesViewMarket/>} />
              <Route path="/MarketPlace/Academias" exact element={<AcademicViewMarket/>} />
              <Route path="/MarketPlace/Cards" exact element={<CardsViewMarket/>} />
              <Route path="/MarketPlace/:DetallesProducto/:id/:tipo/:stipo" exact element={<DetailsViews/>} />
              <Route path="/MarketPlace/DetallesProductoSpecific/:id/:tipo/:word/:stipo/:favorito" element={<FormDetailSpecific/>} />
              <Route path="/MarketPlace/DetallesProduct/:id/:tipo/:buy" exact element={<FDS/>} />
              <Route path="/MarketPlace/:ShoppingCar" exact element={<ShoppingCar/>} />
              <Route path="/MarketPlace/PayMethod" exact element={<MetodoPago/>} />
              <Route path="/prueba" exact element={<Prueba/>} /> */}

              {/**marketplace account */}
              {/* <Route path="/MarketPlace/:Account/Home" exact element={<CardContainer/>}/>
              <Route path="/MarketPlace/Account/:Favoritos" exact element={<Favoritos/>}/>
              <Route path="/MarketPlace/Account/MisCompras" exact element={<MisCompras/>}/>
              <Route path="/MarketPlace/Account/Comprado" exact element={<Comprado/>}/>
              <Route path="/MarketPlace/Account/Comprado2" exact element={<ComprasGenerales/>}/>
              <Route path="/MarketPlace/Account/Historial" exact element={<Historial/>}/> */}

              {/* Roles */}
              <Route path="/roles" element={<RolesHome/>}/>
              <Route path='/roles/new' element={<RolesForm/>}/>
              <Route path='/roles/:idRol' element={<RolesForm edit/>}/>

              {/* Países */}
              <Route path="/paises/*" element={<PaisesRoutes />}/>

              {/* Categorías */}
              <Route path="/categorias" element={<CategoriasHome/>}/>

              {/* Rutas */}
              <Route path="/rutas" element={<RutasHome/>}/>

              <Route path="/marketplace/*" element={<ComercialRoutes/>}/>
              <Route path='/escolar/reportes' element={<Reportes />}/>
              <Route path='/escolar/reportesGrupo' element={<ReportesGrupos />}/>
              <Route path='/escolar/reportes/:idGrupo/asignatura' element={<ReportesLibros />}/>
              <Route path='/escolar/reportes/:idGrupo/actividades/:idLibro' element={<ReportesActividades />}/>
              <Route path='/escolar/reportes/:idGrupo/actividades/:idLibro/alumno/:idAlumno' element={<ReportesAlumno />}/>
              <Route path='/escolar/reportes/:idGrupo/libro/:idLibro/evaluaciones/:idEvaluacion' element={<ReportesEvaluaciones />}/>
              <Route path='/escolar/reportesDistribuidor' element={<ReportesDistribuidor />}/>
              <Route path='/escolar/reportesLicenciasDistribuidor' element={<ReportesLicenciasDistribuidor />}/>
              <Route path='/plataforma/logros/:idLibro/alumno/:idAlumno/insignias' element={<LogrosInsignias />}/>
              <Route path='/herramientas/:idEditorial/configuracionLicencias' element={<ConfiguracionLicencias />}/>
              <Route path='/herramientas/configuracionMaterias' element={<ConfiguracionMaterias />}/>
              <Route path='/herramientas/configuracionCategorias' element={<ConfiguracionCategorias />}/>
            </Route>
            <Route path="*" element={<NotFound/>}/>
            <Route path='/escolar/reporte' element={<ReportesNotFound />}/>
          </Routes>
        </ResetScroll>
        </Suspense>
      </BrowserRouter>
      </UserProvider>
    </ProvideAuth>
  )
  function PrivateRoute({ children, ...rest }) {
    let navigate = useNavigate()
    let location = useLocation()
    var token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token')
    let auth = useAuth()

    let sideBar = hideSidebar(location.pathname)
    axios.interceptors.response.use(
      response=>response,
      error=>{
        if(error.response.status === 401){
          if(error.response.data.message==='Not authorized'){
            navigate('/home')
          }else if(error.response.data.message==='Datos de acceso incorrectos'){
            auth.setCred(error.response.data.message)
          }else{
            if(error.response.data.message !== "Contraseña incorrecta") auth.signOut()
          }
        }
        return {error:error.response.data}
      }
    )

    return auth.login || token != null ?
      (<div>
        <Header title={getHeaderTitle(location.pathname)} icon={getHeaderIcon(location.pathname)} sidebar={sidebar} setSidebar={setSidebar}/>
        <div className="flex pt-14 md:pt-16" >
          {sidebar && 
            <div className={`${sidebar ? 'hidden' : 'block'} ${sidebarlg ? 'lg:hidden' : 'lg:block'}`}>
              <SideBar  />
            </div>
          }
          <div className={`${sideBar ? 'w-full' : 'w-full lg:pl-24'}`}>
            <Outlet/>
          </div>
        </div>
      </div>

      ) : <Navigate to='/' replace state={{ from: location }} />
  }
}

export default App