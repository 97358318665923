import { toast } from "react-toastify";
import { deleteAviso } from "../../../services/Api/GestorAvisos";
import alertConfiguration from "../../../utils/alertsUtils";

const DeleteAvisos = ({ onCloseModal, getAvisos, selectedAvisos }) => {
  const onDeleteAvisos = async () => {
    try {
      await Promise.all(
        selectedAvisos.map(async (aviso) => await deleteAviso(aviso._id))
      );
      await getAvisos();
      toast.success("Avisos eliminados correctamente.", alertConfiguration);
    } catch (error) {
      toast.error("Ha ocurrido un error.", alertConfiguration);
      console.log(error);
    } finally {
      onCloseModal();
    }
  };

  return (
    <div className="grid gap-y-4">
      <div className="max-w-2xl font-semibold text-lg text-zinc-400 md:px-10 text-justify w-full px-3 mb-3">
        <p>
          Al confirmar la acción, se eliminará de forma permanente el o los
          avisos seleccionados, y ya no se podrán consultar ni modificar
          posteriormente, y tampoco se mostrarán en los reportes. De igual
          forma, si el aviso ya fue enviado o está programado para envío, los
          usuarios receptores ya tampoco lo podrán consultar sin importar si ya
          lo marcaron o no como leído.
        </p>
      </div>
      <div className="flex justify-center gap-x-4">
        <button
          className="flex items-center justify-center bg-nimbusDark w-32 h-10 rounded-lg text-white font-semibold cursor-pointer mr-5"
          onClick={onDeleteAvisos}
        >
          <span>Confirmar</span>
        </button>

        <button
          className="flex items-center justify-center bg-gray-200 w-32 h-10 rounded-lg text-nimbusDark font-semibold cursor-pointer mr-5"
          onClick={onCloseModal}
        >
          <span>Cancelar</span>
        </button>
      </div>
    </div>
  );
};

export default DeleteAvisos;
