import { formatPostDate, formatShowDate } from "../../../utils/dateFunctions";
import { ReactComponent as Delete } from "../../../../assets/icons/deleteWhite.svg";
import { useContext, useEffect, useState } from "react";
import { useGestorAvisos } from "../GestorAvisosContext";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";
import NimbusModal from "../../../fragments/NimbusModal";
import DeleteAvisos from "./DeleteAvisos";
import { getCategoriasNotificaciones } from "../../../services/Api/CategoriaNotificaciones";
import { UserContext } from "../../../services/user";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import {
  estatusOptions,
  visibilidadOptions,
} from "../../../utils/gestorAvisosUtils";
import InputSelect from "../../../fragments/Inputs/InputSelect";

const customStyles = {
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

export const FILTRO_GESTOR_AVISOS_INITIAL_VALUES = {
  fechaInicio: "",
  fechaFin: "",
  categoria: "",
  estatus: "",
  visibilidad: "",
  busqueda: "",
};

const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

const GestorAvisosFilterForm = ({ getAvisos }) => {
  const { user } = useContext(UserContext);
  const { avisos, setAvisosFiltro, currentData } = useGestorAvisos();

  const [isLoading, setIsLoading] = useState(false);
  const [filtro, setFiltro] = useState(FILTRO_GESTOR_AVISOS_INITIAL_VALUES);
  const [dateFieldType, setDateFieldType] = useState("text");
  const [deleteModal, setDeleteModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const selectedAvisos = currentData().filter((aviso) => aviso.esSeleccionado);

  useEffect(() => {
    obtenerCategoriasNotificaciones();
  }, []);

  useEffect(() => {
    const { fechaInicio, fechaFin, estatus, visibilidad, categoria, busqueda } =
      filtro;

    let newAvisosFiltro = avisos;
    newAvisosFiltro = filterFecha(newAvisosFiltro, fechaInicio, fechaFin);
    newAvisosFiltro = filterCategoria(newAvisosFiltro, categoria);
    newAvisosFiltro = filterEstatus(newAvisosFiltro, estatus);
    newAvisosFiltro = filterVisibilidad(newAvisosFiltro, visibilidad);
    newAvisosFiltro = filterBusqueda(newAvisosFiltro, busqueda.toLowerCase());
    setAvisosFiltro(newAvisosFiltro);
  }, [filtro]);

  useEffect(() => {
    setFiltro({ ...filtro, fechaFin: "" });
  }, [filtro?.fechaInicio]);

  // Petición para obtener listado de categoirías
  const obtenerCategoriasNotificaciones = async () => {
    try {
      setIsLoading(true);
      let resp = await getCategoriasNotificaciones(user._id);

      resp = resp.sort((a, b) => {
        if (a.fechaCreacion > b.fechaCreacion) return 1;
        if (a.fechaCreacion < b.fechaCreacion) return -1;
        return 0;
      });

      setCategories(resp);
    } catch (error) {
      console.error(error);
      toast.error(
        "Error al obtener las categorias de notificaciones",
        alertConfiguration
      );
    } finally {
      setIsLoading(false);
    }
  };

  const filterFecha = (avisos, fechaInicio, fechaFin) => {
    if (fechaInicio === "") return avisos;
    const startFrom = new Date(fechaInicio);
    let newAvisosFiltro = avisos.filter((aviso) => {
      const avisoDate = new Date(
        formatPostDate(new Date(aviso?.fechaCreacion))
      );
      if (fechaFin !== "") {
        const endOn = new Date(fechaFin);
        if (
          avisoDate.getTime() >= startFrom.getTime() &&
          avisoDate.getTime() <= endOn.getTime()
        ) {
          return aviso;
        }
      } else if (avisoDate.getTime() === startFrom.getTime()) return aviso;
    });

    return newAvisosFiltro;
  };

  const filterCategoria = (avisos, categoria) => {
    if (categoria === "") return avisos;
    const newAvisosFiltro = avisos.filter(
      (aviso) => aviso?.categoria?._id === categoria
    );

    return newAvisosFiltro;
  };

  const filterEstatus = (avisos, estatus) => {
    if (estatus === "") return avisos;
    const newAvisosFiltro = avisos.filter((aviso) =>
      aviso?.estatus.includes(estatus)
    );

    return newAvisosFiltro;
  };

  const filterVisibilidad = (avisos, visibilidad) => {
    if (visibilidad === "") return avisos;
    const newAvisosFiltro = avisos.filter((aviso) =>
      visibilidad === "1"
        ? aviso?.visibilidad === false
        : aviso?.visibilidad === true
    );

    return newAvisosFiltro;
  };

  const filterBusqueda = (avisos, busqueda) => {
    if (busqueda === "") return avisos;

    const newAvisosFiltro = avisos.filter((aviso) =>
      aviso?.nombre?.toLowerCase().includes(busqueda)
    );
    return newAvisosFiltro;
  };

  return (
    <>
      <div className="flex flex-row">
        <div>
          <input
            type={dateFieldType}
            name="fechaInicio"
            id="fechaInicio"
            placeholder="Fecha de creación inicial"
            onFocus={() => setDateFieldType("date")}
            onBlur={() => setDateFieldType("text")}
            value={
              filtro.fechaInicio
                ? formatShowDate(addDays(new Date(filtro.fechaInicio)))
                : ""
            }
            onChange={(e) =>
              setFiltro({ ...filtro, fechaInicio: e.target.value })
            }
            className={
              "rounded-l-lg p-2 w-full border-divider border text-black/60"
            }
          />
        </div>
        <div>
          <input
            type={dateFieldType}
            name="fechaFin"
            id="fechaFin"
            placeholder="Fecha de creación final"
            onFocus={() => setDateFieldType("date")}
            onBlur={() => setDateFieldType("text")}
            disabled={filtro?.fechaInicio === ""}
            min={filtro?.fechaInicio ?? undefined}
            value={
              filtro.fechaFin
                ? formatShowDate(addDays(new Date(filtro.fechaFin)))
                : ""
            }
            onChange={(e) => setFiltro({ ...filtro, fechaFin: e.target.value })}
            className={
              "rounded-r-lg p-2 w-full border-divider border text-black/60"
            }
          />
        </div>
      </div>

      <div className="flex flex-row">
        <InputSelect
          id="categoria"
          name="categoria"
          value={filtro.categoria}
          options={categories ?? []}
          onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
          styles="w-80 lg:w-full"
          label={"Categoría"}
          disabled={isLoading}
          topLabel={true}
        />
      </div>

      <div className="flex flex-row">
        <InputSelect
          id="estatus"
          name="estatus"
          value={filtro.estatus}
          options={estatusOptions ?? []}
          onChange={(e) => setFiltro({ ...filtro, estatus: e.target.value })}
          styles="w-80 lg:w-full"
          label={"Estatus"}
          topLabel={true}
        />
      </div>

      <div className="flex flex-row">
        <InputSelect
          id="visibilidad"
          name="visibilidad"
          value={filtro.visibilidad}
          options={visibilidadOptions ?? []}
          onChange={(e) =>
            setFiltro({ ...filtro, visibilidad: e.target.value })
          }
          styles="w-80 lg:w-[130px]"
          label={"Visibilidad"}
          topLabel={true}
        />
      </div>

      <div className="flex flex-row">
        <InputBuscar
          onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
        />
      </div>

      <div className="flex flex-row">
        <button
          type="button"
          className="w-full sm:w-80 lg:w-fit bg-[#18bafb] text-white py-2 p-4 rounded-lg flex items-center justify-center disabled:bg-[#18bafb]/40"
          onClick={() => setDeleteModal(true)}
          disabled={selectedAvisos?.length === 0}
        >
          <Delete className="w-5 h-5 mr-2 mb-1" />
          Borrar
        </button>
      </div>

      <NimbusModal isOpen={deleteModal} styles={customStyles}>
        <DeleteAvisos
          onCloseModal={() => setDeleteModal(false)}
          selectedAvisos={selectedAvisos}
          getAvisos={getAvisos}
        />
      </NimbusModal>
    </>
  );
};

export default GestorAvisosFilterForm;
