import { useEffect, useState } from "react";
import EliminarIcon from "../../../../assets/icons/trash2.svg";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import AudioComponent from "./fragments/AudioComponent";
import VideoComponent from "./fragments/VideoComponent";
import PieDeFoto from "./fragments/PieDeFoto";
import { useGestorAvisosDetalles } from "./GestorAvisosDetallesContext";
import { CONTENT, CONTENT_OPTIONS } from "../../../utils/gestorAvisosUtils";
import PdfComponent from "./fragments/PdfComponent";
import ImagenComponent from "./fragments/ImagenComponent";
import TextoComponent from "./fragments/TextoComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectContent } from "../../../../reducers/avisoData/avisoDataSelector";
import {
  deleteBloqueContenido,
  setBloqueId,
} from "../../../../reducers/avisoData/avisoDataAction";

const ContentBox = () => {
  const { editandoTexto, ver } = useGestorAvisosDetalles();
  const contenido = useSelector(selectContent);
  const dispatch = useDispatch();

  return (
    <>
      {contenido.length > 0 && (
        <p className="font-semibold text-base text-[#0D0C22] mb-2">Contenido</p>
      )}
      {contenido.map((content) => (
        <div
          className="grid grid-cols-12 border-gray-300 rounded-lg border-2 p-3 px-5 gap-4"
          key={content.orden}
        >
          <div className="text-base font-semibold text-gray-500 col-span-10 md:col-span-6">
            Seleccione el tipo de contenido que desea agregar
          </div>
          <div className="col-span-2 md:col-span-6 justify-self-end">
            <button
              title="Eliminar bloque de contenido"
              className="w-5 disabled:cursor-not-allowed disabled:opacity-80"
              onClick={() =>
                dispatch(deleteBloqueContenido(contenido, content.orden))
              }
              disabled={editandoTexto || ver}
            >
              <img src={EliminarIcon} className="w-5 h-5" />
            </button>
          </div>

          <SelectContentComponent
            contenido={contenido}
            contentBlock={content}
          />
        </div>
      ))}
    </>
  );
};

const SelectContentComponent = ({ contenido, contentBlock }) => {
  const [contentSelected, setContentSelected] = useState(null);
  const { editandoTexto, setEditandoTexto } = useGestorAvisosDetalles();
  const dispatch = useDispatch();

  //CKEDITOR
  const [ckEditorText, setCkEditorText] = useState(true);
  const [pieDeFoto, setPieDeFoto] = useState("");
  const [ckEditorTable, setCkEditorTable] = useState(true);

  const allowed = !ckEditorTable || !ckEditorText;

  useEffect(() => {
    if (allowed) setEditandoTexto(true);
    else setEditandoTexto(false);
  }, [allowed]);

  const handleContentType = (e) => {
    setContentSelected(e.target.value);
    dispatch(setBloqueId(e.target.value, contenido, contentBlock.orden));
  };

  return (
    <>
      <div className="grid col-span-12 md:col-span-4">
        <InputSelect
          id={"tipoContenido"}
          name={"tipoContenido"}
          label={"Tipo de contenido"}
          customLabel="Seleccione una opción"
          options={CONTENT_OPTIONS}
          value={contentSelected ?? ""}
          disableFirstOption
          onChange={handleContentType}
          styles={`!w-full ${editandoTexto ? "cursor-not-allowed" : ""}`}
          disabled={editandoTexto}
        />
      </div>
      <div className="grid col-span-12">
        {contentSelected === CONTENT.texto && (
          <TextoComponent
            setCkEditorText={setCkEditorText}
            ckEditorText={ckEditorText}
            contenidoId={contentBlock.orden}
          />
        )}
        {contentSelected === CONTENT.audio && (
          <>
            <AudioComponent
              pieDeFoto={pieDeFoto}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
            <PieDeFoto
              pieDeFoto={pieDeFoto}
              setPieDeFoto={setPieDeFoto}
              ckEditorTable={ckEditorTable}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
          </>
        )}
        {contentSelected === CONTENT.video && (
          <>
            <VideoComponent
              pieDeFoto={pieDeFoto}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
            <PieDeFoto
              pieDeFoto={pieDeFoto}
              setPieDeFoto={setPieDeFoto}
              ckEditorTable={ckEditorTable}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
          </>
        )}
        {contentSelected === CONTENT.imagen && (
          <>
            <ImagenComponent
              pieDeFoto={pieDeFoto}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
            <PieDeFoto
              pieDeFoto={pieDeFoto}
              setPieDeFoto={setPieDeFoto}
              ckEditorTable={ckEditorTable}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
          </>
        )}
        {contentSelected === CONTENT.archivo && (
          <>
            <PdfComponent
              pieDeFoto={pieDeFoto}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
            <PieDeFoto
              pieDeFoto={pieDeFoto}
              setPieDeFoto={setPieDeFoto}
              ckEditorTable={ckEditorTable}
              setCkEditorTable={setCkEditorTable}
              contenidoId={contentBlock.orden}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ContentBox;
