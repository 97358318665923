import { useState } from "react";
import {
  updateListaDifusion,
  createListaDifusion,
} from "../../../services/Api/ListasDifusion";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { selectProgress } from "../../../../reducers/avisoData/avisoDataSelector";
import { useSelector } from "react-redux";

const useListAddEdit = (userID) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const savedDraft = useSelector(selectProgress);

  const handleCreateList = async (nombre, usersAdded) => {
    const destinatarios = usersAdded.map((user) => {
      const estructuraKey = user.estructura.toLowerCase();
      return {
        usuario: {
          _id: user.usuario._id,
        },
        estructura: user.estructura,
        [estructuraKey]: {
          _id: user[estructuraKey]._id,
        },
      };
    });

    const body = {
      nombre,
      creador: userID,
      destinatarios,
    };

    try {
      setIsLoading(true);
      await createListaDifusion(body);
      toast.success(
        "La lista de difusión ha sido agregada exitosamente.",
        alertConfiguration
      );
      if (savedDraft) navigate("/herramientas/gestor-avisos/agregar-aviso");
      else navigate("/herramientas/listas-difusion");
    } catch (error) {
      console.log();
      const message =
        error?.response?.data?.message ?? "Error al crear la lista de difusión";
      toast.error(message, alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateList = async (nombre, usersAdded, id) => {
    const destinatarios = usersAdded.map((user) => {
      const estructuraKey = user.estructura.toLowerCase();
      return {
        _id: user._id,
        usuario: {
          _id: user.usuario._id,
        },
        estructura: user.estructura,
        [estructuraKey]: {
          _id: user[estructuraKey]._id,
        },
      };
    });

    const body = {
      nombre,
      destinatarios,
    };

    try {
      setIsLoading(true);
      await updateListaDifusion(body, id);
      toast.success(
        "La lista de difusión se ha actualizado exitosamente.",
        alertConfiguration
      );
      if (savedDraft) navigate("/herramientas/gestor-avisos/agregar-aviso");
      else navigate("/herramientas/listas-difusion");
    } catch (error) {
      const message =
        error?.response?.data?.message ??
        "Error al actualizar la lista de difusión";
      toast.error(message, alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleCreateList, handleUpdateList, isLoading };
};

export default useListAddEdit;
