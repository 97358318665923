import SubmitButton from "../../../fragments/Buttons/SubmitButton";
import CancelButton from "../../../fragments/Buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEnProgreso } from "../../../../reducers/avisoData/avisoDataAction";
import { useGestorAvisosDetalles } from "./GestorAvisosDetallesContext";
import {
  selectAvisoData,
  selectProgress,
} from "../../../../reducers/avisoData/avisoDataSelector";
import { toast } from "react-toastify";
import alertConfiguration from "../../../utils/alertsUtils";
import { postAviso } from "../../../services/Api/GestorAvisos";
import NimbusModal from "../../../fragments/NimbusModal";
import AddListasModal from "./fragments/AddListasModal";
import { useState } from "react";
import Modal from "react-modal";
import Notification from "./fragments/Notification";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

function AvisoFormButtons({ user, activeRole }) {
  const savedDraft = useSelector(selectProgress);

  const { tipoEstructuraRequest: estructuraKey, estructuraId } =
    obtenerUserEstructuraInfo(user, activeRole);

  const [addListasModal, setAddListasModal] = useState(
    savedDraft ? true : false
  );
  const [previewModal, setPreviewModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ver, editandoTexto } = useGestorAvisosDetalles();

  const {
    nombre,
    categoria,
    descripcion,
    contenido,
    destinatarios,
    envio: { defaultOption, date, time },
    calendario: { selected, fechaInicio, fechaFin },
  } = useSelector(selectAvisoData);

  const isValidContent = contenido.every((obj) =>
    obj.hasOwnProperty("path") ||
    (obj.hasOwnProperty("texto") && obj.texto !== "")
      ? true
      : false
  );

  const disabledButton =
    ver ||
    editandoTexto ||
    nombre === "" ||
    descripcion === "" ||
    categoria === "" ||
    (selected && (fechaInicio == null || fechaFin == null)) ||
    (contenido.length > 0 && !isValidContent);

  const handleSaveDraft = async () => {
    const newDestinatarios = destinatarios.map((destinatario) => {
      delete destinatario.listaId;
      return destinatario;
    });
    let newAviso = {
      creador: user._id,
      nombre: nombre,
      categoria: categoria,
      descripcion: descripcion,
      visibilidad: true,
      estatus: "BORRADOR",
      calendario: selected,
      contenidos: contenido,
      destinatarios: newDestinatarios,
      [estructuraKey]: estructuraId,
    };
    try {
      if (selected)
        newAviso = {
          ...newAviso,
          fechaInicio: fechaInicio,
          fechaFin: fechaFin,
        };
      await postAviso(newAviso);
      toast.success(
        "Aviso guardado como borrador correctamente",
        alertConfiguration
      );
      navigate("/herramientas/gestor-avisos");
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    }
  };

  const handleSave = async () => {
    const newDestinatarios = destinatarios.map((destinatario) => {
      delete destinatario.listaId;
      return destinatario;
    });
    let newAviso = {
      creador: user._id,
      nombre: nombre,
      categoria: categoria,
      descripcion: descripcion,
      visibilidad: true,
      estatus: defaultOption === "Now" ? "ENVIADO" : "PROGRAMADO",
      calendario: selected,
      contenidos: contenido,
      destinatarios: newDestinatarios,
      [estructuraKey]: estructuraId,
    };
    try {
      if (selected)
        newAviso = {
          ...newAviso,
          fechaInicio: fechaInicio,
          fechaFin: fechaFin,
        };
      if (defaultOption === "Now") {
        newAviso = {
          ...newAviso,
          fechaEnvio: new Date(),
        };
      } else {
        newAviso = {
          ...newAviso,
          fechaEnvio: new Date(date + " " + time),
        };
      }
      await postAviso(newAviso);
      toast.success("Aviso guardado correctamente", alertConfiguration);
      navigate("/herramientas/gestor-avisos");
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    }
  };

  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-12 md:col-span-12 gap-6 md:place-self-center">
        <div className="grid col-span-2">
          <CancelButton
            text="Cancelar"
            bgColor="bg-divider"
            styles="!w-full"
            onClick={() => navigate("/herramientas/gestor-avisos")}
            disabled={editandoTexto}
          />
        </div>
        <div className="grid col-span-3 text-white">
          <button
            className="min-h-[40px] bg-lightBlue text-white font-semibold rounded"
            disabled={editandoTexto}
            onClick={() => setPreviewModal(true)}
          >
            Previsualizar
          </button>
        </div>
        <div className="grid col-span-4">
          <button
            className="min-h-[40px] text-white font-semibold rounded bg-[#5E5F64]"
            disabled={disabledButton}
            onClick={handleSaveDraft}
          >
            Guardar como borrador
          </button>
        </div>
        <div className="grid col-span-3">
          <SubmitButton
            text="Continuar"
            styles="!w-auto min-h-[40px]"
            disabled={disabledButton}
            onClick={() => {
              dispatch(setEnProgreso(true));
              setAddListasModal(true);
            }}
          />
        </div>
      </div>
      <NimbusModal isOpen={addListasModal} styles={customStyles}>
        <AddListasModal
          activeRole={activeRole}
          userId={user._id}
          onCloseModal={() => {
            dispatch(setEnProgreso(false));
            setAddListasModal(false);
          }}
          saveNotification={handleSave}
        />
      </NimbusModal>
      <Modal
        isOpen={previewModal}
        style={customStyles}
        contentLabel="My dialog"
      >
        <Notification
          onClosePreview={() => setPreviewModal(false)}
          categoriaId={categoria}
        />
      </Modal>
    </>
  );
}

export default AvisoFormButtons;
