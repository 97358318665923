import React, { useEffect, useRef, useState } from "react";
import IconPDF from "../../../../../assets/icons/icons-bloques/pdf_default.png";
import Modal from "react-modal";
import CerrarIcon from "../../../../../assets/icons/cerrar-circle.svg";
import ReactLoading from "react-loading";
import { useGestorAvisosDetalles } from "../GestorAvisosDetallesContext";
import pdf_up from "../../../../../assets/icons/icons-bloques/pdf_up.png";
import editListIcon from "../../../../../assets/icons/editListIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectAvisoData } from "../../../../../reducers/avisoData/avisoDataSelector";
import { uploadFileHandler } from "../../../../../reducers/avisoData/avisoDataAction";

function PdfComponent({ pieDeFoto, setCkEditorTable, contenidoId }) {
  const [pdf, setPdf] = useState(false);
  const [modal, setModal] = useState(false);
  const { editandoTexto, ver } = useGestorAvisosDetalles();
  const dispatch = useDispatch();
  const { contenido, isUploadingFile: loading } = useSelector(selectAvisoData);
  const fileRef = useRef();

  useEffect(() => {
    Modal.defaultStyles.overlay.backgroundColor = "rgb(84 84 84 / 75%)";
    Modal.defaultStyles.overlay.zIndex = "+99";
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  const modalStyles = {
    content: {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      borderRadius: "20px 20px 0 0",
    },
  };

  return (
    <>
      <div
        className={`hover:border-[#c6c6c6] transition-all rounded-md pl-5 pb-5 pr-5 p-2 mb-4 border`}
      >
        <div className="flex justify-between pb-2">
          <button
            onClick={() => fileRef.current.click()}
            className="border rounded-l-md rounded-b-md bg-nimbus text-white px-2"
          >
            Subir archivo
          </button>
          <div className="border rounded-l-md rounded-b-md bg-nimbus text-white px-2">
            PDF
          </div>
          <input
            type="file"
            ref={fileRef}
            onChange={(e) =>
              dispatch(
                uploadFileHandler(
                  e.target.files[0],
                  setPdf,
                  contenido,
                  contenidoId,
                  "archivo"
                )
              )
            }
            hidden
            disabled={editandoTexto || ver}
            accept=".pdf"
          />
        </div>
        <div className="flex justify-center w-full max-w-[960px] mx-auto">
          <div
            className={`border border-dashed border-blue-400 relative ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
          >
            {loading && (
              <div className="w-full h-full absolute bg-[#fff] flex items-center justify-center z-10">
                <ReactLoading
                  type="spin"
                  color="#1A3D82"
                  height={"5%"}
                  width={"5%"}
                />
              </div>
            )}
            {pdf ? (
              <>
                <img className="h-full w-full" src={pdf_up} alt="" />
                <button
                  className="flex items-center justify-center absolute transform -translate-x-1/2 left-[51%] bottom-[7%] border rounded-md py-2 bg-nimbus text-white w-1/4 text-center"
                  onClick={() => setModal(!modal)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                  <span className="ml-2">Ver PDF</span>
                </button>
              </>
            ) : (
              <img className="h-full w-full" src={IconPDF} alt="" />
            )}
          </div>
        </div>
        {pieDeFoto === "" || pieDeFoto === undefined ? (
          <div
            className={`text-[#697D99] cursor-pointer flex justify-center items-center ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
            onClick={() => {
              if (!editandoTexto && !ver) setCkEditorTable(false);
            }}
          >
            <img src={editListIcon} alt="" />
            <span>Editar pie</span>
          </div>
        ) : (
          <div
            onClick={() => {
              if (!editandoTexto && !ver) setCkEditorTable(false);
            }}
            className={`text-[#697D99] cursor-pointer items-center mt-2 flex justify-center ${
              editandoTexto || ver ? "cursor-not-allowed" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: pieDeFoto }}
          />
        )}
      </div>
      <Modal
        isOpen={modal}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={modalStyles}
      >
        <div className="flex justify-end items-center  md:top-8 right-1 md:right-1 sm:top- pr-4 ">
          <p className="text-nimbus mr-0.5 font-bold font-sans">Cerrar PDF</p>
          <button
            type="button"
            className="rounded-x focus:outline-none"
            onClick={() => setModal(false)}
          >
            <img src={CerrarIcon} alt="" />
          </button>
        </div>
        <embed src={pdf} type="application/pdf" className="h-full w-full" />
      </Modal>
    </>
  );
}

export default PdfComponent;
