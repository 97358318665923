const ListaDifusionDescription = () => {
  return (
    <div className="font-semibold text-zinc-500/70 text-lg">
      De clic en el botón de agregar destinatarios para crear la lista de
      difusión. Una vez que haya agregado todos los destinatarios requeridos,
      debe dar clic en guardar. Esta lista podrá ser editada posteriormente si
      así se requiere.
    </div>
  );
};

export default ListaDifusionDescription;
