import { useNavigate } from "react-router-dom";
import AddButton from "../../../fragments/Buttons/AddButton";
import BreadCrumb from "../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../fragments/SubHeader";

const SubHeaderGestorAvisos = () => {
  const navigate = useNavigate();

  return (
    <div className="flex align-middle justify-between items-center">
      <SubHeader styles="!bg-backgroundColor">
        <BreadCrumbContainer>
          <BreadCrumb
            route={"Herramientas /"}
            onClick={() => navigate("/recursos/herramientas")}
          />
          <BreadCrumb route={"Gestor de avisos"} active />
        </BreadCrumbContainer>
      </SubHeader>
      <div className="py-5 px-10">
        <AddButton
          text="Nuevo aviso"
          onClick={() => navigate("/herramientas/gestor-avisos/agregar-aviso")}
        />
      </div>
    </div>
  );
};

export default SubHeaderGestorAvisos;
