import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faRectangleList } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react";
import {useContext} from 'react'
import { ActividadContext } from "../ActividadesUtils/ActWrapper";
import useGrabar from '../ActividadesUtils/useGrabar';
import { toCleanText } from '../ActividadesUtils/ActividadesUtils';
import Microphone from './Microphone';
import Cronometro from '../ActividadesUtils/Cronometro';
import { Trans } from 'react-i18next';
import ImagenValidada from '../ActividadesUtils/RecursosValidados/ImagenValidada';

export default function HerramientaPronunciacion({lang, inputList, umaBloque, guardarCalificacion, instrucciones}) {
  const {isListening, transcript, stopHandle, handleReset, resetTranscript, handleListing} = useGrabar({lang})
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [generalScore, setGeneralScore] = useState(0)
  const [currentScore, setcurrentScore] = useState(0)
  const [iniciado, setIniciado] = useState(false)
  const [time, setTime] = useState(0)
  const [initialSlideTime, setInitialSlideTime] = useState(0)
  const [slidesCorrectas, setSlidesCorrectas] = useState(0)//usadas solo en umas de tipo evaluación
  const activityContext = useContext(ActividadContext);
  const currentText = toCleanText(inputList[currentWordIndex].texto)
  
  const transcriptArr = toCleanText(transcript).split(' ')
  useEffect(()=>{
    //busca la palabra actual dentro de las últimas 5 palabras
    const textFirstEl = currentText.split(' ')[currentScore]
    if(transcript.length && transcriptArr.slice(-5).includes(textFirstEl)){
      setcurrentScore(currentScore + 1)
    }
    if(!iniciado)setIniciado(true)
  },[transcriptArr])

  useEffect(()=>{
    stopHandle()
  },[])

  const showNextSentence = () =>{
    setTimeout(()=>{
      handleReset()
      if(currentWordIndex === inputList.length - 1 ){
        handleCalificar()
      }else{
        setCurrentWordIndex(currentWordIndex + 1)
        setGeneralScore(generalScore + currentScore)
        resetTranscript()
        setInitialSlideTime(time)
      }
    },500)
  }

  useEffect(()=>{
    setcurrentScore(0)
  },[generalScore])

  const handleCalificar = () =>{
    const score = generalScore + currentScore
    const allWordsLength = inputList.map((el)=>(toCleanText(el.texto)))
    .map((el)=>(el.split(' ').length))
    .reduce((partialSum, a) => partialSum + a, 0);
    const calificacion = (score / allWordsLength) * 100
    if(iniciado){
      activityContext.postCalificacionActividad(calificacion)
      if(umaBloque)guardarCalificacion(umaBloque, slidesCorrectas, '')
    }else{
      activityContext.postCalificacionActividad(null)
    }
    const roundedTime = Math.round(time / 1000)
    const wordsPerMinute= Math.round((score / roundedTime) * 60)
    activityContext.getAdditionalData([{type:'Velocidad de lectura', value:wordsPerMinute, label:'palabras por minuto'}])
  }

  useEffect(()=>{
    if(currentScore === currentText.split(' ').length - 1){
      setSlidesCorrectas(slidesCorrectas + 1)
    }

    if(currentScore === currentText.split(' ').length){
      setTimeout(()=>{
        showNextSentence()
      },500)
    }
  },[currentScore])

  const tryAgain = () =>{
    handleReset()
    setcurrentScore(0)
    setTime(initialSlideTime)
  }

  return (
    <>
      {instrucciones && <div className='act-instructions'>{instrucciones}</div>}
      <Cronometro time={time} running={true} setTime={setTime} style={'opacity-0 absolute pointer-events-none'}/>
      <div className="max-w-[36rem] min-w-[340px] w-full md:w-[1280px] mx-auto pt-10">
      {inputList.map((el,i)=>(
          <div key={el._id} className={`w-full mx-auto text-xl text-[#627d92] custom-transition ${i === currentWordIndex ? 'opacity-100' : 'opacity-0 h-0 w-0'}`}>
          <div className="flex justify-between mb-2 text-sm">
          </div>
          <div className="flex justify-between mb-2 text-sm">
            <div className="flex items-center">
              <FontAwesomeIcon className="text-sm mr-2 text-[#627d92]" icon={faRectangleList}></FontAwesomeIcon>
              <div className='inline-block'>
                {i + 1} <Trans i18nKey='slideOf'></Trans> {inputList.length}
              </div>
            </div>
            <div className=""></div>
          </div>
          {el.imagen ? <ImagenValidada className="w-full h-full border mx-auto rounded-lg shadow-lg mb-4" src={el.imagen}/> : null}
          <div className="text-center text-[#627d92] mb-6">
            <div className='mr-2 mb-2 text-xl font-bold block'>
            <Trans i18nKey='sentenceLabel'></Trans>
            </div>
            {el.texto.split(' ').map((item, index)=>(
              <span className={`mr-2 custom-transition inline-block ${currentScore  <= index ? '' : 'text-green-500'}`}>{item}</span>
            ))}
          </div>
          </div>
        ))}
        <Microphone isListening={isListening} lang={lang} transcript={''} stopHandle={showNextSentence} handleListing={handleListing} tryAgain={tryAgain}/>
      </div>
    </>
  );
}