import { toast } from "react-toastify";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import alertConfiguration from "../../../utils/alertsUtils";

const DeleteButton = () => {
  const { usersAdded, setUsersAdded, setUsersAddedFiltered } =
    useListaDifusionDetalles();

  const handleRemoveSelectedUsers = () => {
    // Separar usuarios seleccionados y no seleccionados
    const nonSelectedUsers = usersAdded?.filter((user) => !user.esSeleccionado);

    // Actualizar usersAdded y usersAddedFiltered con usuarios no seleccionados
    setUsersAdded(nonSelectedUsers);
    setUsersAddedFiltered(nonSelectedUsers);

    toast.success(
      "Los usuarios han sido removidos de la lista exitosamente.",
      alertConfiguration
    );
  };

  return (
    <div className="w-full md:w-fit">
      <button
        type="button"
        className={`w-full bg-nimbusDark text-white py-2 p-4 rounded-lg flex items-center justify-center disabled:bg-opacity-70 disabled:cursor-not-allowed`}
        disabled={
          usersAdded?.filter((user) => user.esSeleccionado).length === 0
        }
        onClick={handleRemoveSelectedUsers}
      >
        Eliminar destinatarios
      </button>
    </div>
  );
};

export default DeleteButton;
