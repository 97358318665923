import React from "react";
import { Trans } from "react-i18next";

function ComentariosBox({
  comentarios,
  textareaValue,
  setTextareaValue,
  readOnly,
}) {
  return (
    <div className="w-full inline-block whitespace-pre-wrap">
      <div className="font-bold text-black mb-4">
        <Trans i18nKey="">Añadir comentarios</Trans>
      </div>
      <div className="max-h-[25rem] overflow-auto mb-4">
        {comentarios
          .sort((a, b) => {
            if (a.fecha > b.fecha) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((el, i) => (
            <div
              key={i}
              className={`w-full ${
                el.rol === "profesor" ? "flex justify-end" : ""
              }`}
            >
              <div
                className={`bg-[#eff1f5] w-fit rounded-lg p-2 mb-2 ${
                  el.rol === "profesor" ? "!bg-[#dcf5e1]" : ""
                }`}
              >
                {el.esEliminado ? "Mensaje eliminado" : el.comentario}
              </div>
            </div>
          ))}
      </div>
      {!readOnly && (
        <textarea
          className={`rounded-lg border border-[#dcdcdc] min-h-[7rem] w-full `}
          placeholder=""
          onChange={(e) => setTextareaValue(e.target.value)}
          value={textareaValue}
        ></textarea>
      )}
    </div>
  );
}

export default ComentariosBox;
