import CounterTable from "./CounterTable";
import DeleteButton from "./DeleteButton";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import SearchFilter from "./SearchFilter";
import SelectItems from "./SelectItems";

const ListaDifusionDetallesTableHeader = () => {
  const { ver } = useListaDifusionDetalles();
  const classes = `grid grid-cols-6 grid-rows-${
    ver ? "2" : "4"
  } gap-4 sm:grid-rows-${ver ? "1" : "2"} mb-8`;

  return (
    <div className={classes}>
      <div className="col-span-6 sm:col-span-3">
        <CounterTable />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <SearchFilter />
      </div>

      {!ver && (
        <>
          <div className="col-span-6 sm:col-span-4">
            <SelectItems />
          </div>

          <div className="col-span-6 sm:col-span-2 sm:col-start-5 flex items-center">
            <DeleteButton />
          </div>
        </>
      )}
    </div>
  );
};

export default ListaDifusionDetallesTableHeader;
