import { useEffect, useState } from "react";
import { formatShowDate } from "../../../utils/dateFunctions";
import { useDispatch } from "react-redux";
import {
  setCalendario,
  setFechaFin,
  setFechaInicio,
} from "../../../../reducers/avisoData/avisoDataAction";
import ReactSwitch from "react-switch";

const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

const CalendarForm = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [dateFieldType, setDateFieldType] = useState("text");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [hourFieldType, setHourFieldType] = useState("text");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  const dispatch = useDispatch();

  const handleChangeToggle = (newValue) => {
    setIsChecked(newValue);
    dispatch(setCalendario(newValue));
    dispatch(setFechaInicio(null));
    dispatch(setFechaFin(null));
    setStartDate("");
    setStartHour("");
    setEndDate("");
    setEndHour("");
  };

  useEffect(() => {
    if (startDate !== "" && startHour !== "") {
      dispatch(setFechaInicio(new Date(startDate + " " + startHour)));
    }
    if (endDate !== "" && endHour !== "") {
      dispatch(setFechaFin(new Date(startDate + " " + startHour)));
    }
  }, [startDate, startHour, endDate, endHour]);

  return (
    <>
      <div className="grid col-span-12">
        <div className="flex gap-4">
          <ReactSwitch
            onChange={() => handleChangeToggle(!isChecked)}
            id={"calendar_switch"}
            height={20}
            width={40}
            checked={isChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#18BAFB"
          />
          <span className="font-semibold text-base text-[#0D0C22] mb-2">
            Calendario
          </span>
        </div>
      </div>

      {isChecked && (
        <>
          <CalendarInput
            dateFieldType={dateFieldType}
            setDateFieldType={setDateFieldType}
            label="inicio"
            dateValue={startDate}
            setDate={setStartDate}
            hourFieldType={hourFieldType}
            setHourFieldType={setHourFieldType}
            hourValue={startHour}
            setHour={setStartHour}
          />
          <CalendarInput
            dateFieldType={dateFieldType}
            setDateFieldType={setDateFieldType}
            label="fin"
            dateValue={endDate}
            setDate={setEndDate}
            hourFieldType={hourFieldType}
            setHourFieldType={setHourFieldType}
            hourValue={endHour}
            setHour={setEndHour}
            dateDisabled={startDate}
            hourDisabled={startHour}
          />
        </>
      )}
    </>
  );
};

const CalendarInput = ({
  dateFieldType,
  setDateFieldType,
  label,
  dateValue,
  setDate,
  hourFieldType,
  setHourFieldType,
  hourValue,
  setHour,
  dateDisabled,
  hourDisabled,
}) => {
  return (
    <div className="grid col-span-6">
      <label className="font-semibold text-base text-[#0D0C22] mb-2">
        Fecha y hora de {label}
      </label>
      <div className="grid grid-cols-6">
        <div className="grid col-span-4">
          <input
            type={dateFieldType}
            name={`${label}Fecha`}
            id={`${label}Fecha`}
            placeholder="dd-mm-aaaa"
            onFocus={() => setDateFieldType("date")}
            onBlur={() => setDateFieldType("text")}
            min={label === "fin" ? dateDisabled : undefined}
            value={formatShowDate(addDays(new Date(dateValue)))}
            onChange={(e) => setDate(e.target.value)}
            className={
              "rounded-l-lg p-2 w-full border-divider border text-black/60"
            }
            disabled={dateDisabled === ""}
          />
        </div>
        <div className="grid col-span-2">
          <input
            type={hourFieldType}
            name={`${label}Hora`}
            id={`${label}Hora`}
            placeholder="hh:mm"
            onFocus={() => setHourFieldType("time")}
            onBlur={() => setHourFieldType("text")}
            value={hourValue}
            onChange={(e) => setHour(e.target.value)}
            className={
              "rounded-r-lg p-2 w-full border-divider border text-black/60"
            }
            disabled={hourDisabled === ""}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarForm;
