import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone} from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next';

function Microphone({isListening, transcript, handleListing, stopHandle, automatic, tryAgain}) {
  return (
    <>
        {transcript ? 
        <div className="microphone-result-container">
            <div className="microphone-result-text text-base md:text-lg text-[#627d92]"><Trans i18nKey={'myRecordingLabel'}></Trans>: {transcript}</div>
        </div>:
        <div className='microphone-result-container min-h-[20px] opacity-0'></div>
        }
        <div className="microphone-wrapper md:flex md:items-center md:justify-center ">
            <div className="mircophone-container mb-4 w-fit mx-auto md:w-auto md:mb-0">
            <div
                className={`flex items-center justify-center microphone-icon-container ${isListening ? 'listening' : ''}`}
                onClick={handleListing}
            >
                <FontAwesomeIcon className="text-[2.5rem] text-white" icon={faMicrophone}></FontAwesomeIcon>
            </div>
            <div className={`microphone-status text-base md:text-lg text-[#627d92] ${isListening ? 'w-full' : ''}`}>
                {isListening 
                ? <Trans i18nKey={'listeningLabel'}></Trans>
                : <Trans i18nKey={'recordLabel'}></Trans>
                }
            </div>
            </div>
            {isListening && (
                <>
                    <button className="microphone-stop btn z-[+99] whitespace-nowrap mx-4 btn-light border border-[#dcdcdc] text-nimbusLight" onClick={tryAgain}>
                        <Trans i18nKey={'retryLabel'}></Trans>
                    </button>
                    <button className="microphone-stop btn text-white bg-nimbus z-[+99] whitespace-nowrap" onClick={stopHandle}>
                    {!automatic ? <Trans i18nKey={'stopLabel'}></Trans>:null}
                    {automatic ? <Trans i18nKey={'verifyLabel'}></Trans>:null}
                    </button>
                </>
            )}
        </div>
    </>
  )
}

export default Microphone