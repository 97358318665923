import { useGestorAvisos } from "../GestorAvisosContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeleteAviso from "./DeleteAviso";
import DuplicarIcon from "../../../../assets/icons/duplicate.svg";
import EditarIcon from "../../../../assets/icons/Edit.svg";
import EliminarIcon from "../../../../assets/icons/trash2.svg";
import NimbusModal from "../../../fragments/NimbusModal";
import StatusIcon from "./StatusIcon";
import VisibilitySwitch from "./VisibilitySwitch";
import VisualizarIcon from "../../../../assets/icons/viewIcon.svg";
import { formatShowDate } from "../../../utils/dateFunctions";
import DuplicateAviso from "./DuplicateAviso";

const customStyles = {
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  }
};

const GestorAvisosRow = ({ aviso, getAvisos }) => {
  const navigate = useNavigate();
  const { selectAviso } = useGestorAvisos();
  const {
    _id,
    nombre,
    fechaCreacion,
    fechaEnvio,
    categoria: { nombre: categoriaNombre },
    estatus,
    visibilidad,
    esSeleccionado,
  } = aviso;

  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);

  const fechaCreacionDate = new Date(fechaCreacion);
  const fechaEnvioDate = new Date(fechaEnvio);

  return (
    <>
      <tr className="p-2 text-left bg-white">
        <td className="border-y-2 font-sans text-center">
          <div className="rounded-md flex flex-row items-center justify-center gap-4">
            <input
              type="checkbox"
              name={`selectUser`}
              id={`selectUser`}
              className="rounded-md border-2 border-gray-300 my-0 cursor-pointer"
              checked={esSeleccionado ? esSeleccionado : false}
              onChange={() => selectAviso(aviso)}
            />
            <StatusIcon estatus={estatus} />
          </div>
        </td>
        <td className="border-y-2 font-sans text-left">{nombre}</td>
        <td className="border-y-2 font-sans text-center">
          {formatShowDate(fechaCreacionDate)}
        </td>
        <td className="border-y-2 font-sans text-center">
          {formatShowDate(fechaEnvioDate)}
        </td>
        <td className="border-y-2 font-sans text-center">{categoriaNombre}</td>
        <td className="border-y-2 font-sans text-center first-letter:uppercase lowercase">{estatus}</td>
        <td className="border-y-2 font-sans text-center">
          <VisibilitySwitch
            avisoId={_id}
            checked={visibilidad}
            getAvisos={getAvisos}
          />
        </td>
        <td className="border-2 rounded-r-md border-l-0 pb-1 pt-3 text-center font-sans">
          <div className="flex items-center justify-around">
            <button
              title="Duplicar aviso"
              className="w-5 flex"
              onClick={() => setDuplicateModal(true)}
            >
              <img src={DuplicarIcon} className="w-4 h-4" />
            </button>
            {estatus === "BORRADOR" && (
              <button
                title="Editar aviso"
                className="w-5 flex"
                onClick={() =>
                  navigate(`/herramientas/gestor-avisos/${_id}/editar`)
                }
              >
                <img src={EditarIcon} className="w-5 h-5" />
              </button>
            )}
            <button
              title="Ver aviso"
              className="w-5 flex"
              onClick={() => navigate(`/herramientas/gestor-avisos/${_id}/ver`)}
            >
              <img src={VisualizarIcon} className="w-5 h-5" />
            </button>
            <button
              title="Eliminar aviso"
              className="w-5 flex"
              onClick={() => setDeleteModal(true)}
            >
              <img src={EliminarIcon} className="w-5 h-5" />
            </button>
          </div>
        </td>
      </tr>

      <NimbusModal isOpen={deleteModal} styles={customStyles}>
        <DeleteAviso
          onCloseModal={() => setDeleteModal(false)}
          id={_id}
          getAvisos={getAvisos}
        />
      </NimbusModal>

      <NimbusModal isOpen={duplicateModal} styles={customStyles}>
        <DuplicateAviso
          onCloseDuplicateModal={() => setDuplicateModal(false)}
          id={_id}
          getAvisos={getAvisos}
        />
      </NimbusModal>
    </>
  );
};

export default GestorAvisosRow;
