import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";


//reordenar arreglos
export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
//para estilos de modal
  export const customStyles = {
    content: {
      width:'100%',
      maxWidth:"1200px",
      maxHeight:"90vh",
      borderRadius:'.5rem',
      padding:'3.5rem 2rem 1rem 2rem',
      margin:'0 auto',
      height:'fit-content',
      zIndex:'+99',
      position:'relative',
      top:80
    },
  };

//verifica si un item es par o impar dentro de un array
export function isEven(n) {
    return n % 2 == 0;
 }
 
export function isOdd(n) {
    return Math.abs(n % 2) == 1;
 }

//valida extenciones

function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

export function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
    case 'svg':
      //etc
      return true;
  }
  return false;
}

export function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
  }
  return false;
}

export const colors =[
  "#3f297e",
  "#175fa9",
  "#169ed8",
  "#239b63",
  "#64b031",
  "#bfbd2e",
  "#f7a416",
  "#e6471d",
  "#dc0936",
  "#e5177b",
  "#be1180",
  "#871f7f",
  "#3f297e",
  "#175fa9",
  "#169ed8",
  "#239b63",
  "#64b031",
  "#dad842",
  "#f7a416",
  "#e6471d",
  "#dc0936",
  "#e5177b",
  "#be1180",
  "#871f7f",
]

export const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.pointerEvents = 'none';
      div.style.top = '0';
      div.style.width = '100%';
      div.style.height = '100%';
      self.elt = div;
      document.body.appendChild(div);
      return () => {
          document.body.removeChild(div);
      };
  }, [self]);

  return (render) => (provided, ...args) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
          return createPortal(element, self.elt);
      }
      return element;
  };
};

export const repeatedColors = Array(10).fill(colors).flat()

export const ComponentWithDimensions = props => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  return (
    <div ref={targetRef}>
      <p>{dimensions.width}</p>
      <p>{dimensions.height}</p>
    </div>
  );
};
 
//regresa texto sin acentos ni signos de puntuacion
export const toCleanText = (str) =>{
  const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
	const textoSinAcentos = str.split('').map( letra => acentos[letra] || letra).join('').toString();	
  return textoSinAcentos.toLocaleLowerCase().replace(/[.,\/#¡!¿?$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s+/g,' ').trim();
}

//regresa texto sin espacios inecesarios
export const textWithoutSpaces = (str) =>{
  return str.replace(/\s+/g,' ').trim()
}

//Revisa si un array de numeros es acendente
export function isAscending(arr) {
  return arr.every(function (x, i) {
      return i === 0 || x >= arr[i - 1];
  });
}

export const getExpTitle = (type, lang) =>{
  if(type === 'PALABRAS' && lang === 'es'){
      return 'Lectura de palabras'
  }
  else if(type === 'PARRAFO' && lang === 'es'){
      return 'Lectura de párrafo (pronunciación)'
  }
  else if(type === 'VELOCIDAD' && lang === 'es'){
      return 'Lectura de párrafo (velocidad)'
  }
  if(type === 'PALABRAS' && lang === 'en'){
      return 'Word reading'
  }
  else if(type === 'PARRAFO' && lang === 'en'){
      return 'Paragraph reading (pronunciation)'
  }
  else if(type === 'VELOCIDAD' && lang === 'en'){
      return 'Paragraph reading (speed)'
  }
  
}

export const getExpDesc = (type, lang) =>{
  if(type === 'PALABRAS' && lang === 'es'){
      return 'Prepara tu micrófono, selecciona la palabra u oración, pronúnciala y para la grabación. Espera a verificar si la pronunciaste correctamente o no. Intenta con todas las palabras.'
  }
  else if(type === 'PARRAFO' && lang === 'es'){
      return 'Prepara tu micrófono, pulsa el botón iniciar y lee el texto en voz alta, el sistema calificará tu pronunciación, no la rapidez con que lo hagas. Una vez que termines de dictar, pulsa el botón verificar.'
  }
  else if(type === 'VELOCIDAD' && lang === 'es'){
      return 'Prepara tu micrófono,  pulsa el botón iniciar y lee el texto en voz alta lo más rápido que puedas, el sistema te irá calificando cada palabra. Si te atoras en una palabra intenta pronunciarla hasta que logres pasar, entre más rápido lo hagas mejor será tu promedio de velocidad de lectura.'
  }
  if(type === 'PALABRAS' && lang === 'en'){
      return 'Get your microphone ready, select the word or sentence, pronounce it and stop the recording. Wait to check if you pronounced it correctly or not. Try this whith all the words.'
  }
  else if(type === 'PARRAFO' && lang === 'en'){
      return "Get your microphone ready, press the start button and read the text aloud, the system will rate your pronunciation, not how fast you do it. Once you have finished dictating, click the check button."
  }
  else if(type === 'VELOCIDAD' && lang === 'en'){
      return 'Get your microphone ready, press the start button and read the text aloud as fast as you can, the system will rate each word for you. If you get stuck on a word, try to pronounce it until you pass it, the faster you do it, the better your average reading speed will be.'
  }
}

//parse datos para relacionar columnas

export const filaUnoItems = (columnas) =>{
  return columnas.filter((item, index)=>(isEven(index))).map((el, i)=>({...el, orden:i}))
}

export const filaDosItems = (columnas) =>{
  return columnas.filter((item, index)=>(isOdd(index))).map((el, i)=>({...el, orden:i}))
}

export const getNuevasPreguntas = (preguntas, respuestas) =>{
  const nuevasPreguntas = preguntas.map((el)=>{
      const respuestasObj = respuestas.filter((item)=>(el.respuestas.includes(item._id)))
      return {...el,respuestas:respuestasObj}
  })
  .map((el)=>({...el, respuestas:shuffle(el.respuestas)}))
  return shuffle(nuevasPreguntas)
}

//agrega datos faltantes a enlaces que empiezan con www y añade un tag tipo alt a imágenes
export const replaceTextLinks = (text) => {
  return text
    ?.replaceAll(
      '<a href="www',
      '<a target="_blank" rel="noopener noreferrer" href="https://www'
    )
    .replaceAll(
      "<img ",
      '<img alt="Recurso no encontrado / Resource not found" '
    );
};

export const folderColors = [
  'azul', 'ladrillo', 'cardenal', 'fresas', 
  'naranja', 'taxi', 'menta', 'helecho', 
  'esparrago', 'limon', 'arena', 'macarrones', 
  'espuma', 'piscina', 'vaquero', 'cielo', 
  'terciopelo', 'dinosaurio', 'raton', 'montana', 
  'lombriz', 'chicle', 'lluvia', 'berenjena'
]

export function fileExists(image_url) {
  var http = new XMLHttpRequest();
  http.open("HEAD", image_url, false);
  http.send();
  return http.status != 404;
}

export const formatearFecha = (fecha) => {
  if(!fecha)return ''
  const fechaNueva = new Date(fecha);
  return fechaNueva.toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
};

export const roundTwoDecimals = (num) =>{
  if(!num)return ''
  return  (Math.round(num * 100) / 100).toFixed(2);
}
 
export const getRubricas = (evidenciaObj) => {
  if (evidenciaObj?.rubricasStr && evidenciaObj?.rubricasStr !== "[]")
    return JSON.parse(evidenciaObj?.rubricasStr);
  else if (evidenciaObj.rubricas) return evidenciaObj.rubricas;
  else return [];
};