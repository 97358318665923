import { createContext, useContext, useState } from "react";

const initialContext = {
  ver: undefined,
  setVer: () => {},
  editar: undefined,
  setEditar: () => {},
  editandoTexto: false,
  setEditandoTexto: () => {},
  count: 1,
  setCount: () => {},
};

export const GestorAvisosDetallesContext = createContext(initialContext);

export const GestorAvisosContextProvider = ({ children }) => {
  const [count, setCount] = useState(1);
  const [ver, setVer] = useState(undefined);
  const [editar, setEditar] = useState(undefined);
  const [editandoTexto, setEditandoTexto] = useState();

  let body = new FormData();

  return (
    <GestorAvisosDetallesContext.Provider
      value={{
        ver,
        setVer,
        editar,
        setEditar,
        editandoTexto,
        setEditandoTexto,
        body,
        count,
        setCount,
      }}
    >
      {children}
    </GestorAvisosDetallesContext.Provider>
  );
};

export const useGestorAvisosDetalles = () => {
  return useContext(GestorAvisosDetallesContext);
};
