import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStopwatch, faRectangleList} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react";
import {useContext} from 'react'
import { ActividadContext } from "../ActividadesUtils/ActWrapper";
import useGrabar from '../ActividadesUtils/useGrabar';
import Microphone from './Microphone';
import { toCleanText } from '../ActividadesUtils/ActividadesUtils';
import { Trans } from 'react-i18next';
import ImagenValidada from '../ActividadesUtils/RecursosValidados/ImagenValidada';

export default function HerramientaVelocidad({lang, inputList, umaBloque, guardarCalificacion, instrucciones}) {
  const {isListening, transcript, stopHandle, handleReset, resetTranscript, handleListing} = useGrabar({lang})
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [iniciado, setIniciado] = useState(false)
  const activityContext = useContext(ActividadContext);
  const [palabrasHabladas, setPalabrasHabladas] = useState([])
  const [slidesCorrectas, setSlidesCorrectas] = useState(0)
  const transcriptArr = toCleanText(transcript).split(' ')
  const originalTextArr = toCleanText(inputList[currentWordIndex].texto).split(' ')

  useEffect(()=>{
    setTimeout(()=>{
      handleReset()
    },300)
  },[currentWordIndex])
  
  useEffect(()=>{
    handleReset()
    //stopHandle()
  },[])

  const handleCalificar = () =>{
    const finalScore = (palabrasHabladas.reduce((partialSum, a) => partialSum + a, 0)) / inputList.length
    if(iniciado){
      activityContext.postCalificacionActividad(finalScore)
      if(umaBloque)guardarCalificacion(umaBloque, slidesCorrectas,'')
    }else{
      activityContext.postCalificacionActividad(null)
    }
  }

  const handleNext = () =>{
    const correctasSlide = transcriptArr.filter((el, i)=>{
      return el === originalTextArr[i]
    }).length

    const totalesSlide = originalTextArr.length
    const slideScore = [(correctasSlide / totalesSlide) * 100]
    if(slideScore[0] === 100){
      setSlidesCorrectas(slidesCorrectas + 1)
    }

    setPalabrasHabladas([...palabrasHabladas , ...slideScore]) 
  }
  
  useEffect(()=>{
    if(iniciado){
      if(currentWordIndex === inputList.length - 1 ){
        handleCalificar()
      }else{
        setCurrentWordIndex(currentWordIndex + 1)
        resetTranscript()
      }
    }
  },[palabrasHabladas])

  const handleResume = () =>{
    setIniciado(true)
    handleListing()
  }

  return (
    <>
      {instrucciones && <div className='act-instructions'>{instrucciones}</div>}
      <div className="max-w-[36rem] min-w-[340px] w-full md:w-[1280px] mx-auto pt-10">
      {inputList.map((el,i)=>(
          <div key={el._id} className={`w-full mx-auto text-xl text-[#627d92] custom-transition ${i === currentWordIndex ? 'opacity-100' : 'opacity-0 h-0 w-0'}`}>
          <div className="flex justify-between mb-2 text-sm">
          </div>
          <div className="flex justify-between mb-2 text-sm">
            <div className="flex items-center">
              <FontAwesomeIcon className="text-sm mr-2 text-[#627d92]" icon={faRectangleList}></FontAwesomeIcon>
              <div className='inline-block'>{i + 1} <Trans i18nKey='slideOf'></Trans> {inputList.length}</div>
            </div>
          </div>
          {el.imagen ? <ImagenValidada className="w-full h-full border mx-auto rounded-lg shadow-lg mb-4" src={el.imagen}/> : null}
            <div className="text-center text-[#627d92] mb-6">
              <div className='mr-2 inline-block'>
                 <Trans i18nKey={'sentenceLabel'}></Trans>
              </div>
              {originalTextArr.map((el, i)=>(
                <div className={`inline-block px-1 rounded text-lg transition-all ${transcriptArr.length === i && transcript !== '' ? 'bg-[#18bafb] text-white' : 'text-[#18bafb]'}`}>{el}</div>
              ))}
            </div>
          </div>
        ))}
        <div className="text-center">
          {transcript !== '' ? <div className="inline-block mr-2">
            <Trans i18nKey='myRecordingLabel'></Trans>
          </div>:null}
          {transcriptArr.map((el, i)=>(
            <div className={`inline-block mr-2 text-lg ${el === originalTextArr[i] ? 'text-green-500' : 'text-red-500'}`}>{el}</div>
          ))}
        </div>
        <Microphone isListening={isListening} transcript={''} stopHandle={handleNext} handleListing={handleResume} tryAgain={handleReset} automatic/>
      </div>
    </>
  );
}