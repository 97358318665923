import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../fragments/BreadCrumb";
import BreadCrumbContainer from "../../../fragments/BreadCrumbContainer";
import SubHeader from "../../../fragments/SubHeader";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";

const SubHeaderListaDifusionDetalles = () => {
  const navigate = useNavigate();
  const { ver, editar } = useListaDifusionDetalles();

  return (
    <div className="flex align-middle justify-between items-center">
      <SubHeader styles="!bg-backgroundColor">
        <BreadCrumbContainer>
          <BreadCrumb
            route={"Listas de difusión /"}
            onClick={() => navigate(-1)}
          />
          <BreadCrumb
            route={
              ver
                ? "Ver lista de difusión"
                : editar
                ? "Editar lista de difusión"
                : "Agregar lista de difusión"
            }
            active
          />
        </BreadCrumbContainer>
      </SubHeader>
    </div>
  );
};

export default SubHeaderListaDifusionDetalles;
