import generator from "crossword-layout-generator";
import {isOdd,isEven} from './ActividadesUtils/ActividadesUtils'
import CrucigramaBody from './CrucigramaBody';

function Crucigrama({inputList, evaluable, umaBloque, guardarCalificacion, instrucciones}) {
  const evenItems = inputList.filter((item, index)=>{
    return isEven(index)
  })
  .map((el, i)=>({...el, orden:i}))

  const oddItems = inputList.filter((item, index)=>{
  return isOdd(index)
  })
  .map((el, i)=>({...el, orden:i}))

  const crucigramaData = evenItems.map((el, i)=>({answer:el.texto,clue:oddItems[i].texto ? oddItems[i].texto : ' '}))
  .filter((el)=>(el.answer && el.clue))
  .map((el)=>({...el, answer:el.answer.toUpperCase().replace(/\s/g, '')} ))

  const layout = generator.generateLayout(crucigramaData);
  const output_json = layout.result;

  const downWords = output_json
  .filter((el) => {
    return el.orientation === "down";
  })
  .map((el) => {
    return {
      ...el,
      row: el.startx,
      col: el.starty
    };
  });

  const acrossWords = output_json
    .filter((el) => {
      return el.orientation === "across";
    })
    .map((el) => {
      return {
        ...el,
        row: el.startx,
        col: el.starty
      };
    });

    const crucigrama = {
        down: { ...acrossWords },
        across: { ...downWords }
    };

  return (
    <div className={`w-full max-w-activity pt-10`}>
      {instrucciones && <div className="act-instructions mb-5">{instrucciones}</div>}
        <CrucigramaBody
          crucigrama={crucigrama}
          evaluable={evaluable}
          crucigramaLength={[...downWords, ...acrossWords].length}
          umaBloque={umaBloque}
          guardarCalificacion={guardarCalificacion}
        />
    </div>
  )
}

Crucigrama.defaultProps ={
  evaluable:true
}

export default Crucigrama